<div class="py-1 h-[240px]">
    <!--CO2 -->
    <div class="w-full flex h-1/3 flex-row rounded-md items-center px-2 ">
        <div class="w-[45%] flex flex-col">
            <span class="items-center flex">
                {{ 'SENSOR_CO2_COMPONENT.CO' | transloco }}<sub class="mr-1">2</sub>
                {{ 'SENSOR_CO2_COMPONENT.PPM_ATUAL' | transloco }}
                <span>
                    <mat-icon
                        [svgIcon]="'icon-info'"
                        class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
                        (click)="abrirModalEscalaCores()"
                    ></mat-icon>
                </span>
            </span>
            <span class="flex items-center rounded">
                <app-data-ultima-comunicacao
                    [equipamentos]="formataArrayEquipamentosComponenteData(listaEquimentosSensoresCO2)"
                    [visualizarTempo]="true"
                    [tamanhoIconeStatus]="'icon-size-09'"
                    [ocultarModal]="ocultarModal"
                    [apenasVisualizacao]="true"
                >
                </app-data-ultima-comunicacao>
            </span>
        </div>
        <div class="w-[55%] text-right my-auto items-end">
            <span
                class="rounded-md text-center inline-block w-[70px] h-[24px]"
                [ngClass]="
                    sensorCO2?.co2?.co2?.med ? 'px-1 py-0.5' : 'opacity-50 px-[1rem] text-[#535767] bg-[#CECECE]'
                "
                [ngStyle]="{
                    background: defineCoresCO2(sensorCO2?.co2?.co2?.med)?.fundo,
                    color: defineCoresCO2(sensorCO2?.co2?.co2?.med)?.cor
                }"
            >
                {{ sensorCO2?.co2?.co2?.med ? (sensorCO2?.co2?.co2?.med | number : '1.0' : 'pt') : '-' }}
            </span>
            <ng-container *ngIf="sensorCO2?.co2?.validacao?.ppm">
                <span
                    class="rounded border px-1 py-[0.5] mt-1 ml-1 text-right text-[#16171B]"
                    [ngStyle]="{
                        'border-color': defineCoresCO2(sensorCO2.co2.co2.med)?.fundo
                    }"
                >
                    {{
                        sensorCO2?.co2?.validacao?.ppm
                            ? (Object.values(ppmEnum)[Object.keys(ppmEnum).indexOf(sensorCO2.co2?.validacao.ppm)]
                              | transloco)
                            : ''
                    }}
                </span>
            </ng-container>
        </div>
    </div>

    <!--Bola de ar-->
    <div class="w-full flex h-1/3 rounded-md items-center bg-[#f8fafb] px-2">
        <div class="w-full flex items-center">
            <span>{{ 'SENSOR_CO2_COMPONENT.BOLSAO_DE_AR' | transloco }}</span>
        </div>
        <div class="w-[160px] flex flex-col my-auto justify-end">
            <div class="flex">
                <span class="wl-cor-primaria text-sm mt-0.5 mr-2 w-[80px]">
                    {{ 'SENSOR_CO2_COMPONENT.TEMP' | transloco }}
                </span>
                <span
                    class="rounded-md text-center inline-block w-[70px] h-[24px]"
                    [ngClass]="
                        sensorCO2?.co2?.temperatura?.med
                            ? 'px-1 py-0.5'
                            : 'opacity-50 px-[1rem] text-[#535767] bg-[#CECECE]'
                    "
                    [ngStyle]="{
                        background: defineCoresTemperatura(sensorCO2?.co2?.temperatura?.med)?.fundo,
                        color: defineCoresTemperatura(sensorCO2?.co2?.temperatura?.med)?.cor
                    }"
                    >{{
                        sensorCO2?.co2?.temperatura?.med
                            ? (sensorCO2?.co2?.temperatura?.med | number : '1.1-1' : 'pt')
                            : ' - '
                    }}
                </span>
            </div>
            <div class="flex mt-2">
                <span class="wl-cor-primaria text-sm mt-0.5 mr-2 w-[80px]">{{ 'SENSOR_CO2_COMPONENT.UR' | transloco }}</span
                ><br />
                <span
                    class="rounded-md text-center inline-block w-[70px] h-[24px]"
                    [ngClass]="
                        sensorCO2.co2?.umidade?.med
                            ? 'px-1 py-0.5'
                            : 'opacity-50 px-[1rem] text-[#535767] bg-[#CECECE]'
                    "
                    [ngStyle]="{
                        background: defineCoresUmidade(sensorCO2.co2?.umidade?.med)?.fundo,
                        color: defineCoresUmidade(sensorCO2.co2?.umidade?.med)?.cor
                    }"
                    >{{
                        sensorCO2.co2?.umidade?.med ? (sensorCO2.co2?.umidade?.med | number : '1.1-1' : 'pt') : ' - '
                    }}</span
                >
            </div>
        </div>
    </div>

    <!--Chapa metálica-->
    <div class="w-full flex h-1/3 rounded-md items-center px-2">
        <div class="w-full flex items-center">
            <span>{{ 'SENSOR_CO2_COMPONENT.CHAPA_METALICA' | transloco }}</span>
        </div>
        <div class="w-[160px] flex flex-col my-auto justify-end">
            <div class="flex">
                <span class="wl-cor-primaria text-sm mt-0.5 mr-2 w-[80px]">
                    {{ 'SENSOR_CO2_COMPONENT.TEMP' | transloco }}
                </span>
                <span
                    class="rounded-md text-center inline-block w-[70px] h-[24px]"
                    [ngClass]="
                        sensorCO2.co2?.temperatura_chapa?.med
                            ? 'px-1 py-0.5'
                            : 'opacity-50 px-[1rem] text-[#535767] bg-[#CECECE]'
                    "
                    [ngStyle]="{
                        background: defineCoresTemperatura(sensorCO2.co2?.temperatura_chapa?.med)?.fundo,
                        color: defineCoresTemperatura(sensorCO2.co2?.temperatura_chapa?.med)?.cor
                    }"
                    >{{
                        sensorCO2.co2?.temperatura_chapa?.med
                            ? (sensorCO2.co2?.temperatura_chapa?.med | number : '1.1-1' : 'pt')
                            : ' - '
                    }}
                </span>
            </div>
            <div class="flex mt-2">
                <span class="wl-cor-primaria text-left text-sm mr-2">
                    <span class="block"> {{ 'SENSOR_CO2_COMPONENT.RISCO' | transloco }} </span>
                    <span class="block -mt-[6px]"> {{ 'SENSOR_CO2_COMPONENT.CONDENSACAO' | transloco }} </span>
                </span>
                <span class="flex justify-center items-center w-[70px] h-[24px]">
                    <ng-container *ngIf="sensorCO2?.co2?.validacao; else semInformacao">
                        {{
                            sensorCO2?.co2?.validacao
                                ? sensorCO2.co2?.validacao.condensacao
                                    ? ('SENSOR_CO2_COMPONENT.SIM' | transloco)
                                    : ('SENSOR_CO2_COMPONENT.NAO' | transloco)
                                : ''
                        }}
                    </ng-container>
                    <span>
                        <mat-icon
                            [svgIcon]="'icon-info'"
                            class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
                            (click)="abrirModalCondensacao()"
                        ></mat-icon>
                    </span>
                </span>
            </div>
        </div>
    </div>
</div>

<ng-template #semInformacao>
    <span
        class="rounded-md min-w-[70px] text-center pt-1 pb-1 inline-block opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]"
        >-</span
    >
</ng-template>
