import { StatusAeracaoModel } from 'app/shared/models';

export const configuracoesStatusAeracaoEnConstant: StatusAeracaoModel[] = [
    {
        codigo: '0A',
        icone: {
            tipo: 'icone-aguardando',
            texto: 'Command Confirmation',
            classIcone: 'color-[#185BC3]',
            modal: {
                titulo: 'Command Confirmation',
                subtitulo: 'Waiting for MEP.',
                mensagem: 'Command sent. Waiting for confirmation that it was executed.',
            },
        },
        alerta: {
            cor: '#185BC3',
            background: '#185BC333',
        },
        aerador: {
            cor: 'azul',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '0B',
        icone: {
            tipo: 'icone-aguardando',
            texto: 'Command Confirmation',
            classIcone: 'color-[#185BC3]',
            modal: {
                titulo: 'Command Confirmation',
                subtitulo: 'Waiting for MEP.',
                mensagem: 'Command sent. Waiting for confirmation that it was executed.',
            },
        },
        alerta: {
            cor: '#185BC3',
            background: '#185BC333',
        },
        aerador: {
            cor: 'azul-mao-amarela',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '-1',
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Status indefinido',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Undefined status',
                subtitulo: 'Undefined status',
                mensagem: 'Undefined aeration status.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '1A',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Communication failure',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Communication failure',
                subtitulo: 'No communication (relay).',
                mensagem: 'The equipment responsible for activation has not been communicating since {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '1B',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Communication failure',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Communication failure',
                subtitulo: 'No communication (relay).',
                mensagem: 'The equipment responsible for activation has not been communicating since {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '2A',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Communication failure',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Communication failure',
                subtitulo: 'No communication (switch).',
                mensagem:
                    'The equipment responsible for reading the switches has not been communicating since {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '2B',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Communication failure',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Communication failure',
                subtitulo: 'No communication (switch).',
                mensagem:
                    'The equipment responsible for reading the switches has not been communicating since {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '3A',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Communication failure',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Communication failure',
                subtitulo: 'No communication (status).',
                mensagem:
                    'The equipment responsible for reporting whether the command was executed has not been communicating since {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '3B',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Communication failure',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Communication failure',
                subtitulo: 'No communication (status).',
                mensagem:
                    'The equipment responsible for reporting whether the command was executed has not been communicating since {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '101',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Electrical panel or Procer equipment',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Electrical panel or Procer equipment',
                subtitulo: 'Aerator did not turn on. Check panel circuit breakers.',
                mensagem:
                    'The system is configured for automatic activation and tried to turn on the aerator. The panel switches are in the automatic position, but the motor status is not activated. It is possible that the electrical panel or Procer equipment are defective. Check panel circuit breakers.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Manual aeration command',
                mensagem:
                    'Do you really want to <strong>turn off</strong> the aeration of <strong>{{valor}}</strong>? By confirming, the system will no longer automatically control the aerators.',
                botaoAcao: 'turn off',
            },
        },
    },
    {
        codigo: '102',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Electrical panel or Procer equipment',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Electrical panel or Procer equipment',
                subtitulo: 'Aerators through the system would be off.',
                mensagem:
                    'The panel switches are in the automatic position and the motor status is activated. It is possible that the electrical panel or Procer equipment are defective.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Manual aeration command',
                mensagem:
                    'Do you really want to <strong>turn on</strong> the aeration of <strong>{{valor}}</strong>? By confirming, the system will no longer automatically control the aerators.',
                botaoAcao: 'turn on',
            },
        },
    },
    {
        codigo: '103',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Panel switch in manual position',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Panel switch in manual position',
                subtitulo: 'Aerators through the system would be on.',
                mensagem:
                    'The system is configured for automatic activation and tried to turn on the aerator, but the panel switches are in the manual position, preventing the motor from being turned on or off through the system.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '104',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Panel switch in manual position',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Panel switch in manual position',
                subtitulo: 'Aerators will not be controlled through the system.',
                mensagem:
                    'The system is configured for automatic activation, the aerator is on, but the panel switches are in the manual position, preventing the aerator from being turned off through the system.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '105',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Aerator turned on manually',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Aerator turned on manually',
                subtitulo: 'Aerators will not be controlled through the system.',
                mensagem:
                    'The system is configured for automatic activation, but the panel switches are in the manual position, preventing the aerator from being turned on or off through the system.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '106',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Electrical panel or Procer equipment',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Electrical panel or Procer equipment',
                subtitulo: 'Aerator did not turn on. Check panel circuit breakers.',
                mensagem:
                    'The relay of the Procer equipment is activated, the panel switch is in the automatic position, but the aerator did not turn on. Check panel circuit breakers.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Manual aeration command',
                mensagem: 'Do you really want to <strong>turn off</strong> the aeration of <strong>{{valor}}</strong>?',
                botaoAcao: 'turn off',
            },
        },
    },
    {
        codigo: '107',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Electrical panel or Procer equipment',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Electrical panel or Procer equipment',
                subtitulo: 'Aerators through the system would be off.',
                mensagem:
                    'The panel switches are in the automatic position, the aerator status is activated, and the relay is off. It is possible that the electrical panel or Procer equipment are defective.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Manual aeration command',
                mensagem: 'Do you really want to <strong>turn on</strong> the aeration of <strong>{{valor}}</strong>?',
                botaoAcao: 'turn on',
            },
        },
    },
    {
        codigo: '201',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Panel switch in manual position',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Panel switch in manual position',
                subtitulo: 'Aerators will not be controlled through the system.',
                mensagem:
                    'The system is configured for automatic activation, but the panel switches are in the manual position, preventing the aerator from being turned on or off through the system.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '202',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Panel switch in manual position',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Panel switch in manual position',
                subtitulo: 'Aerators through the system would be on.',
                mensagem:
                    'The relay of the Procer equipment is activated, but the panel switch is in the manual position, preventing the aerator from turning on.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '203',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Panel switch in manual position',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Panel switch in manual position',
                subtitulo: 'Aerators will not be controlled through the system.',
                mensagem:
                    'The relay of the Procer equipment is activated, but the panel switch is in the manual position, preventing the aerator from being turned off through the system.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '204',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Aerator turned on manually',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Aerator turned on manually',
                subtitulo: 'Turned on through the panel.',
                mensagem: 'Aerator turned on manually through the buttons on the electrical panel',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '205',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Panel switch in manual position',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Panel switch in manual position',
                subtitulo: 'It is not possible to control the aerators.',
                mensagem: 'The panel switch is in the manual position, it will not be possible to control the aerator',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'cinza-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '206',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'System in manual mode',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'System in manual mode',
                subtitulo: 'Aerators will not be controlled through the system.',
                mensagem: 'Aerators turned on manually through the system.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Manual aeration command',
                mensagem: 'Do you really want to <strong>turn off</strong> the aeration of <strong>{{valor}}</strong>?',
                botaoAcao: 'turn off',
            },
        },
    },
    {
        codigo: '207',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'System in manual mode',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'System in manual mode',
                subtitulo: 'Aerators will not be controlled through the system.',
                mensagem: 'System in manual control. The aerators will not be controlled automatically.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'cinza-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Manual aeration command',
                mensagem: 'Do you really want to <strong>turn on</strong> the aeration of <strong>{{valor}}</strong>?',
                botaoAcao: 'turn on',
            },
        },
    },
    {
        codigo: '401',
        icone: {
            tipo: 'mat_solid:check_circle',
            texto: 'System in automatic mode',
            classIcone: 'fill-[#34901C]',
            modal: {
                titulo: 'System in automatic mode',
                subtitulo: 'Aerators configured for automatic activation.',
                mensagem: 'Automatic activation of aerators according to the established program.',
            },
        },
        alerta: {
            cor: '#34901C',
            background: '#34901C33',
        },
        aerador: {
            cor: 'cinza',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Manual aeration command',
                mensagem:
                    'Do you really want to <strong>turn on</strong> the aeration of <strong>{{valor}}</strong>? By confirming, the system will no longer automatically control the aerators.',
                botaoAcao: 'turn on',
            },
        },
    },
    {
        codigo: '402',
        icone: {
            tipo: 'mat_solid:check_circle',
            texto: 'System in automatic mode',
            classIcone: 'fill-[#34901C]',
            modal: {
                titulo: 'System in automatic mode',
                subtitulo: 'Aerators turned on automatically.',
                mensagem: 'Automatic activation of aerators according to the configured program.',
            },
        },
        alerta: {
            cor: '#34901C',
            background: '#34901C33',
        },
        aerador: {
            cor: 'verde',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Manual aeration command',
                mensagem:
                    'Do you really want to <strong>turn off</strong> the aeration of <strong>{{valor}}</strong>? By confirming, the system will no longer automatically control the aerators.',
                botaoAcao: 'turn off',
            },
        },
    },
];
