import { Component, Input, OnChanges } from '@angular/core';
import { VerificaObjetoVazio } from 'app/shared/helpers';
import {
    EquipamentoInformacoesSiloInterface,
    EstoqueInformacoesSiloInterface,
    SensoresVolumetria3DInterface,
    SensorVolumetriaInterface,
} from 'app/shared/interfaces';
import { SensoresVolumetriaEquipamentoInterface } from 'app/modules/armazenagem/visualizacao/silos/interfaces';
import { IFormataEquipamentosComponenteDataService } from 'app/shared/services';
import { IMontaListaEquipamentosSensoresService } from 'app/modules/armazenagem/visualizacao/silos/services';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { configuracaoTamanhoModal } from 'app/shared/constants';
import { ModalInformativoGenericoComponent } from 'app/shared/components';
import { MensagensVolumetriaEnum } from 'app/shared/enums';

@Component({
    selector: 'app-estoque-informacoes-gerais-armazem',
    templateUrl: './estoque-informacoes-gerais-armazem.component.html',
    styleUrls: ['./estoque-informacoes-gerais-armazem.component.scss'],
})
export class EstoqueInformacoesGeraisArmazemComponent implements OnChanges {
    @Input() estoque: EstoqueInformacoesSiloInterface;
    @Input() sensorVolumetria: SensorVolumetriaInterface;
    @Input() equipamentos: EquipamentoInformacoesSiloInterface[];
    @Input() sensoresVolumetriaEquipamentos: SensoresVolumetriaEquipamentoInterface[];
    @Input() sensoresVolumetria3DEquipamentos: SensoresVolumetria3DInterface[];
    @Input() compactacaoLayout: boolean = false;

    listaEquimentosSensoresVolumetria: EquipamentoInformacoesSiloInterface[] = [];
    ocultarModal: boolean = true;

    verificaObjetoVazio = VerificaObjetoVazio.execute;

    constructor(
        private formataEquipamentosComponenteDataService: IFormataEquipamentosComponenteDataService,
        private montaListaEquipamentosSensoresService: IMontaListaEquipamentosSensoresService,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver
    ) {}

    ngOnChanges(): void {
        if (!this.verificaObjetoVazio(this.sensorVolumetria)) {
            this.montaListagemEquipamentosSensores();
        }
    }

    calculaPercentualEstoque(estoqueTonelada: number, capacidadeTonelada: number): number {
        let percentual = (estoqueTonelada / capacidadeTonelada) * 100;
        if (percentual == Infinity) {
            percentual = null;
        }
        return percentual;
    }

    formataArrayEquipamentosComponenteData(equipamentos: any): EquipamentoInformacoesSiloInterface[] {
        return this.formataEquipamentosComponenteDataService.execute(equipamentos);
    }

    equipamentoVolumeTria3DSemComunicacao(): boolean {        
        return this.formataArrayEquipamentosComponenteData(this.listaEquimentosSensoresVolumetria)[0]
            ?.equipamento_comunicacao?.status;
    }

    sensoresVolumeComFalha(): boolean | false {
        return this.sensoresVolumetriaEquipamentos?.some(
            (sensorVolume) => !sensorVolume?.sensor_volume_ultima_leitura?.estoque
        );
    }

    abrirModalVolumetria(): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        this.dialog.open(ModalInformativoGenericoComponent, {
            minWidth: mobile
                ? configuracaoTamanhoModal.informativo.mobile.width
                : configuracaoTamanhoModal.informativo.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.informativo.mobile.height
                : configuracaoTamanhoModal.informativo.desktop.height,
            data: {
                titulo: MensagensVolumetriaEnum.TITULO_MODAL_VOLUMETRIA,
                mensagem: MensagensVolumetriaEnum.MENSAGEM_MODAL_VOLUMETRIA,
            },
        });
    }

    possuiSensorVolumetria3D(): boolean {
        return this.sensoresVolumetria3DEquipamentos?.length > 0;
    }

    private montaListagemEquipamentosSensores(): void {
        this.listaEquimentosSensoresVolumetria = this.montaListaEquipamentosSensoresService.execute(
            this.possuiSensorVolumetria3D() ? this.sensoresVolumetria3DEquipamentos : this.sensoresVolumetriaEquipamentos,
            this.formataArrayEquipamentosComponenteData(this.equipamentos)
        );
    }
}
