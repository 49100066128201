import { Injectable } from '@angular/core';
import { ApiTransportService } from 'app/core/services/transport';
import {
    HistoricoAlteracoesProgramaInterface,
    PaginacaoModalHistoricoInterface,
    ResponseInterface,
} from 'app/shared/interfaces';
import { ApiPaginacao, ProgramaModel, ProgramaResponseModel } from 'app/shared/models';
import { Observable } from 'rxjs';

import { IApiTransportInterface } from 'app/core/interfaces/';
import { IObjectToAnyService } from 'app/shared/services';

export abstract class IProgramaAeracaoController {
    abstract buscaProgramas(options?: ApiPaginacao): Observable<any>;
    abstract buscaListagemSimples(): Observable<ProgramaResponseModel[]>;

    abstract buscarHistorico(
        armazenagemREf: number,
        params: ApiPaginacao
    ): Observable<ResponseInterface<HistoricoAlteracoesProgramaInterface>>;

    abstract editarProgramaAeracaoPorDivisao(id: string, dados?: any): Observable<any>;
    abstract buscarLogProgramaAeracao(programa: PaginacaoModalHistoricoInterface): Observable<any>;
    abstract duplicarPrograma(id: string): Observable<ProgramaModel>;
    abstract exportarPrograma(id: string, unidadeId: string): Observable<ProgramaModel>;
    abstract editaProgramaAeracao(id: string, programa: ProgramaModel): Observable<ProgramaResponseModel>;

    abstract salvarPrograma(programa: ProgramaModel): Observable<ProgramaResponseModel>;
    abstract buscarProgramaId(id: string): Observable<any>;
}

@Injectable({
    providedIn: 'root',
})
export class ProgramaAeracaoController implements IProgramaAeracaoController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/aeracao');
    }

    buscaListagemSimples(): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/programa-aeracao/listagem-simples`);
    }

    buscaProgramas(options?: ApiPaginacao): Observable<any> {
        const arquivado = !options?.arquivado ? { 'filter.arquivado': 'false' } : {};
        const payload =
            options?.page >= 0
                ? {
                      page: options.page + 1,
                      limit: options.limit,
                      search: options.search ? options.search : '',
                      sortBy: 'ultima_alteracao:DESC',
                      ...arquivado,
                  }
                : {
                      page: 1,
                      limit: 1000,
                      ...{ 'filter.arquivado': 'false' },
                  };
        this.transport.options.params = this.objectToAnyService.toQueryString(payload);
        return this.transport.http.get(`${this.transport.path}/programa-aeracao`, this.transport.options);
    }

    buscarHistorico(
        armazenagemREf: number,
        params: ApiPaginacao
    ): Observable<ResponseInterface<HistoricoAlteracoesProgramaInterface>> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: params.page,
            limit: params.limit,
        });
        return this.transport.http.get<ResponseInterface<HistoricoAlteracoesProgramaInterface>>(
            `${this.transport.path}/programa-aeracao/estrutura_armazenagem_divisao/${armazenagemREf}`,
            {
                params: this.transport.options.params,
            }
        );
    }

    buscarLogProgramaAeracao(programa: PaginacaoModalHistoricoInterface): Observable<any> {
        const id = programa.id ? { 'filter.programa_aeracao_id': programa.id } : {};
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: programa.pageIndex,
            limit: programa.pageSize,
            sortBy: 'instante:DESC',
            ...id,
        });
        return this.transport.http.get(`${this.transport.path}/log-programa-aeracao`, this.transport.options);
    }

    editarProgramaAeracaoPorDivisao(id: string, dados?: any): Observable<any> {
        return this.transport.http.patch<any>(
            `${this.transport.path}/programa-aeracao/estrutura_armazenagem_divisao/${id}`,
            dados
        );
    }

    editaProgramaAeracao(id: string, programa: ProgramaModel): Observable<ProgramaResponseModel> {
        return this.transport.http.put<ProgramaResponseModel>(
            `${this.transport.path}/programa-aeracao/${id}`,
            programa
        );
    }

    duplicarPrograma(id: string): Observable<ProgramaModel> {
        return this.transport.http.post<ProgramaModel>(`${this.transport.path}/programa-aeracao/duplicacao/${id}`, {});
    }

    exportarPrograma(id: string, unidadeId: string): Observable<ProgramaModel> {
        return this.transport.http.post<ProgramaModel>(`${this.transport.path}/programa-aeracao/duplicacao/${id}`, {
            unidade: unidadeId,
        });
    }

    buscarProgramaId(id: string): Observable<any> {
        return this.transport.http.get(`${this.transport.path}/programa-aeracao/${id}`);
    }

    salvarPrograma(programa: ProgramaModel): Observable<ProgramaResponseModel> {
        return this.transport.http.post<ProgramaResponseModel>(`${this.transport.path}/programa-aeracao`, programa);
    }
}
