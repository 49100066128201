<div
    *ngIf="dadosTabelaInformacoesAeracao.data.length; else carregandoDados"
    class="w-full bg-[#F5F6F7] items-center flex flex-col pr-2 pt-1 pb-2"
>
    <p class="wl-cor-primaria text-base">{{ 'AERADORES' | transloco }}</p>
    <div class="w-full flex flex-row mb-1">
        <div class="w-[25px]"></div>
        <div class="w-[35px]">
            <span class="text-xs wl-cor-primaria">
                {{ 'VISAO_DETALHADA_SILOS_SCREEN.CMD' | transloco }}
            </span>
        </div>
        <div class="w-[35px]">
            <span class="text-xs wl-cor-primaria">
                {{ 'VISAO_DETALHADA_SILOS_SCREEN.RTN' | transloco }}
            </span>
        </div>
        <div class="w-[35px]">
            <span class="text-xs wl-cor-primaria">
                {{ 'VISAO_DETALHADA_SILOS_SCREEN.PNL' | transloco }}
            </span>
        </div>
    </div>
    <div
        *ngFor="let elemento of dadosTabelaInformacoesAeracao.data"
        class="w-full flex flex-row mb-1"
        (mouseenter)="tooltipAerador = elemento"
        (mouseleave)="tooltipAerador = null"
    >
        <div class="flex items-center">
            <div class="text-center lg:text-center sm:text-left text-xs sm:text-base w-[21px] ml-1">
                {{ elemento?.aerador?.codigo ?? '-' }}
            </div>
        </div>
        <div class="flex items-center">
            <div class="text-left w-[35px]">
                <app-status-on-off-armazem
                    [reduzirWidth]="true"
                    [statusOn]="elemento?.aerador?.aerador_leitura_rele?.valor"
                    [equipamentoComunicando]="
                        buscaEquipamentoStatus(elemento?.aerador?.aerador_leitura_rele?.equipamento_id)
                    "
                    [possuiStatusComunicacao]="true"
                ></app-status-on-off-armazem>
            </div>
        </div>
        <div class="flex items-center">
            <div class="text-left w-[35px]">
                <app-status-on-off-armazem
                    [reduzirWidth]="true"
                    [statusOn]="elemento?.aerador?.aerador_leitura_status?.valor"
                    [equipamentoComunicando]="
                        buscaEquipamentoStatus(elemento?.aerador?.aerador_leitura_status?.equipamento_id)
                    "
                    [possuiStatusComunicacao]="true"
                ></app-status-on-off-armazem>
            </div>
        </div>
        <div class="flex items-center">
            <div class="text-left w-[35px]">
                <app-status-automatico-manual-armazem
                    [reduzirWidth]="true"
                    [statusAutomatico]="elemento.aerador?.aerador_leitura_chave?.valor"
                    [possuiStatusComunicacao]="true"
                    [equipamentoComunicando]="
                        buscaEquipamentoStatus(elemento?.aerador?.aerador_leitura_chave?.equipamento_id)
                    "
                ></app-status-automatico-manual-armazem>
            </div>
        </div>
        <ng-container *ngIf="tooltipAerador === elemento">
            <app-tooltip-aerador
                [aerador]="elemento"
                [visaoPlanificadaArmazem]="visaoPlanificadaArmazem"
            ></app-tooltip-aerador>
        </ng-container>
    </div>
</div>

<ng-template #carregandoDados>
    <mat-progress-spinner [diameter]="20" [mode]="'indeterminate'" class="ml-2"> </mat-progress-spinner>
</ng-template>
