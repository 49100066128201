import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatusAeracaoModel } from 'app/shared/models';
import { IConfigurarStatusAeracaoService } from 'app/shared/services';
import { ModalStatusAeracaoComponent } from 'app/shared/components';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AeradorStatuSodeEnum } from 'app/shared/enums';

const configuracoesTamanho = {
    widthDesktopPx: '650px',
    heightDesktopPx: '416px',
    breakpointMobile: '(max-width: 639px)',
    widthMobilePx: '100%',
    heightMobilePx: 'auto',
};
@Component({
    selector: 'app-status-aeracao-armazem',
    templateUrl: './status-aeracao-armazem.component.html',
    styleUrls: ['./status-aeracao-armazem.component.scss'],
})
export class StatusAeracaoArmazemComponent implements OnInit {
    @Input() statusCode?: AeradorStatuSodeEnum;
    
    configuracaoStatusAerador: StatusAeracaoModel;

    constructor(
        private configurarStatusAeracaoService: IConfigurarStatusAeracaoService,        
    ) {}

    ngOnInit() {
        this.configuracaoStatusAerador = this.configurarStatusAeracaoService.execute(this.statusCode);        
    }   
}
