<div class="w-full flex justify-between items-center mt-0.5">
    <div class="flex items-center justify-start h-[30px] bg-[#F8FAFB99] min-w-[120px] rounded-md">
        <span class="text-lg capitalize truncate pl-1 pr-2">
            {{ nomeProduto ?? " - " }}
        </span>
        <img class="w-[19px]" src="{{ iconeProduto }}" />
    </div>
    <div class="flex flex-row w-full justify-around">
        <div class="flex flex-col">
            <span
                class="text-[#535767] text-xs text-left font-normal"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TOOLTIP_EHAMB' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.EHAMB" | transloco }}
            </span>
            <span
                class="font-semibold text-base leading-4"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.ehamb && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.ehamb) ?? "-" }}
            </span>
        </div>
        <div class="flex flex-col">
            <span
                class="text-[#535767] text-xs text-left font-normal"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TOOLTIP_AQ' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.AQ" | transloco }}
            </span>
            <span
                class="font-semibold text-base leading-4"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.aq && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.aq) ?? "-" }}
            </span>
        </div>
        <div class="flex flex-col">
            <span
                class="text-[#535767] text-xs text-left font-normal"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TOOLTIP_TAQ' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TAQ" | transloco }}
            </span>
            <span
                class="font-semibold text-base leading-4"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.taq && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.taq) ?? "-" }}
            </span>
        </div>
        <div class="flex flex-col">
            <span
                class="text-[#535767] text-xs text-left font-normal"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TOOLTIP_URAQ' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.URAQ" | transloco }}
            </span>
            <span
                class="font-semibold text-base leading-4"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.uraq && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.uraq) ?? "-" }}
            </span>
        </div>
        <div class="flex flex-col">
            <span
                class="text-[#535767] text-xs text-left font-normal"
                matTooltip="{{ 'DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.TOOLTIP_EHAQ' | transloco }}"
                matTooltipClass="tooltip-padrao"
            >
                {{ "DADOS_EQUILIBRIO_HIGROSCOPICO_TEMPLATE.EHAQ" | transloco }}
            </span>
            <span
                class="font-semibold text-base leading-4"
                [ngClass]="
                    equilibrioHigroscopico?.equilibrio_higroscopico?.ehaq && temEstacaoAtiva
                        ? 'wl-cor-primaria'
                        : 'text-[#535767]'
                "
            >
                {{ formataCampo(equilibrioHigroscopico?.equilibrio_higroscopico?.ehaq) ?? "-" }}
            </span>
        </div>
    </div>
</div>
