import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TranslocoService } from '@ngneat/transloco';
import {
    ModalAcionamentoManulAeracaoComponent,
    ModalConfirmacaoComponent,
    ModalHistoricoAlteracoesProgramaComponent,
    ModalHistoricoComandoAeracaoComponent,
    ModalHistoricoControleAeracaoComponent,
    ModalHistoricoVariavelAquecimentoComponent,
} from 'app/shared/components';
import { configuracaoTamanhoModal } from 'app/shared/constants';
import {
    AeradorStatuSodeEnum,
    InformacoesAeracaoVisaoColunasEnum,
    MensagensModalConfirmacaoEnum,
    TipoCampoEmEdicaoEnum,
    VariavelAquecimentoEnum,
} from 'app/shared/enums';
import {
    AeradorInterface,
    EditaInformacoesAeracaoPayloadInterface,
    InformacoesSiloInterface,
    ProgramaAeracaoInterface,
    ResponseInterface,
    ResumoUnidadeInterface,
} from 'app/shared/interfaces';
import { PaginatorClass, ProgramaResponseModel, StatusAeracaoModel } from 'app/shared/models';
import { UnidadeParametrizacaoObservable } from 'app/shared/observables';
import {
    IConfigurarStatusAeracaoService,
    IFormataEquipamentosComponenteDataService,
    IRetornaObjetivoProgramaAeracaoService,
} from 'app/shared/services';
import { take } from 'rxjs';

@Component({
    selector: 'app-aeracao-visao-detalhada',
    templateUrl: './aeracao-visao-detalhada.component.html',
    styleUrls: ['./aeracao-visao-detalhada.component.scss'],
})
export class AeracaoVisaoDetalhadaComponent implements OnChanges {
    @Output() editaCampoControle = new EventEmitter<EditaInformacoesAeracaoPayloadInterface>();
    @Output() editaCampoPrograma = new EventEmitter<EditaInformacoesAeracaoPayloadInterface>();
    @Output() editaCampoVariavel = new EventEmitter<EditaInformacoesAeracaoPayloadInterface>();
    @Output() envioDoComando = new EventEmitter<any>();

    @Input() listaDeProgramas?: ProgramaResponseModel[];
    @Input() variavelAquecimento?: VariavelAquecimentoEnum | undefined;
    @Input() programaSetadoSilo: ProgramaAeracaoInterface;
    @Input() tempoAeracaoAutomatica?: string | undefined;
    @Input() tempoAeracaoManual?: string | undefined;
    @Input() editarAearacaoErro?: string | undefined;
    @Input() listagemAeradores: AeradorInterface[];
    @Input() statusAeracao: AeradorStatuSodeEnum;
    @Input() historicoSilo?: boolean | false;
    @Input() silo: InformacoesSiloInterface;
    @Input() carregando?: boolean | false;
    @Input() controleAeracao: boolean;
    @Input() comandoAeracao: boolean;
    @Input() ocultarControles: boolean;
    @Input() permissaoAdministrar: boolean;

    dadosTabelaInformacoesAeracao = new MatTableDataSource<any>();
    variavelAquecimentoControle: UntypedFormControl = new UntypedFormControl();
    programaControle: UntypedFormControl = new UntypedFormControl();
    tipoCampoEmEdicaoEnum = TipoCampoEmEdicaoEnum;
    parametrosUnidade: ResumoUnidadeInterface;
    listaVariavelAquecimento = {};
    objetivoProgramaUsado: string;
    loadInicial: boolean = true;
    campoEmEdicao: string;

    tabelaColunas: string[] = [
        InformacoesAeracaoVisaoColunasEnum.ID,
        InformacoesAeracaoVisaoColunasEnum.CMD,
        InformacoesAeracaoVisaoColunasEnum.RTN,
        InformacoesAeracaoVisaoColunasEnum.PNL,
        InformacoesAeracaoVisaoColunasEnum.IN_A,
        InformacoesAeracaoVisaoColunasEnum.P_CV,
        InformacoesAeracaoVisaoColunasEnum.STATUS,
    ];

    Object = Object;
    aeradoresPaginados: AeradorInterface[] = [];
    valoresPaginacao: PaginatorClass = {
        previousPageIndex: 0,
        pageIndex: 0,
        pageSize: 10,
        length: 0,
    };

    private nenhum = 'null';
    private iconeModal = {
        icone: 'warning_amber',
        cor_size: 'fill-[#019DE8] icon-size-16',
    };

    constructor(
        private retornaObjetivoProgramaAeracaoService: IRetornaObjetivoProgramaAeracaoService,
        private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable,
        private configurarStatusAeracaoService: IConfigurarStatusAeracaoService,
        private breakpointObserver: BreakpointObserver,
        private formataEquipamentosComponenteDataService: IFormataEquipamentosComponenteDataService,
        private transloco: TranslocoService,
        readonly dialog: MatDialog
    ) {}

    ngOnChanges(): void {
        this.desabilitaControles();
        this.dadosTabelaInformacoesAeracao.data = [];
        if (this.listagemAeradores?.length > 0) {
            this.buscarUnidadesParametrizacao();
            this.configurarPaginacao(this.valoresPaginacao);
        }
        if (this.editarAearacaoErro || this.loadInicial) {
            this.setaValoresIniciais();
            this.loadInicial = false;
        }
    }

    configurarPaginacao(event: PageEvent): void {
        this.valoresPaginacao.pageIndex = event.pageIndex;

        const totalItems = this.listagemAeradores.length;
        const aeradores = this.listagemAeradores;
        const startIndex = event.pageIndex * event.pageSize;
        this.valoresPaginacao.length = totalItems;
        let endIndex = startIndex + event.pageSize;

        if (endIndex > totalItems) {
            endIndex = totalItems;
        }
        this.dadosTabelaInformacoesAeracao.data = aeradores.slice(startIndex, endIndex);
    }

    setaValoresIniciais(): void {
        if (this.historicoSilo) {
            this.programaControle.disable();
            this.variavelAquecimentoControle.disable();
        }
        if (this.programaSetadoSilo?.id && this.listaDeProgramas?.length) {
            this.programaControle.setValue(this.programaSetadoSilo?.id);
            this.configuraObjetivoProgramaUsado(this.programaSetadoSilo?.id);
        } else {
            this.programaControle.setValue(this.nenhum);
            this.objetivoProgramaUsado = undefined;
        }
        this.setaVariavelAquecimento();
    }

    setaVariavelAquecimento(): void {
        if (this.variavelAquecimento) {
            for (const variavel in this.listaVariavelAquecimento) {
                if (this.listaVariavelAquecimento[variavel] === this.variavelAquecimento) {
                    this.variavelAquecimentoControle.setValue(
                        Object.keys(this.listaVariavelAquecimento)[
                            Object.values(this.listaVariavelAquecimento).indexOf(
                                this.listaVariavelAquecimento[variavel]
                            )
                        ]
                    );
                }
            }
        }
    }

    buscaEquipamentoStatus(equipamentoId: string): boolean {
        const equipamentos = this.formataEquipamentosComponenteDataService.execute(this.silo.equipamentos);
        return equipamentos.find((equipamento) => equipamento?.id === equipamentoId)?.equipamento_comunicacao?.status;
    }

    configuraObjetivoProgramaUsado(idProgrma: number): void {
        const programaSelecionado = this.listaDeProgramas.find((progrma) => progrma.id === idProgrma);
        this.objetivoProgramaUsado = this.retornaObjetivoProgramaAeracaoService.execute(
            programaSelecionado?.objetivo_programa
        );
    }

    objetivoProgramaUsadoHistorico(objetivo_programa): string | undefined {
        return this.retornaObjetivoProgramaAeracaoService.execute(objetivo_programa);
    }

    retornarConfiguracaoStatusAeracao(codigo: AeradorStatuSodeEnum): StatusAeracaoModel {
        return this.configurarStatusAeracaoService.execute(codigo);
    }

    abrirModalAcionamentoManual(configuracao: StatusAeracaoModel, silo: InformacoesSiloInterface): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        if (!configuracao.acionamento_manual.habilitado) return;
        const dialogRef = this.dialog.open(ModalAcionamentoManulAeracaoComponent, {
            minWidth: mobile
                ? configuracaoTamanhoModal.informativo.mobile.width
                : configuracaoTamanhoModal.informativo.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.informativo.mobile.height
                : configuracaoTamanhoModal.informativo.desktop.height,
            data: {
                configuracaoStatus: configuracao,
                unidadeArmazenagem: silo,
            },
        });
        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe({
                next: (confirmed) => {
                    if (confirmed) {
                        this.envioDoComando.emit(silo.id);
                    }
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    editarControleAeracao(event?: any): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        if (event === this.controleAeracao) return;
        const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
            width: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.width
                : configuracaoTamanhoModal.confirmacao.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.height
                : configuracaoTamanhoModal.confirmacao.desktop.height,
            data: {
                mensagem: MensagensModalConfirmacaoEnum.ALTERAR_CONTROLE_AERACAO,
                icone: this.iconeModal.icone,
                cor_size: this.iconeModal.cor_size,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe({
                next: (confirmed) => {
                    if (confirmed) {
                        this.campoEmEdicao = this.tipoCampoEmEdicaoEnum.CONTROLE;
                        this.controleAeracao = !this.controleAeracao;
                        this.editaCampoControle.emit({
                            controle_aeracao_automatica: event,
                        });
                    }
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    customizaPanelSelect(): void {
        if (!this.permissaoAdministrar) return;
        const panel = document.getElementById('select-aeracao');
        const panelSelect = document.getElementById('select-aeracao-panel');
        const panelClass: any = document.getElementsByClassName('selectAeracao');
        const acrecentaLeft = panelSelect.offsetWidth - panel.offsetWidth < 100 ? 20 : -20;
        panelClass[0].style.left = `-${panelSelect.offsetWidth - panel.offsetWidth + acrecentaLeft}px`;
    }

    editarPrograma(event?: any): void {
        if (!this.permissaoAdministrar) return;
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
            width: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.width
                : configuracaoTamanhoModal.confirmacao.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.height
                : configuracaoTamanhoModal.confirmacao.desktop.height,
            data: {
                mensagem: MensagensModalConfirmacaoEnum.ALTERAR_PROGRAMA_AERACAO,
                icone: this.iconeModal.icone,
                cor_size: this.iconeModal.cor_size,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe({
                next: (confirmed) => {
                    if (confirmed) {
                        this.campoEmEdicao = this.tipoCampoEmEdicaoEnum.PROGRAMA;
                        this.editaCampoPrograma.emit({
                            programa_aeracao_id: event === 'null' ? null : event,
                        });
                        this.configuraObjetivoProgramaUsado(event);
                    }
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    editarVariavel(event?: any): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
            width: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.width
                : configuracaoTamanhoModal.confirmacao.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.height
                : configuracaoTamanhoModal.confirmacao.desktop.height,
            data: {
                mensagem: MensagensModalConfirmacaoEnum.ALTERAR_VARIAVEL_AERACAO,
                icone: this.iconeModal.icone,
                cor_size: this.iconeModal.cor_size,
            },
        });

        dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe({
                next: (confirmed) => {
                    if (confirmed) {
                        this.campoEmEdicao = this.tipoCampoEmEdicaoEnum.VARIAVEL_AQUECIMENTO;
                        this.editaCampoVariavel.emit({
                            programa_aeracao_id:
                                this.programaControle.value === 'null' ? null : this.programaControle.value,
                            controle_aeracao_automatica: this.controleAeracao,
                            aquecimento_ar: event === 'null' ? VariavelAquecimentoEnum.aquecimento_ar_2 : event,
                            valor: this.listaVariavelAquecimento[event],
                        });
                    }
                },
                error: (error) => {
                    console.log(error);
                },
            });
    }

    mostrarHistoricoControleAeracao(armazenagemRef: string): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        this.dialog.open(ModalHistoricoControleAeracaoComponent, {
            minWidth: mobile
                ? configuracaoTamanhoModal.comTabela.mobile.width
                : configuracaoTamanhoModal.comTabela.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.comTabela.mobile.height
                : configuracaoTamanhoModal.comTabela.desktop.height,
            data: { armazenagemRef },
        });
    }

    mostrarHistoricoComandoAeracao(armazenagemRef: string): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        this.dialog.open(ModalHistoricoComandoAeracaoComponent, {
            minWidth: mobile
                ? configuracaoTamanhoModal.comTabela.mobile.width
                : configuracaoTamanhoModal.comTabela.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.comTabela.mobile.height
                : configuracaoTamanhoModal.comTabela.desktop.height,
            data: { armazenagemRef },
        });
    }

    mostrarHistoricoAlteracoesPrograma(armazenagemRef: string): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        this.dialog.open(ModalHistoricoAlteracoesProgramaComponent, {
            minWidth: mobile
                ? configuracaoTamanhoModal.comTabela.mobile.width
                : configuracaoTamanhoModal.comTabela.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.comTabela.mobile.height
                : configuracaoTamanhoModal.comTabela.desktop.height,
            data: { armazenagemRef },
        });
    }

    mostrarHistoricoVariavelAquecimento(armazenagemRef: string): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        this.dialog.open(ModalHistoricoVariavelAquecimentoComponent, {
            minWidth: mobile
                ? configuracaoTamanhoModal.comTabela.mobile.width
                : configuracaoTamanhoModal.comTabela.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.comTabela.mobile.height
                : configuracaoTamanhoModal.comTabela.desktop.height,
            data: { armazenagemRef },
        });
    }

    private desabilitaControles(): void {
        if (!this.permissaoAdministrar) {
            this.programaControle.disable();
            this.variavelAquecimentoControle.disable();
        }
    }

    private buscarUnidadesParametrizacao(): void {
        this.unidadeParametrizacaoObservable.buscaParametrosUnidade$.pipe(take(1)).subscribe((parametros) => {
            this.parametrosUnidade = parametros;
            this.listaVariavelAquecimento = {
                aquecimento_ar_1: parametros.unidade_parametrizacao.aquecimento_ar_1,
                aquecimento_ar_2: parametros.unidade_parametrizacao.aquecimento_ar_2,
                aquecimento_ar_3: parametros.unidade_parametrizacao.aquecimento_ar_3,
            };
        });
    }
}
