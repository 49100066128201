import { Component, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InformacoesAeracaoVisaoColunasEnum } from 'app/shared/enums';
import { AeradorInterface, InformacoesEstruturaArmazenagemInterface } from 'app/shared/interfaces';
import { IFormataEquipamentosComponenteDataService } from 'app/shared/services';

@Component({
    selector: 'listagem-aeradores-armazem',
    templateUrl: './listagem-aeradores-armazem.component.html',
    styleUrls: ['./listagem-aeradores-armazem.component.scss'],
})
export class ListagemAeradoresArmazemComponent implements OnChanges {
    @Input() listagemAeradores: AeradorInterface[];
    @Input() armazem: InformacoesEstruturaArmazenagemInterface;
    @Input() visaoPlanificadaArmazem: boolean = false;

    tooltipAerador: AeradorInterface | null;
    
    dadosTabelaInformacoesAeracao = new MatTableDataSource<AeradorInterface>();

    tabelaColunas: string[] = [
        InformacoesAeracaoVisaoColunasEnum.ID,
        InformacoesAeracaoVisaoColunasEnum.CMD,
        InformacoesAeracaoVisaoColunasEnum.RTN,
        InformacoesAeracaoVisaoColunasEnum.PNL,
    ];

    constructor(
        private formataEquipamentosComponenteDataService: IFormataEquipamentosComponenteDataService,
    ) {}    

    ngOnChanges(): void {        
        this.dadosTabelaInformacoesAeracao.data = this.listagemAeradores;
    }

    buscaEquipamentoStatus(equipamentoId: string): boolean {
        const equipamentos = this.formataEquipamentosComponenteDataService.execute(this.armazem.equipamentos);
        return equipamentos.find((equipamento) => equipamento?.id === equipamentoId)?.equipamento_comunicacao?.status;        
    }
}
