export enum IconesProdutos {
    ICONE_ARROZ = 'assets/media/products/icon-product-rice.svg',
    ICONE_AVEIA = 'assets/media/products/icon-product-aveia.svg',
    ICONE_AZEVEM = 'assets/media/products/icon-product-azevem.svg',
    ICONE_BRACHIARIA = 'assets/media/products/icon-product-brachiaria.svg',
    ICONE_CAFEDESCASCADO = 'assets/media/products/icon-product-cafe-descascado.svg',
    ICONE_CAFEPERGAMINHO = 'assets/media/products/icon-product-cafe-pergaminho.svg',
    ICONE_CANOLA = 'assets/media/products/icon-product-canola.svg',
    ICONE_CAROCODEALGODAO = 'assets/media/products/icon-product-caroco-algodao.svg',
    ICONE_CEVADA = 'assets/media/products/icon-product-cevada.svg',
    ICONE_ERVILHA = 'assets/media/products/icon-product-ervilha.svg',
    ICONE_FEIJAO = 'assets/media/products/icon-product-feijao.svg',
    ICONE_GERGELIM = 'assets/media/products/icon-product-gergelim.svg',
    ICONE_GIRASSOL = 'assets/media/products/icon-product-girassol.svg',
    ICONE_MILHETO = 'assets/media/products/icon-product-milheto.svg',
    ICONE_MILHO = 'assets/media/products/icon-product-corn.svg',
    ICONE_MILHOPIPOCA = 'assets/media/products/icon-product-milho-pipoca.svg',
    ICONE_NABOFORRAGEIRO = 'assets/media/products/icon-product-nabo-forrageiro.svg',
    ICONE_PAINCO = 'assets/media/products/icon-product-painco.svg',
    ICONE_SOJA = 'assets/media/products/icon-product-soy.svg',
    ICONE_SORGO = 'assets/media/products/icon-product-sorgo.svg',
    ICONE_TRIGO = 'assets/media/products/icon-product-wheat.svg',
    ICONE_TRITICALE = 'assets/media/products/icon-product-triticale.svg',
    ICONE_OUTROS = 'assets/media/products/icon-product-others.svg',
    ICONE_CROTALARIA = 'assets/media/products/icon-product-crotalaria.svg',
    ICONE_CENTEIO = 'assets/media/products/icon-product-centeio.svg',
}
