import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AmostragemInformacoesGeraisArmazemComponent } from 'app/shared/components/amostragem-informacoes-gerais-armazem/amostragem-informacoes-gerais-armazem.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconsModule } from 'app/shared/icons/icons.module';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [CommonModule, MatTooltipModule, MatIconModule, TranslocoModule, IconsModule],
    declarations: [AmostragemInformacoesGeraisArmazemComponent],
    exports: [AmostragemInformacoesGeraisArmazemComponent],
})
export class AmostragemInformacoesGeraisArmazemModule {}
