import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
	AmostragemEstruturaArmazenagemController,
	ArmazenagemEquilibrioHigroscopicoController,
	BuscaGraoPorEstruturaController,
	BuscaSensoresPlenumController,
	ClientesController,
	ComandoAeracaoAutomaticaController,
	ControleAeracaoAutomaticaController,
	EquilibrioHigroscopicoController,
	EquipamentosController,
	EstacaoMeteorologicaController,
	EstacaoMeteorologicaLocalController,
	EstruturaAmostragemController,
	EstruturaArmazenagemController,
	EstruturaArmazenagemSensores3DController,
	EstruturaPerfilController,
	EstruturaUsuarioController,
	GraficoSecadorController,
	GraosUnidadeController,
	IAmostragemEstruturaArmazenagemController,
	IArmazenagemEquilibrioHigroscopicoController,
	IBuscaGraoPorEstruturaController,
	IBuscaSensoresPlenumController,
	IClientesController,
	IComandoAeracaoAutomaticaController,
	IControleAeracaoAutomaticaController,
	IEquilibrioHigroscopicoController,
	IEquipamentosController,
	IEstacaoMeteorologicaController,
	IEstacaoMeteorologicaLocalController,
	IEstruturaAmostragemController,
	IEstruturaArmazenagemController,
	IEstruturaArmazenagemSensores3DController,
	IEstruturaPerfilController,
	IEstruturaUsuarioController,
	IGraficoSecadorController,
	IGraosUnidadeController,
	IListaArmazensComSubcelulasController,
	IListaProdutosEquilibrioEstruturaController,
	IListagemArmazenagemController,
	IListagemRegrasController,
	IPendulosEstruturaArmazenagemDivisaoController,
	IProdutoTipoController,
	IProgramaAeracaoController,
	IResumoUnidadeController,
	ISecadorController,
	ISilosController,
	IUltimaLeituraEstruturaAmostragemController,
	IUnidadesController,
	IUsuarioController,
	IVariavelAquecimentoController,
	ListaArmazensComSubcelulasController,
	ListaProdutosEquilibrioEstruturaController,
	ListagemArmazenagemController,
	ListagemRegrasController,
	PendulosEstruturaArmazenagemDivisaoController,
	ProdutoTipoController,
	ProgramaAeracaoController,
	ResumoUnidadeController,
	SecadorController,
	SilosController,
	UltimaLeituraEstruturaAmostragemController,
	UnidadesController,
	UsuarioController,
	VariavelAquecimentoController,
} from 'app/shared/controllers';
import {
	ConexaoInternetObservable,
	IUnidadeProdutosObservable,
	SincronizadorEquipamentoObservable,
	TabScreenObservable,
	UnidadeProdutosObservable,
} from 'app/shared/observables';
import {
	AgrupaPendulosPorDivisaoService,
	ApenasNumerosPermitidosService,
	AplicaPlanoCorteSilo3DService,
	AtualizaStatusConexaoInternetService,
	CalculaAqplenumService,
	ConfiguraEspacoSensoresMapaSiloService,
	ConfigurarStatusAeracaoService,
	ControleAcoesHistoricoService,
	ControleTrocaEstruturaArmazenagemService,
	CriaBotoesSilo3DService,
	CriaElementosVisuaisVisaoSuperiorArmazemService,
	CriaEscadaSilo3DService,
	CriaGraoSilo3DService,
	CriaPaletaCoresCondicaoArmazenagemService,
	CriaPaletaCoresGraoSecadoresQualidadeService,
	CriaPaletaCoresGraoSecadoresService,
	CriaPaletaCoresSecadoresService,
	CriaPaletaCoresTermometriaService,
	CriaSilo3DBasePlanaService,
	CriaSilo3DBaseSemiVService,
	CriaSilo3DBaseVService,
	CriaTexturaGraoSilo3DService,
	DefineAeradoresPorDivisaoService,
	DefineCategoriaEquipamentoService,
	DefineGraficoBarrasService,
	DefineGraficoService,
	DefineMaterialConstrucaoSilo3DService,
	DesenhaAneisVisaoSuperiorService,
	DesenhaPendulosVisaoSuperiorService,
	DesestruturaStringFormulaListagemService,
	DesestruturaStringFormulaParaObjetoService,
	FormataCampoValorService,
	FormataEquipamentosComponenteDataService,
	FormataHHMMMinutosService,
	FormataLeiturasSensor3DService,
	FormataMinutosHHMMSSService,
	FormatarTempoService,
	FormataSegundosHHMMSSService,
	IAgrupaPendulosPorDivisaoService,
	IApenasNumerosPermitidosService,
	IAplicaPlanoCorteSilo3DService,
	IAtualizaStatusConexaoInternetService,
	ICalculaAqplenumService,
	IConfiguraEspacoSensoresMapaSiloService,
	IConfigurarStatusAeracaoService,
	IControleAcoesHistoricoService,
	IControleTrocaEstruturaArmazenagemService,
	ICriaBotoesSilo3DService,
	ICriaElementosVisuaisVisaoSuperiorArmazemService,
	ICriaEscadaSilo3DService,
	ICriaGraoSilo3DService,
	ICriaPaletaCoresCondicaoArmazenagemService,
	ICriaPaletaCoresGraoSecadoresQualidadeService,
	ICriaPaletaCoresGraoSecadoresService,
	ICriaPaletaCoresSecadoresService,
	ICriaPaletaCoresTermometriaService,
	ICriaSilo3DBasePlanaService,
	ICriaSilo3DBaseSemiVService,
	ICriaSilo3DBaseVService,
	ICriaTexturaGraoSilo3DService,
	IDefineAeradoresPorDivisaoService,
	IDefineCategoriaEquipamentoService,
	IDefineGraficoBarrasService,
	IDefineGraficoService,
	IDefineMaterialConstrucaoSilo3DService,
	IDesenhaAneisVisaoSuperiorService,
	IDesenhaPendulosVisaoSuperiorService,
	IDesestruturaStringFormulaListagemService,
	IDesestruturaStringFormulaParaObjetoService,
	IFormataCampoValorService,
	IFormataEquipamentosComponenteDataService,
	IFormataHHMMMinutosService,
	IFormataLeiturasSensor3DService,
	IFormataMinutosHHMMSSService,
	IFormatarTempoService,
	IFormataSegundosHHMMSSService,
	IMaximoNumerosDigitadosService,
	IMontaFormaGeometricaVisaoSuperiorArmazemService,
	IMontaListaEquipamentosSensoresService,
	IMontraVetorSensoresSilo3DService,
	IMostrarTemperaturaPenduloSilo3DService,
	IObjectSubmitService,
	IObjectToAnyService,
	IOrdenarEstacoesService,
	IPossuiTodasInformacoesSilo3DService,
	IRetonaInstanteMaisRecenteAeradoresService,
	IRetonaInstanteMaisRecenteEquipamentoService,
	IRetornaAlturaPendulosSilo3DService,
	IRetornaDiasSemanaEnumService,
	IRetornaDiasSemanaExibicaoService,
	IRetornaEstiloStatusComunicacao,
	IRetornaIdiomaPelaSiglaService,
	IRetornaObjetivoProgramaAeracaoService,
	IRetornaRangeEntreDatasService,
	IRetornaStatusComunicacaoService,
	IRetornaSvgAeradorService,
	IRetornaTelefoneFormatadoService,
	IRetornaUnidadeMedidaPorTipoSensorService,
	ISincronizarPendulosSilo3DService,
	ITrataTimezoneRegraService,
	IValidaMapaArmazenagemService,
	IVerificaComunicacaoService,
	IVerificaValidadeDeAmostragemService,
	MaximoNumerosDigitadosService,
	MontaFormaGeometricaVisaoSuperiorArmazemService,
	MontaListaEquipamentosSensoresService,
	MontraVetorSensoresSilo3DService,
	MostrarTemperaturaPenduloSilo3DService,
	ObjectSubmitService,
	ObjectToAnyService,
	OrdenarEstacoesService,
	PossuiTodasInformacoesSilo3DService,
	RetonaInstanteMaisRecenteAeradoresService,
	RetonaInstanteMaisRecenteEquipamentoService,
	RetornaAlturaPendulosSilo3DService,
	RetornaDiasSemanaEnumService,
	RetornaDiasSemanaExibicaoService,
	RetornaEstiloStatusComunicacao,
	RetornaIdiomaPelaSiglaService,
	RetornaObjetivoProgramaAeracaoService,
	RetornaRangeEntreDatasService,
	RetornaStatusComunicacaoService,
	RetornaSvgAeradorService,
	RetornaTelefoneFormatadoService,
	RetornaUnidadeMedidaPorTipoSensorService,
	SetaTabScreenService,
	SincronizarPendulosSilo3DService,
	StatusRequisicaoHttpService,
	TrataTimezoneRegraService,
	ValidaMapaArmazenagemService,
	VerificaComunicacaoService,
	VerificaValidadeDeAmostragemService,
} from 'app/shared/services';
import { TranslocoRootModule } from 'app/transloco-root.module';
@NgModule({
	imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslocoRootModule],
	providers: [
		ConexaoInternetObservable,
		DecimalPipe,
		SetaTabScreenService,
		TabScreenObservable,
		SincronizadorEquipamentoObservable,
		StatusRequisicaoHttpService,
		{ provide: IObjectToAnyService, useClass: ObjectToAnyService },
		{ provide: IObjectSubmitService, useClass: ObjectSubmitService },
		{ provide: IObjectSubmitService, useClass: ObjectSubmitService },
		{
			provide: ICriaPaletaCoresTermometriaService,
			useClass: CriaPaletaCoresTermometriaService,
		},
		{
			provide: ICriaPaletaCoresSecadoresService,
			useClass: CriaPaletaCoresSecadoresService,
		},
		{
			provide: ICriaPaletaCoresGraoSecadoresService,
			useClass: CriaPaletaCoresGraoSecadoresService,
		},
		{
			provide: ICriaPaletaCoresGraoSecadoresQualidadeService,
			useClass: CriaPaletaCoresGraoSecadoresQualidadeService,
		},
		{
			provide: ICriaPaletaCoresCondicaoArmazenagemService,
			useClass: CriaPaletaCoresCondicaoArmazenagemService,
		},
		{
			provide: IDefineCategoriaEquipamentoService,
			useClass: DefineCategoriaEquipamentoService,
		},
		{ provide: IDefineGraficoService, useClass: DefineGraficoService },
		{ provide: ISilosController, useClass: SilosController },
		{ provide: IEstacaoMeteorologicaController, useClass: EstacaoMeteorologicaController },
		{ provide: IRetornaRangeEntreDatasService, useClass: RetornaRangeEntreDatasService },
		{ provide: IRetornaIdiomaPelaSiglaService, useClass: RetornaIdiomaPelaSiglaService },
		{ provide: IEquipamentosController, useClass: EquipamentosController },
		{ provide: IEstacaoMeteorologicaLocalController, useClass: EstacaoMeteorologicaLocalController },
		{ provide: IOrdenarEstacoesService, useClass: OrdenarEstacoesService },
		{ provide: IRetornaStatusComunicacaoService, useClass: RetornaStatusComunicacaoService },
		{
			provide: IRetonaInstanteMaisRecenteEquipamentoService,
			useClass: RetonaInstanteMaisRecenteEquipamentoService,
		},
		{
			provide: IRetornaEstiloStatusComunicacao,
			useClass: RetornaEstiloStatusComunicacao,
		},
		{
			provide: IConfiguraEspacoSensoresMapaSiloService,
			useClass: ConfiguraEspacoSensoresMapaSiloService,
		},

		{
			provide: IListagemRegrasController,
			useClass: ListagemRegrasController,
		},
		{
			provide: IDesestruturaStringFormulaListagemService,
			useClass: DesestruturaStringFormulaListagemService,
		},
		{
			provide: IConfigurarStatusAeracaoService,
			useClass: ConfigurarStatusAeracaoService,
		},
		{
			provide: IUnidadeProdutosObservable,
			useClass: UnidadeProdutosObservable,
		},
		{
			provide: IRetonaInstanteMaisRecenteAeradoresService,
			useClass: RetonaInstanteMaisRecenteAeradoresService,
		},
		{
			provide: IRetornaObjetivoProgramaAeracaoService,
			useClass: RetornaObjetivoProgramaAeracaoService,
		},
		{
			provide: IDesenhaPendulosVisaoSuperiorService,
			useClass: DesenhaPendulosVisaoSuperiorService,
		},
		{
			provide: IDesenhaAneisVisaoSuperiorService,
			useClass: DesenhaAneisVisaoSuperiorService,
		},
		{
			provide: IControleAeracaoAutomaticaController,
			useClass: ControleAeracaoAutomaticaController,
		},
		{
			provide: IControleAcoesHistoricoService,
			useClass: ControleAcoesHistoricoService,
		},
		{
			provide: IVariavelAquecimentoController,
			useClass: VariavelAquecimentoController,
		},
		{
			provide: IFormataEquipamentosComponenteDataService,
			useClass: FormataEquipamentosComponenteDataService,
		},
		{
			provide: IFormataCampoValorService,
			useClass: FormataCampoValorService,
		},
		{
			provide: IUsuarioController,
			useClass: UsuarioController,
		},
		{
			provide: IComandoAeracaoAutomaticaController,
			useClass: ComandoAeracaoAutomaticaController,
		},
		{
			provide: IControleTrocaEstruturaArmazenagemService,
			useClass: ControleTrocaEstruturaArmazenagemService,
		},
		{
			provide: IDesestruturaStringFormulaParaObjetoService,
			useClass: DesestruturaStringFormulaParaObjetoService,
		},
		{
			provide: IRetornaDiasSemanaExibicaoService,
			useClass: RetornaDiasSemanaExibicaoService,
		},
		{
			provide: IRetornaDiasSemanaEnumService,
			useClass: RetornaDiasSemanaEnumService,
		},
		{
			provide: IRetornaTelefoneFormatadoService,
			useClass: RetornaTelefoneFormatadoService,
		},
		{
			provide: IDefineGraficoBarrasService,
			useClass: DefineGraficoBarrasService,
		},
		{ provide: ISecadorController, useClass: SecadorController },
		{ provide: IProdutoTipoController, useClass: ProdutoTipoController },
		{ provide: IListagemArmazenagemController, useClass: ListagemArmazenagemController },
		{ provide: IEquilibrioHigroscopicoController, useClass: EquilibrioHigroscopicoController },
		{
			provide: ICalculaAqplenumService,
			useClass: CalculaAqplenumService,
		},
		{
			provide: IArmazenagemEquilibrioHigroscopicoController,
			useClass: ArmazenagemEquilibrioHigroscopicoController,
		},
		{
			provide: IListaProdutosEquilibrioEstruturaController,
			useClass: ListaProdutosEquilibrioEstruturaController,
		},
		{
			provide: IBuscaGraoPorEstruturaController,
			useClass: BuscaGraoPorEstruturaController,
		},
		{
			provide: IUltimaLeituraEstruturaAmostragemController,
			useClass: UltimaLeituraEstruturaAmostragemController,
		},
		{
			provide: IPendulosEstruturaArmazenagemDivisaoController,
			useClass: PendulosEstruturaArmazenagemDivisaoController,
		},
		{
			provide: IEstruturaAmostragemController,
			useClass: EstruturaAmostragemController,
		},
		{
			provide: IApenasNumerosPermitidosService,
			useClass: ApenasNumerosPermitidosService,
		},
		{
			provide: IMaximoNumerosDigitadosService,
			useClass: MaximoNumerosDigitadosService,
		},
		{
			provide: IBuscaSensoresPlenumController,
			useClass: BuscaSensoresPlenumController,
		},
		{
			provide: IVerificaValidadeDeAmostragemService,
			useClass: VerificaValidadeDeAmostragemService,
		},
		{
			provide: IMontaListaEquipamentosSensoresService,
			useClass: MontaListaEquipamentosSensoresService,
		},
		{
			provide: IAmostragemEstruturaArmazenagemController,
			useClass: AmostragemEstruturaArmazenagemController,
		},
		{
			provide: IListaArmazensComSubcelulasController,
			useClass: ListaArmazensComSubcelulasController,
		},
		{
			provide: IEstruturaPerfilController,
			useClass: EstruturaPerfilController,
		},
		{
			provide: IEstruturaUsuarioController,
			useClass: EstruturaUsuarioController,
		},
		{
			provide: ITrataTimezoneRegraService,
			useClass: TrataTimezoneRegraService,
		},
		{
			provide: IProgramaAeracaoController,
			useClass: ProgramaAeracaoController,
		},
		{
			provide: IEstruturaArmazenagemController,
			useClass: EstruturaArmazenagemController,
		},
		{
			provide: IEstruturaArmazenagemSensores3DController,
			useClass: EstruturaArmazenagemSensores3DController,
		},
		{
			provide: IFormataSegundosHHMMSSService,
			useClass: FormataSegundosHHMMSSService,
		},
		{
			provide: IFormataHHMMMinutosService,
			useClass: FormataHHMMMinutosService,
		},
		{
			provide: IFormataMinutosHHMMSSService,
			useClass: FormataMinutosHHMMSSService,
		},
		{
			provide: IVerificaComunicacaoService,
			useClass: VerificaComunicacaoService,
		},
		{
			provide: IClientesController,
			useClass: ClientesController,
		},
		{
			provide: IUnidadesController,
			useClass: UnidadesController,
		},
		{
			provide: IRetornaSvgAeradorService,
			useClass: RetornaSvgAeradorService,
		},
		{
			provide: IAgrupaPendulosPorDivisaoService,
			useClass: AgrupaPendulosPorDivisaoService,
		},
		{
			provide: IDefineAeradoresPorDivisaoService,
			useClass: DefineAeradoresPorDivisaoService,
		},
		{
			provide: IMontaFormaGeometricaVisaoSuperiorArmazemService,
			useClass: MontaFormaGeometricaVisaoSuperiorArmazemService,
		},
		{
			provide: ICriaElementosVisuaisVisaoSuperiorArmazemService,
			useClass: CriaElementosVisuaisVisaoSuperiorArmazemService,
		},
		{
			provide: IValidaMapaArmazenagemService,
			useClass: ValidaMapaArmazenagemService,
		},
		{
			provide: IAplicaPlanoCorteSilo3DService,
			useClass: AplicaPlanoCorteSilo3DService,
		},
		{
			provide: ICriaSilo3DBasePlanaService,
			useClass: CriaSilo3DBasePlanaService,
		},
		{
			provide: ICriaSilo3DBaseSemiVService,
			useClass: CriaSilo3DBaseSemiVService,
		},
		{
			provide: ICriaSilo3DBaseVService,
			useClass: CriaSilo3DBaseVService,
		},
		{
			provide: ICriaEscadaSilo3DService,
			useClass: CriaEscadaSilo3DService,
		},
		{
			provide: IDefineMaterialConstrucaoSilo3DService,
			useClass: DefineMaterialConstrucaoSilo3DService,
		},
		{
			provide: ICriaTexturaGraoSilo3DService,
			useClass: CriaTexturaGraoSilo3DService,
		},
		{
			provide: ICriaBotoesSilo3DService,
			useClass: CriaBotoesSilo3DService,
		},
		{
			provide: ICriaGraoSilo3DService,
			useClass: CriaGraoSilo3DService,
		},
		{
			provide: IMostrarTemperaturaPenduloSilo3DService,
			useClass: MostrarTemperaturaPenduloSilo3DService,
		},
		{
			provide: IMontraVetorSensoresSilo3DService,
			useClass: MontraVetorSensoresSilo3DService,
		},
		{
			provide: ISincronizarPendulosSilo3DService,
			useClass: SincronizarPendulosSilo3DService,
		},
		{
			provide: IRetornaAlturaPendulosSilo3DService,
			useClass: RetornaAlturaPendulosSilo3DService,
		},
		{
			provide: IFormataLeiturasSensor3DService,
			useClass: FormataLeiturasSensor3DService,
		},
		{
			provide: IPossuiTodasInformacoesSilo3DService,
			useClass: PossuiTodasInformacoesSilo3DService,
		},

		{
			provide: IAtualizaStatusConexaoInternetService,
			useClass: AtualizaStatusConexaoInternetService,
		},
		{
			provide: IRetornaUnidadeMedidaPorTipoSensorService,
			useClass: RetornaUnidadeMedidaPorTipoSensorService,
		},
		{
			provide: IGraficoSecadorController,
			useClass: GraficoSecadorController,
		},
		{
			provide: IResumoUnidadeController,
			useClass: ResumoUnidadeController,
		},
		{ provide: IFormatarTempoService, useClass: FormatarTempoService },
		{ provide: IGraosUnidadeController, useClass: GraosUnidadeController },
	],
	exports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class SharedModule {}
