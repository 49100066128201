<div id="tooltipCustomPendulo" class="absolute z-99 rounded popoverCable" style="width: 200px; height: 80px">
    <div class="w-full bg-slate-200" *ngIf="penduloAtual">
        <span>{{ "P" + (penduloAtual?.pendulo?.codigo | number : "2.0") }}</span>
    </div>
    <div class="w-full bg-slate-100 text-sm p-1.5 text-left" *ngIf="penduloAtual">
        <p *ngIf="equipamentoAtual(penduloAtual)?.equipamento_comunicacao?.instante">
            <span>
                {{ "TIPO" | transloco }}:
                {{ penduloAtual?.pendulo?.pendulo_propriedade?.digital_termopar }}
            </span>
        </p>
        <p *ngIf="equipamentoAtual(penduloAtual)?.equipamento_comunicacao?.instante">
            <span> {{ "EQUIP" | transloco }}.: {{ equipamentoAtual(penduloAtual)?.nome }} </span>
        </p>
        <p
            *ngIf="
                equipamentoAtual(penduloAtual)?.pendulo?.pendulo_canal?.canal &&
                equipamentoAtual(penduloAtual)?.pendulo?.pendulo_propriedade.sensor_temperatura_quantidade
            "
        >
            <span
                >{{ "CANAL" | transloco }} {{ penduloAtual?.pendulo?.pendulo_canal?.canal }},
                {{ penduloAtual?.pendulo?.pendulo_propriedade.sensor_temperatura_quantidade }}
                {{ "SENSORES" | transloco }}
            </span>
        </p>
        <p *ngIf="penduloAtual?.pendulo?.pendulo_leitura?.instante">
            <span> {{ "STATUS" | transloco }}: </span>
            <span>
                {{
                    penduloAtual?.pendulo?.pendulo_leitura?.instante
                        ? (penduloAtual?.pendulo?.pendulo_leitura?.instante | date : "dd/MM/yyyy HH:mm")
                        : ("AGUARDANDO_LEITURA" | transloco)
                }}
            </span>
            <mat-icon id="icontooltipCustomPendulo" svgIcon="mat_solid:circle"></mat-icon>
        </p>
    </div>
</div>
