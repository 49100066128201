<loading-bar></loading-bar>

<app-aside-menu *ngIf="menuPrincipalVisivel"></app-aside-menu>

<div
    id="classicLayout"
    class="flex flex-col flex-auto w-full min-w-0 pl-0 cursor-default"
    [ngClass]="menuPrincipalVisivel ? 'sm:pl-[75px]' : 'sm:pl-[-75px]'"
>
    <div #sobreposicao id="sobreposicao" (click)="classicService.fecharMenuSobreposto()"></div>
    <div #sideBarMenu class="flex flex-col flex-auto">
        <div
            class="relative flex flex-0 items-center w-full h-[53.17px] sm:h-16 md:px-5 z-49 shadow dark:shadow-none dark:border-b sm:bg-card dark:bg-transparent print:hidden border-t-2 border-[#535767] bg-[#00091F]"
        >
            <button
                mat-icon-button
                (click)="toggleNavigation()"
                [disabled]="!menuPrincipalVisivel && checkInCheckOutObservable.checkoutIniciado$ | async"
            >
                <mat-icon [svgIcon]="'heroicons_outline:menu'" class="text-[#bac3d2] sm:text-[#64748b]"></mat-icon>
            </button>
            <div class="flex w-[100%] mr-[-200px] items-center justify-center">
                <app-header-select-customer (trocaUnidade)="trocaUnidade($event)"></app-header-select-customer>
            </div>
            <div class="flex items-center pl-0 ml-auto sm:ml-[90.95px] space-x-0.5 sm:space-x-2 mr-[9px] sm:mr-0">
                <ng-container *ngIf="usuarioProcer; else carregandoCheckInCheckOut">
                    <app-header-check-in-check-out></app-header-check-in-check-out>
                </ng-container>
                <ng-template #carregandoCheckInCheckOut>
                    <div class="w-[210px]"></div>
                </ng-template>
                <user> </user>
            </div>
        </div>
        <ng-container *ngIf="!recarregarComponente">
            <div
                class="sm:flex-row flex-col flex items-center w-full h-[32px] shadow dark:shadow-none bg-card dark:bg-transparent md:justify-between print:hidden subHeader md:pl-2 md:pr-3"
            >
                <app-page-info></app-page-info>

                <ng-container *ngIf="tipoColetor === tiposColetor.COLETOR_CERES">
                    <app-sincronizador-equipamento></app-sincronizador-equipamento>
                </ng-container>
            </div>
            <div class="flex flex-col flex-auto">
                <router-outlet *ngIf="true"></router-outlet>
            </div>
            <div
                class="relative flex flex-0 justify-between items-center w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
            >
                <span class="font-medium text-secondary">Ceres Orion 2.0 - v{{ versaoSistema }} </span>
                <span class="w-[151px] h-[36px] flex items-center"><img [src]="logoUrl" /></span>
            </div>
        </ng-container>
    </div>
</div>

<div class="alerta-fixo" *ngIf="alerta?.mostrar">
    <app-alert *ngIf="alerta?.mostrar" class="mt-0 -mr-0" [showIcon]="true" [type]="alerta.tipo" [appearance]="'fill'">
        <span fuseAlertTitle>{{ alerta.titulo }}</span>
        <span>{{ alerta.mensagem }}</span>
    </app-alert>
</div>
