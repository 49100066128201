import { MonoTypeOperatorFunction } from 'rxjs';
import { IdiomaSiglaEnum } from '../enums';
import { StatusAtendimentoEnum } from 'app/shared/enums/status-atendimento.enum';

export interface ResumoUnidadeInterface {
    pipe(arg0: MonoTypeOperatorFunction<unknown>): unknown;
    id: string;
    unidade_parametrizacao: UnidadeParametrizacaoInterface;
    unidade_produto_interno: ProdutoUnidadeInterface[];
    usuario: UsuarioLogadoResumoUnidadeInterface;
    atendimento: AtendimentoInterface;
}

export interface UnidadeParametrizacaoInterface {
    id: string;
    unidade_id: string;
    potencia_limite: any;
    instalado_local: boolean;
    instalado_nuvem: boolean;
    prioridade_acionamento: string;
    aguardar_tempo_entre_estruturas: boolean;
    tempo_espera_entre_estruturas: number;
    aguardar_tempo_histerese: boolean;
    tempo_espera_histerese: number;
    horario_pico_inicio: string;
    horario_pico_fim: string;
    intervalo_ligar_antes_horario_pico: number;
    intervalo_desligar_antes_horario_pico: number;
    unidade_estoque_padrao: string;
    tempo_expiracao_comando: number;
    tempo_expiracao_comando_secador: number;
    aquecimento_ar_1: number;
    aquecimento_ar_2: number;
    aquecimento_ar_3: number;
    instalacao_autorizada: boolean;
    origem_dados: string;
    possui_servico_desligamento_chuva: boolean;
}

export interface ProdutoUnidadeInterface {
    id: string;
    arquivado: boolean;
    unidade_id: string;
    produto: string;
}

export interface UsuarioLogadoResumoUnidadeInterface {
    id: string;
    nome: string;
    sobrenome: string;
    email: string;
    categoria: string;
    arquivado: boolean;
    idioma: IdiomaSiglaEnum;
    obrigar_resetar_senha: boolean;
    usuario_unidade: {
        id: string;
        permite_cadastro_usuario: boolean;
    };
}

export interface AtendimentoInterface {
    id: string;
    unidade_id: string;
    status: StatusAtendimentoEnum;
    visivel_cliente: boolean;
    instante_checkin: string;
    usuario_id_checkin: string;
    instante_checkout: string;
    usuario_id_checkout: string;
    tempo_analise: number;
    tempo_ultimo_checkin: number;
    tempo_ultimo_contato: number;
    notas: string;
}
