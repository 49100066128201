<div class="flex flex-col">
    <!-- CULTIVAR -->
    <ng-container *ngIf="produto?.produto_tipo">
        <div class="flex justify-between items-center rounded h-[33px] px-2 mt-1.5">
            <span class="text-base">
                {{ 'CULTIVAR' | transloco }}
            </span>
            <span class="flex justify-between items-center min-w-[140px]">
                <div class="text-base mr-4">
                    {{ produto?.nome ? produto?.nome : '-' }}
                </div>
                <div class="flex flex-col items-center text-center">
                    <div class="text-sm wl-cor-primaria">
                        {{ 'ARMAZENAGEM_GRAOS_SCREEN.DENSIDADE' | transloco }}
                    </div>
                    <div class="text-sm">
                        {{
                            produto?.produto_tipo?.densidade
                                ? (produto?.produto_tipo?.densidade | number : '1.1-1')
                                : '-'
                        }}
                    </div>
                </div>
            </span>
        </div>
    </ng-container>

    <!-- Amostragem -->
    <div class="w-full flex justify-between items-center rounded text-xs sm:text-base mt-1">
        <div *ngIf="amostragem; else semDados" class="w-full flex flex-col overflow-y-auto px-2 py-1 bg-[#f8fafb]">
            <div class="flex flex-row w-full items-center">
                <div class="w-[40%]">
                    <div class="flex flex-row min-w-36">
                        <span class="flex flex-row justify-center">
                            <div class="flex flex-col">
                                <p class="text-base">
                                    {{ 'AMOSTRAGEM' | transloco }}
                                </p>

                                <p class="w-auto flex items-center text-xs">
                                    {{ amostragem?.data_amostra | date : 'dd/MM/yyyy' }}
                                </p>
                            </div>
                            <ng-container *ngIf="amostragem?.data_amostra">
                                <ng-container
                                    *ngIf="verificaValidadeDeAmostragemService.execute(amostragem?.data_amostra)"
                                >
                                    <p
                                        class="flex items-center"
                                        matTooltipPosition="above"
                                        matTooltip="{{
                                            'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_AMOSTRAGEM_RECENTE'
                                                | transloco
                                        }}"
                                        matTooltipClass="tooltip-padrao tooltip-amostragem"
                                    >
                                        <mat-icon class="text-[15px] text-[#34901c]"> check_circle </mat-icon>
                                    </p>
                                </ng-container>

                                <ng-container
                                    *ngIf="!verificaValidadeDeAmostragemService.execute(amostragem?.data_amostra)"
                                >
                                    <p
                                        class="flex items-center"
                                        matTooltipPosition="above"
                                        matTooltip="{{
                                            'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_FAVOR_REALIZAR_AMOSTRAGEM'
                                                | transloco
                                        }}"
                                        matTooltipClass="tooltip-padrao tooltip-amostragem"
                                    >
                                        <mat-icon class="text-[15px] text-[#a44040] blink"> warning </mat-icon>
                                    </p>
                                </ng-container>
                            </ng-container>
                        </span>
                    </div>
                </div>

                <div class="flex w-full flex-row text-sm justify-between mb-2">
                    <div class="flex flex-col wl-cor-primaria text-center mx-1">
                        <p
                            matTooltipPosition="above"
                            matTooltip="{{
                                'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_UMIDADE_GRAO' | transloco
                            }}"
                            matTooltipClass="tooltip-padrao tooltip-amostragem"
                        >
                            {{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.UG' | transloco }} (%)
                        </p>
                        <span
                            class="text-[#0b0c0e]"
                            [ngClass]="amostragem?.umidade_controle ? '' : 'opacity-50 text-center rounded-md'"
                        >
                            {{ amostragem?.umidade_controle ? (amostragem?.umidade_controle | number : '1.1-1') : '-' }}
                        </span>
                    </div>
                    <div class="flex flex-col wl-cor-primaria text-center mx-1">
                        <p
                            matTooltipPosition="above"
                            matTooltip="{{
                                'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_AVARIADOS' | transloco
                            }}"
                            matTooltipClass="tooltip-padrao tooltip-amostragem"
                        >
                            {{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.AVR' | transloco }} (%)
                        </p>
                        <span
                            class="text-[#0b0c0e]"
                            [ngClass]="amostragem?.avariados_controle ? '' : 'opacity-50 text-center rounded-md'"
                        >
                            {{
                                amostragem?.avariados_controle
                                    ? (amostragem?.avariados_controle | number : '1.1-1')
                                    : '-'
                            }}
                        </span>
                    </div>
                    <div class="flex flex-col wl-cor-primaria text-center mx-1">
                        <p
                            matTooltipPosition="above"
                            matTooltip="{{
                                'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_IMPUREZAS' | transloco
                            }}"
                            matTooltipClass="tooltip-padrao tooltip-amostragem"
                        >
                            {{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.IMP' | transloco }} (%)
                        </p>
                        <span
                            class="text-[#0b0c0e]"
                            [ngClass]="amostragem?.impureza_controle ? '' : 'opacity-50 text-center rounded-md'"
                        >
                            {{
                                amostragem?.impureza_controle ? (amostragem.impureza_controle | number : '1.1-1') : '-'
                            }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #semDados>
    <div class="flex flex-row w-full items-center px-2 py-1">
        <div class="w-[40%]">
            <div class="flex">
                <p class="text-base">
                    {{ 'AMOSTRAGEM' | transloco }}
                </p>
            </div>
            <div class="flex flex-row min-w-36">
                <span class="flex flex-row justify-center"> </span>
            </div>
        </div>

        <div class="flex w-full flex-row text-sm justify-between mb-2">
            <div class="flex flex-col text-center mx-1 my-auto"></div>
            <div class="flex flex-col wl-cor-primaria text-center mx-1">
                <p
                    matTooltipPosition="above"
                    matTooltip="{{
                        'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_UMIDADE_GRAO' | transloco
                    }}"
                    matTooltipClass="tooltip-padrao tooltip-amostragem"
                >
                    {{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.UG' | transloco }} (%)
                </p>
                <span
                    class="text-[#0b0c0e]"
                    [ngClass]="amostragem?.umidade_controle ? '' : 'opacity-50 text-center rounded-md'"
                >
                    -
                </span>
            </div>
            <div class="flex flex-col wl-cor-primaria text-center mx-1">
                <p
                    matTooltipPosition="above"
                    matTooltip="{{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_AVARIADOS' | transloco }}"
                    matTooltipClass="tooltip-padrao tooltip-amostragem"
                >
                    {{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.AVR' | transloco }} (%)
                </p>
                <span
                    class="text-[#0b0c0e]"
                    [ngClass]="amostragem?.avariados_controle ? '' : 'opacity-50 text-center rounded-md'"
                >
                    -
                </span>
            </div>
            <div class="flex flex-col wl-cor-primaria text-center mx-1">
                <p
                    matTooltipPosition="above"
                    matTooltip="{{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_IMPUREZAS' | transloco }}"
                    matTooltipClass="tooltip-padrao tooltip-amostragem"
                >
                    {{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.IMP' | transloco }} (%)
                </p>
                <span
                    class="text-[#0b0c0e]"
                    [ngClass]="amostragem?.impureza_controle ? '' : 'opacity-50 text-center rounded-md'"
                >
                    -
                </span>
            </div>
        </div>
    </div>
</ng-template>
