import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import { ModalHistoricoAlteracoesProgramaComponent } from 'app/shared/components/modal-historico-alteracoes-programa/modal-historico-alteracoes-programa.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatTableModule,
        MatProgressSpinnerModule,
        TranslocoModule,
        InfiniteScrollModule,
    ],
    declarations: [ModalHistoricoAlteracoesProgramaComponent],
    exports: [ModalHistoricoAlteracoesProgramaComponent],
})
export class ModalHistoricoAlteracoesProgramaModule {}
