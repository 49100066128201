<div class="custom-tooltip">
	<div class="w-full bg-slate-200 text-center">
		<span> {{ 'AERADOR' | transloco }} {{ ' ' + (aerador?.aerador?.codigo | number : '2.0') }}</span>
	</div>
	<div class="w-full bg-slate-100 text-sm p-1.5 text-left">
		<app-status-aeracao-armazem [statusCode]="aerador?.aerador?.aeracao?.status"></app-status-aeracao-armazem>
		<div *ngIf="!visaoPlanificadaArmazem">
			<span
				>{{ 'POTENCIA' | transloco }}:
				<span class="ml-2"> {{ aerador?.aerador?.potencia_eletrica }} cv </span>
				<span class="ml-2">{{ 'CORRENTE' | transloco }}:</span> {{ aerador?.aerador?.corrente_nominal }} A
			</span>
		</div>
		<div *ngIf="visaoPlanificadaArmazem">
			<span
				>{{ 'POTENCIA' | transloco }}:
				<span class="ml-2"> {{ aerador?.aerador?.aerador_motor?.potencia_eletrica }} cv </span>
				<span class="ml-2">{{ 'CORRENTE' | transloco }}:</span> {{ aerador?.aerador?.aerador_motor?.corrente_nominal }} A
			</span>
		</div>
	</div>
</div>
