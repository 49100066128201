import { Injectable } from '@angular/core';

import { map, Observable, BehaviorSubject, tap } from 'rxjs';

import { ApiTransportService } from 'app/core/services/transport';
import { ResumoUnidadeInterface } from 'app/shared/interfaces';
import { IApiTransportInterface } from 'app/core/interfaces';
import { FormataDataHelper } from 'app/shared/helpers';

import {
    AutenticacaoUsuarioObservable,
    UnidadeParametrizacaoObservable,
    CheckInCheckOutObservable,
} from 'app/shared/observables';

export abstract class IResumoUnidadeController {
    abstract buscaResumoUnidade(forcarAtualizacao?: boolean): Observable<ResumoUnidadeInterface>;
    abstract atualizarResumoUnidade(): Observable<ResumoUnidadeInterface>;
}

@Injectable({
    providedIn: 'root',
})
export class ResumoUnidadeController implements IResumoUnidadeController {
    private transport: IApiTransportInterface;
    private resumoUnidadeSubject = new BehaviorSubject<ResumoUnidadeInterface>(null);
    private carregando = false;

    constructor(
        readonly apiTransportService: ApiTransportService,
        private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable,
        private autenticacaoUsuarioObservable: AutenticacaoUsuarioObservable,
        private checkInCheckOutObservable: CheckInCheckOutObservable
    ) {
        this.transport = this.apiTransportService.execute('/geral/unidade/resumo');
    }

    buscaResumoUnidade(forcarAtualizacao = false): Observable<ResumoUnidadeInterface> {
        this.checkInCheckOutObservable.setCarregandoDados = true;

        if (this.resumoUnidadeSubject.value && !this.carregando && !forcarAtualizacao) {
            return this.resumoUnidadeSubject.asObservable();
        }

        if (this.carregando) {
            return this.resumoUnidadeSubject.asObservable();
        }

        this.carregando = true;

        this.transport.http
            .get<ResumoUnidadeInterface>(`${this.transport.path}`)
            .pipe(
                map((response) => {
                    const usuario = JSON.parse(localStorage.getItem('usuarioLogado'));
                    if (usuario) {
                        usuario.nome = response.usuario.nome;
                        usuario.sobrenome = response.usuario.sobrenome;
                        usuario.email = response.usuario.email;
                        localStorage.setItem('usuarioLogado', JSON.stringify(usuario));
                    }
                    this.autenticacaoUsuarioObservable.setInformacoesAutenticacaoUsuario = response.usuario;
                    this.unidadeParametrizacaoObservable.setParametrosUnidade =
                        this.unidadeParametrizacaoObservable.setParametrosUnidade = {
                            ...response,
                            unidade_parametrizacao: {
                                ...response.unidade_parametrizacao,
                                horario_pico_fim: FormataDataHelper.adicionaTimezoneHHMMss(
                                    response.unidade_parametrizacao.horario_pico_fim
                                ),
                                horario_pico_inicio: FormataDataHelper.adicionaTimezoneHHMMss(
                                    response.unidade_parametrizacao.horario_pico_inicio
                                ),
                            },
                        };

                    if (response.atendimento) {
                        this.checkInCheckOutObservable.setAtendimento = response.atendimento;
                    } else {
                        this.checkInCheckOutObservable.setAtendimento = null;
                    }

                    this.checkInCheckOutObservable.setCarregandoDados = false;
                    return response;
                }),
                tap({
                    next: (response) => {
                        this.resumoUnidadeSubject.next(response);
                        this.carregando = false;
                    },
                    error: () => {
                        this.carregando = false;
                    },
                })
            )
            .subscribe();

        return this.resumoUnidadeSubject.asObservable();
    }

    public atualizarResumoUnidade(): Observable<ResumoUnidadeInterface> {
        return this.buscaResumoUnidade(true);
    }
}
