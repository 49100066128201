<ng-container *ngIf="atividades?.length && contatos?.length && atendimentoAtual; else carregando">
    <section class="w-full">
        <div class="bg-white rounded-md mt-3 mx-1 p-3">
            <div class="border-b border-solid border-grey flex justify-between">
                <h3 class="font-medium text-base">{{ 'CHECK_IN_CHECK_OUT.TITULO' | transloco }}</h3>
                <mat-slide-toggle [formControl]="permitirClienteVisualizarControl" class="mb-1.5" [color]="'primary'">
                    <span class="text-base">{{ 'CHECK_IN_CHECK_OUT.PERMITIR_CLIENTE_VISUALIZAR' | transloco }}</span>
                </mat-slide-toggle>
            </div>
            <form [formGroup]="formGroup" (ngSubmit)="abrirModalConfirmacao()">
                <div class="flex justify-between mt-8 campos-desabilitados">
                    <div class="w-[20%]">
                        <mat-form-field [floatLabel]="'always'" class="w-full" [hideRequiredMarker]="true">
                            <mat-label class="text-[#535767] font-normal text-md opacity-100">
                                {{ 'CHECK_IN_CHECK_OUT.DATA_HORA_CHECK_IN' | transloco }}
                            </mat-label>
                            <input matInput formControlName="dataCheckIn" readonly />
                        </mat-form-field>
                    </div>
                    <div class="w-[20%]">
                        <mat-form-field [floatLabel]="'always'" class="w-full" [hideRequiredMarker]="true">
                            <mat-label class="text-[#535767] font-normal text-md opacity-100">
                                {{ 'CHECK_IN_CHECK_OUT.TEMPO_ANALISE_CHECKOUT' | transloco }}
                            </mat-label>
                            <input matInput formControlName="tempoAnaliseAteCheckout" type="text" />
                        </mat-form-field>
                    </div>
                    <div class="w-[20%]">
                        <mat-form-field [floatLabel]="'always'" class="w-full" [hideRequiredMarker]="true">
                            <mat-label class="text-[#535767] font-normal text-md opacity-100">
                                {{ 'CHECK_IN_CHECK_OUT.TEMPO_ULTIMO_CHECK_IN' | transloco }}
                            </mat-label>
                            <input matInput formControlName="tempoDesdeUltimoCheckIn" type="text" readonly />
                        </mat-form-field>
                    </div>
                    <div class="w-[20%]">
                        <mat-form-field [floatLabel]="'always'" class="w-full" [hideRequiredMarker]="true">
                            <mat-label class="text-[#535767] font-normal text-md opacity-100">
                                {{ 'CHECK_IN_CHECK_OUT.TEMPO_ULTIMO_CONTATO' | transloco }}
                            </mat-label>
                            <input matInput formControlName="tempoDesdeUltimoContatoEnviado" type="text" readonly />
                        </mat-form-field>
                    </div>
                </div>
                <div class="mt-2 select-atividades">
                    <mat-form-field [floatLabel]="'always'" class="w-full" [hideRequiredMarker]="true">
                        <mat-label>{{ 'CHECK_IN_CHECK_OUT.ATIVIDADES_REALIZADAS' | transloco }}</mat-label>
                        <mat-chip-list #chipList>
                            <mat-chip
                                class="wl-bg-cor-primaria-opacidade wl-cor-primaria text-sm my-2"
                                *ngFor="let atividade of atividadesSelecionadas"
                                (removed)="removerAtividade(atividade)"
                            >
                                {{ atividade }}
                                <button class="wl-bg-cor-primaria flex items-center justify-center" matChipRemove>
                                    <mat-icon class="text-white">remove</mat-icon>
                                </button>
                            </mat-chip>
                            <textarea
                                #atividadeSelect
                                [placeholder]="'CHECK_IN_CHECK_OUT.SELECIONAR' | transloco"
                                formControlName="atividade"
                                [matAutocomplete]="auto"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                (matChipInputTokenEnd)="adicionarAtividade($event)"
                                cdkTextareaAutosize
                                autosizeMinRows="1"
                                autosizeMaxRows="4"
                            ></textarea>
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selecionarAtividades($event)">
                            <mat-option *ngFor="let atividade of exibirAtividadesDisponiveis()" [value]="atividade.id">
                                {{ atividade.nome }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <div class="w-full">
                    <mat-label class="text-[#535767] font-normal text-md opacity-100 mb-1">
                        {{ 'CHECK_IN_CHECK_OUT.TIPOS_CONTATO' | transloco }}
                    </mat-label>
                    <div class="flex items-center mt-1.5">
                        <ng-container *ngFor="let contato of contatos">
                            <mat-checkbox
                                [formControlName]="contato.id"
                                class="mr-8 font-normal text-[12px] text-[#535767]"
                            >
                                {{ contato.nome }}
                            </mat-checkbox>
                        </ng-container>
                        <mat-checkbox
                            class="font-normal text-[12px] text-[#535767]"
                            [formControlName]="'nenhumContato'"
                        >
                            {{ 'CHECK_IN_CHECK_OUT.NENHUM_CONTATO' | transloco }}
                        </mat-checkbox>
                    </div>
                </div>
                <ng-container *ngIf="contatoTipoChamadoAtivo()">
                    <mat-form-field [floatLabel]="'always'" class="w-full mt-4" [hideRequiredMarker]="true">
                        <textarea
                            matInput
                            formControlName="notasChamado"
                            [placeholder]="'CHECK_IN_CHECK_OUT.INSERIR_INFORMACOES' | transloco"
                        >
                        </textarea>
                    </mat-form-field>
                </ng-container>
                <div [ngClass]="contatoTipoChamadoAtivo() ? 'mt-2' : 'mt-4'">
                    <span class="text-[#535767] font-normal text-md opacity-100 mb-2">
                        {{ 'CHECK_IN_CHECK_OUT.NOTA_OBSERVACAO' | transloco }}
                    </span>
                    <quill-editor placeholder="" [modules]="editorTextoConfig" formControlName="anotacao">
                    </quill-editor>
                </div>
                <div class="flex justify-between px-8 pt-4 pb-3">
                    <button type="button" class="w-[160px] h-[46px] leading-5 text-[16px]" (click)="cancelarCheckOut()">
                        {{ 'CHECK_IN_CHECK_OUT.CANCELAR' | transloco }}
                    </button>
                    <button
                        type="submit"
                        class="action w-[160px] h-[46px] leading-5 text-[16px] text-white wl-bg-cor-secundaria border-btn-salvar"
                        [disabled]="encerrandoCheckOut"
                    >
                        {{ 'CHECK_IN_CHECK_OUT.SALVAR' | transloco }}
                    </button>
                </div>
            </form>
        </div>
    </section>
</ng-container>

<ng-template #carregando class="absolute text-">
    <div class="w-full bg-white rounded-md mt-3 mx-1 h-[545px] flex">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>
