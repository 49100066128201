import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VisaoPlanaArmazemComponent } from 'app/shared/components/visao-plana-armazem/visao-plana-armazem.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TooltipArmazemModule } from 'app/shared/components/tooltip-armazem/tooltip-armazem.module';

@NgModule({
    imports: [CommonModule, TranslocoModule, TooltipArmazemModule],
    declarations: [VisaoPlanaArmazemComponent],
    exports: [VisaoPlanaArmazemComponent],
})
export class VisaoPlanaArmazemModule {}
