import { Host, HostListener, Injectable } from '@angular/core';
import { AsideMenuObservable } from 'app/shared/observables';

@Injectable({
    providedIn: 'root',
})
export class ClassicService {
    constructor(private asideMenuObservable: AsideMenuObservable) {}
    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent): void {
        this.fecharMenuSobrepostoClick(event);
    }

    fecharMenuSobrepostoClick(event): void {
        const sobreposicao = document.getElementById('sideBarMenu');

        if (sobreposicao === null) return;
        if (!sobreposicao.contains(event.target)) {
            this.fecharMenuSobreposto();
            document.removeEventListener('click', this.fecharMenuSobrepostoClick);
        }
    }

    abrirMenuSobreposto(): void {
        document.addEventListener('click', this.fecharMenuSobrepostoClick.bind(this));
    }

    fecharMenuSobreposto(): void {
        document.getElementById('sobreposicao').style.display = 'none';
        this.asideMenuObservable.menuMinimizadoSubject.next(true);
    }

    removerMenu(): void {
        document.getElementById('sideBarMenu').style.display = 'none';
        document.getElementById('classicLayout').classList.remove('sm:pl-[75px]');
    }

    adicionarMenu(): void {
        const sideBarMenu = document.getElementById('sideBarMenu');
        const classicLayout = document.getElementById('classicLayout');

        if (sideBarMenu) {
            sideBarMenu.style.display = 'block';
        }

        if (classicLayout) {
            classicLayout.classList.add('sm:pl-[75px]');
        }
    }

    abrirMenuSobrepostoMobile(): void {
        document.getElementById('sobreposicao').style.display = 'block';
        this.asideMenuObservable.menuMinimizadoSubject.next(false);
    }
}
