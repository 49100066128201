import { Component, Input, OnInit } from '@angular/core';
import {
    SensorCo2UltimaLeituraInterface,
    SensoresCo2EquipamentoInterface,
} from 'app/modules/armazenagem/visualizacao/silos/interfaces';
import { TipoSensoresTecnologiaEnum } from 'app/shared/enums';
import { VerificaObjetoVazio } from 'app/shared/helpers';
import {
    EquipamentoInformacoesSiloInterface,
    MediaLeituraInformacoesSiloInterface,
    PenduloInformacoesSiloInterface,
} from 'app/shared/interfaces';
import { ICriaPaletaCoresTermometriaService, IVerificaValidadeDeAmostragemService } from 'app/shared/services';

@Component({
    selector: 'app-sensores-informacoes-gerais-armazem',
    templateUrl: './sensores-informacoes-gerais-armazem.component.html',
    styleUrls: ['./sensores-informacoes-gerais-armazem.component.scss'],
})
export class SensoresInformacoesGeraisArmazemComponent implements OnInit {
    @Input() pendulos: PenduloInformacoesSiloInterface[];
    @Input() sensores: MediaLeituraInformacoesSiloInterface;
    @Input() temSensorUmidade: boolean;
    @Input() sensorCO2: SensorCo2UltimaLeituraInterface;
    @Input() sensoresCO2Equipamentos: SensoresCo2EquipamentoInterface[];
    @Input() equipamentos: EquipamentoInformacoesSiloInterface[];

    tipoSensorEnum = TipoSensoresTecnologiaEnum;

    defineCoresTemperatura = this.criaPaletaCoresSensoresService.criarPaletaTemperatura;
    defineCoresUmidade = this.criaPaletaCoresSensoresService.criarPaletaUmidade;
    verificaObjetoVazio = VerificaObjetoVazio.execute;

    constructor(
        readonly criaPaletaCoresSensoresService: ICriaPaletaCoresTermometriaService,
        readonly verificaValidadeDeAmostragemService: IVerificaValidadeDeAmostragemService
    ) {}

    ngOnInit() {}

    verificaTipoSensorExistente(tipoSensor: string): boolean {
        return this.pendulos?.some((p) => p.pendulo.pendulo_propriedade?.digital_termopar === tipoSensor);
    }
}
