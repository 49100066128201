<ng-container *ngIf="configuracaoStatusAerador">
    <div
        class="min-h-[48px] rounded text-xs sm:text-base p-2 flex"
        [ngStyle]="{
            'background-color': configuracaoStatusAerador.alerta.background,
            color: configuracaoStatusAerador.alerta.cor
        }"
    >
        <div class="flex flex-row sm:w-[5%] w-[10%] items-center justify-center my-auto">
            <mat-icon
                [svgIcon]="configuracaoStatusAerador.icone.tipo"
                [class]="configuracaoStatusAerador.icone.classIcone"
                class="cursor-default"
            ></mat-icon>
        </div>
        <div class="flex flex-col sm:w-[95%] w-[90%] justify-start my-auto">
            <p class="font-semibold">
                {{ statusSemComunicacao() ?? configuracaoStatusAerador.icone.modal.subtitulo }}
            </p>
            <p>
                <ng-container *ngIf="!contemValorParaSubstituir(configuracaoStatusAerador.icone.modal.mensagem)">
                    {{ substituiVariavel(configuracaoStatusAerador.icone.modal.mensagem) }}
                </ng-container>
                <ng-container
                    *ngIf="contemValorParaSubstituir(configuracaoStatusAerador.icone.modal.mensagem) && dataEquipamento"
                >
                    {{ substituiVariavel(configuracaoStatusAerador.icone.modal.mensagem, dataEquipamento) }}
                </ng-container>
                <ng-container
                    *ngIf="
                        contemValorParaSubstituir(configuracaoStatusAerador.icone.modal.mensagem) && !dataEquipamento
                    "
                >
                {{ "NENHUM_REGISTRO_CADASTRADO" | transloco }}
                </ng-container>
            </p>
        </div>
    </div>
</ng-container>
