<div
    class="selctUnit w-full flex flex-row items-center"
    *ngIf="clientes?.length > 0 && !carregandoUndiades; else carregando"
    [ngClass]="{
        'cursor-not-allowed': statusRequisicoes || checkInCheckOutObservable.getPassadorUnidadesBloqueado
    }"
>
    <div class="sm:flex hidden" *ngIf="!buscandoUnidade">
        <button
            mat-icon-button
            (click)="voltarPrimeiraUnidade()"
            [disabled]="voltarPrimeiraUnidadeBtn || statusRequisicoes"
            matTooltip="{{ 'HEADER_SELECT_COMPONENT.PRIMEIRA_UNIDADE' | transloco }}"
            matTooltipClass="tooltip-padrao first-unity"
            [ngClass]="{ 'cursor-not-allowed': voltarPrimeiraUnidadeBtn || statusRequisicoes }"
        >
            <mat-icon svgIcon="heroicons_outline:chevron-double-left" class="icon-size-5 tooltip"> </mat-icon>
        </button>
        <button
            mat-icon-button
            (click)="voltarUnidade()"
            matTooltip="{{ 'HEADER_SELECT_COMPONENT.UNIDADE_ANTERIOR' | transloco }}"
            matTooltipClass="tooltip-padrao previous-unity"
            [disabled]="voltarUnidadeBtn || statusRequisicoes"
            [ngClass]="{ 'cursor-not-allowed': voltarUnidadeBtn || statusRequisicoes }"
        >
            <mat-icon svgIcon="heroicons_outline:chevron-left" class="icon-size-5"> </mat-icon>
        </button>
    </div>
    <div
        class="flex"
        [ngClass]="{
            'cursor-not-allowed': statusRequisicoes || checkInCheckOutObservable.getPassadorUnidadesBloqueado
        }"
    >
        <mat-form-field
            class="w-screen sm:w-full mt-4 ml-[-22px] sm:ml-0"
            [floatLabel]="'always'"
            (click)="focoSelectUnidade(true)"
        >
            <mat-select
                [formControl]="unidadeCtrl"
                placeholder="{{ 'HEADER_SELECT_COMPONENT.PROCURAR_CLIENTE_UNIDADE' | transloco }}"
                #singleSelect
                (selectionChange)="opcaoSelecionadaNoSelect($event)"
                (openedChange)="verificarSelectAberto($event)"
                (closed)="focoSelectUnidade(false)"
                class="sm:bg-card bg-[#00091F] text-[#BAC3D2] text-sm sm:text-base"
                panelClass="containerSelectUnidade"
            >
                <mat-option>
                    <ngx-mat-select-search
                        [formControl]="unidadeFiltroCtrl"
                        placeholderLabel="{{ 'HEADER_SELECT_COMPONENT.PROCURAR_CLIENTE_UNIDADE' | transloco }}"
                        noEntriesFoundLabel="{{ 'HEADER_SELECT_COMPONENT.NENHUM_RESULTADO_ENCONTRADO' | transloco }}"
                    >
                    </ngx-mat-select-search>
                </mat-option>
                <mat-optgroup
                    *ngFor="let grupo of clientesFiltrados | async; let i = index"
                    [attr.data-index]="i"
                    [label]="grupo.nome"
                >
                    <mat-option *ngFor="let unidade of grupo.unidades" [value]="unidade">
                        {{ mostrarClienteSelecionado(grupo, unidade) }}
                    </mat-option>
                </mat-optgroup>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="sm:flex hidden" *ngIf="!buscandoUnidade">
        <button
            mat-icon-button
            (click)="avancarUnidade()"
            matTooltip="{{ 'HEADER_SELECT_COMPONENT.PROXIMA_UNIDADE' | transloco }}"
            matTooltipClass="tooltip-padrao next-unity"
            [disabled]="avancarUnidadeBtn || statusRequisicoes"
            [ngClass]="{ 'cursor-not-allowed': avancarUnidadeBtn || statusRequisicoes }"
        >
            <mat-icon svgIcon="heroicons_outline:chevron-right" class="icon-size-5 tooltip"> </mat-icon>
        </button>
        <button
            mat-icon-button
            (click)="avancarUltimaUnidade()"
            matTooltip="{{ 'HEADER_SELECT_COMPONENT.ULTIMA_UNIDADE' | transloco }}"
            matTooltipClass="tooltip-padrao last-unity"
            [disabled]="avancarUltimaUnidadeBtn || statusRequisicoes"
            [ngClass]="{ 'cursor-not-allowed': avancarUltimaUnidadeBtn || statusRequisicoes }"
        >
            <mat-icon svgIcon="heroicons_outline:chevron-double-right" class="icon-size-5"> </mat-icon>
        </button>
    </div>
</div>

<ng-template #carregando>
    <div class="w-full flex flex-row items-center justify-center min-w">
        {{ 'HEADER_SELECT_COMPONENT.CARREGANDO' | transloco }}
    </div>
</ng-template>
