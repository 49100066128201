import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

import { QuillModule } from 'ngx-quill';

import { CheckInCheckOutComponent } from 'app/layout/common/check-in-check-out/check-in-check-out.component';
import { CheckInCheckOutRoutes } from 'app/layout/common/check-in-check-out/check-in-check-out.routing';
import { TranslocoRootModule } from 'app/transloco-root.module';

import {
    HeaderCheckInCheckOutComponent,
    ModalReiniciarCheckinComponent,
    ModalRetomarCheckInComponent,
} from 'app/layout/common/check-in-check-out/components';

import {
    CheckInCheckOutController,
    ICheckInCheckOutController,
} from 'app/layout/common/check-in-check-out/controllers';

@NgModule({
    declarations: [
        CheckInCheckOutComponent,
        HeaderCheckInCheckOutComponent,
        ModalReiniciarCheckinComponent,
        ModalRetomarCheckInComponent,
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        RouterModule.forChild(CheckInCheckOutRoutes),
        MatDialogModule,
        MatMenuModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTooltipModule,
        MatButtonModule,

        QuillModule,
        TranslocoRootModule,
    ],
    exports: [CheckInCheckOutComponent, HeaderCheckInCheckOutComponent],
    providers: [{ provide: ICheckInCheckOutController, useClass: CheckInCheckOutController }, DatePipe],
})
export class CheckInCheckOutModule {}
