<section class="componente-modal historico w-full">
    <div class="first: mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>

    <div class="text-center pb-lg-0 mt-2 modal-titulo">
        <p *ngIf="dialogData.tipo === sincronizadorEquipamentoHistoricoTipoEnum.NuvemLocal" class="text-sm sm:text-2xl">
            {{ "ULTIMA_SINCRONIZACAO_COMPONENT.TOOLTIP_HISTORICO_EXECUCAO_SERVICO_LOCAL_CLOUD" | transloco }}
        </p>
        <p
            *ngIf="dialogData.tipo === sincronizadorEquipamentoHistoricoTipoEnum.ColetorSincronizador"
            class="text-sm sm:text-2xl"
        >
            {{ "ULTIMA_SINCRONIZACAO_COMPONENT.TOOLTIP_HISTORICO_EXECUCAO_SERVICO_COLETOR_SINCRONIZADOR" | transloco }}
        </p>
    </div>

    <div
        class="componente-tabela-modal mb-5 mt-5 sm:mt-8 overflow-hidden overflow-y-auto"
        [ngClass]="{ 'max-h-[400px]': historico.length > 0 }"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        (scrolled)="onScroll()"
        [scrollWindow]="false"
    >
        <ng-container *ngIf="!carregando; else carrgandoTabela">
            <table mat-table [dataSource]="historico" class="max-h-[400px]">
                <ng-container matColumnDef="data">
                    <th mat-header-cell width="50%" *matHeaderCellDef class="text-xs text-center sm:text-base">
                        {{ "ULTIMA_SINCRONIZACAO_COMPONENT.DATA_HORA" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        {{ element.instante ? (element.instante | date: 'dd/MM/yyyy HH:mm:ss') :
                        ('ULTIMA_SINCRONIZACAO_COMPONENT.AGUARDANDO_LEITURA' | transloco) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell width="50%" *matHeaderCellDef class="text-xs sm:text-base text-center">
                        {{ "ULTIMA_SINCRONIZACAO_COMPONENT.STATUS" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="tabela-status">
                            <p
                                class="circle mr-1 mb-[0.05px] flex"
                                [ngClass]="{
                                    'com-execucao': element.status,
                                    'sem-execucao': !element.status
                                }"
                            ></p>
                            <p *ngIf="element.status" class="text-xs sm:text-base">
                                {{ "DATA_ULTIMA_COMUNICACAO_COMPONENT.COMUNICANDO" | transloco }}
                            </p>
                            <p *ngIf="!element.status" class="text-xs sm:text-base">
                                {{ "ULTIMA_SINCRONIZACAO_COMPONENT.SEM_COMUNICACAO" | transloco }}
                            </p>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tabelaColunas"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: tabelaColunas"></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="carregandoDadosAdicionais">
            <div class="h-[100px] flex">
                <span class="m-auto flex">
                    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
                </span>
            </div>
        </ng-container>
    </div>
</section>

<ng-template #carrgandoTabela>
    <div class="flex h-[500px]">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>

<ng-template [ngIf]="!carregando && historico.length == 0">
    <div class="flex h-[500px]">
        <span class="m-auto flex">
            {{ "ULTIMA_SINCRONIZACAO_COMPONENT.NAO_HA_REGISTROS_REFERENTE_AO_EQUIPAMENTO" | transloco }}
        </span>
    </div>
</ng-template>
