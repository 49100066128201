export interface UsuarioResumoUnidadeInterface {
	id: string;
	usuario_id: string;
	unidade_id: string;
	permite_cadastro_usuario: boolean;
	unidade: ResumoUnidadeInterface;
	usuario_perfil: UsuarioPerfilInterface[];
}

export interface ListaUsuariosResumoUnidadeInterface {
	cliente: {
		id: string;
		nome: string;
		unidade: {
			id: string;
			nome: string;
			permite_cadastro_usuario: boolean;
			usuario_perfil: UsuarioPerfilInterface[];
		}[];
	};
}

interface ResumoUnidadeInterface {
	id: string;
	nome: string;
	cliente: {
		id: string;
		nome: string;
	};
}

interface UsuarioPerfilInterface {
	id: string;
	perfil_id: string;
	usuario_id: string;
	perfil: {
		id: string;
		nome: string;
		padrao: boolean;
	};
}
