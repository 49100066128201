import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CheckInCheckOutObservable } from 'app/shared/observables';
import * as moment from 'moment';

@Component({
    selector: 'app-modal-retomar-check-in',
    templateUrl: './modal-retomar-check-in.component.html',
    styleUrls: ['./modal-retomar-check-in.component.scss'],
})
export class ModalRetomarCheckInComponent implements OnInit {
    dataFormatada: string;
    horaFormatada: string;
    tempoPausado: string;

    constructor(
        readonly dialogRef: MatDialogRef<ModalRetomarCheckInComponent>,

        private checkInCheckOutObservable: CheckInCheckOutObservable
    ) {}

    ngOnInit(): void {
        const atendimento = this.checkInCheckOutObservable.getAtendimento;

        if (atendimento?.instante_checkin) {
            const dataCheckIn = moment(atendimento.instante_checkin);

            this.dataFormatada = dataCheckIn.format('DD/MM/YYYY');
            this.horaFormatada = dataCheckIn.format('HH:mm');
        }
    }
}
