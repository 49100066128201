import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusOnOffArmazemComponent } from 'app/shared/components/status-on-off-armazem/status-on-off-armazem.component';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
    imports: [CommonModule, TranslocoRootModule],
    declarations: [StatusOnOffArmazemComponent],
    exports: [StatusOnOffArmazemComponent],
})
export class StatusOnOffArmazemModule {}
