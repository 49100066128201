import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component } from '@angular/core';

import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'modal-reiniciar-checkin',
    templateUrl: './modal-reiniciar-check-in.component.html',
    styleUrls: ['./modal-reiniciar-check-in.component.scss'],
})
export class ModalReiniciarCheckinComponent {
    formReiniciarCheckIn: FormGroup;

    constructor(readonly dialogRef: MatDialogRef<ModalReiniciarCheckinComponent>, private formBuilder: FormBuilder) {
        this.formReiniciarCheckIn = this.formBuilder.group({
            motivo: ['', Validators.required],
        });
    }

    confirmar(): void {
        if (this.formReiniciarCheckIn.valid) {
            this.dialogRef.close(this.formReiniciarCheckIn.value);
        }
    }
}
