import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { AeradorStatuSodeEnum } from 'app/shared/enums';
import { StatusAeracaoModel } from 'app/shared/models';
import { IConfigurarStatusAeracaoService } from 'app/shared/services';

@Component({
    selector: 'app-alerta-aeracao-sensor-3d',
    templateUrl: './alerta-aeracao-sensor-3d.component.html',
    styleUrls: ['./alerta-aeracao-sensor-3d.component.scss'],
})
export class AlertaAeracaoSensor3DComponent implements OnChanges {
    @Input() statusCode?: AeradorStatuSodeEnum;   
    

    dataEquipamento: string;
    configuracaoStatusAerador: StatusAeracaoModel;

    constructor(
        private configurarStatusAeracaoService: IConfigurarStatusAeracaoService,
        private transloco: TranslocoService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.configuracaoStatusAerador = this.configurarStatusAeracaoService.execute(this.statusCode);
    }

    mostrarAlertaSensor3D(): boolean {
        return !this.configuracaoStatusAerador?.falha_comunicacao || true
    }
}
