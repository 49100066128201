<div class="componente-modal">
    <div class="modal-titulo flex flex-col px-1 py-6">
        <div class="text-center justify-center items-center flex flex-col">
            <p class="text-base">{{ 'CHECK_IN_CHECK_OUT.RETOMAR_CHECK_IN' | transloco }}</p>
        </div>

        <div class="flex text-left mb-5 mt-3">
            <p class="text-sm text-[#535767]">
                <span>{{ 'CHECK_IN_CHECK_OUT.ATENCAO' | transloco }}</span> <br />
                {{ 'CHECK_IN_CHECK_OUT.CHECK_IN_PAUSADO_MENSAGEM' | transloco : { horaFormatada, dataFormatada } }}
            </p>
        </div>

        <mat-dialog-actions class="flex justify-center">
            <button
                (click)="dialogRef.close()"
                type="button"
                class="px-5 h-[40px] leading-5 text-md text-[#535767] border-[#535767] mr-4"
            >
                {{ 'CANCELAR' | transloco }}
            </button>
            <button
                (click)="dialogRef.close(true)"
                type="button"
                class="wl-bg-cor-secundaria border-none px-5 h-[40px] leading-5 text-md text-white ml-4"
            >
                {{ 'CONFIRMAR' | transloco }}
            </button>
        </mat-dialog-actions>
    </div>
</div>
