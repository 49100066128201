import { DecimalPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { VariaveisControleEnum } from 'app/modules/armazenagem/cadastro/regras/cadastro/enums';
import {
    IDesestruturaStringFormulaParaObjetoService,
    IFormataMinutosHHMMSSService,
    IRetornaDiasSemanaExibicaoService,
    ITrataTimezoneRegraService,
} from 'app/shared/services';
import { take } from 'rxjs';

export abstract class IDesestruturaStringFormulaListagemService {
    abstract execute(formula: string, parametrosUnidade): any;
}

@Injectable()
export class DesestruturaStringFormulaListagemService implements IDesestruturaStringFormulaListagemService {
    descricao = {
        maiorOuIgual: '',
        menorOuIgual: '',
        diferente: '',
        igual: '',
        maiorQue: '',
        menorQue: '',
        textoE: '',
        textoOU: '',
    };

    constructor(
        private desestruturaStringFormulaParaObjetoService: IDesestruturaStringFormulaParaObjetoService,
        private retornaDiasSemanaExibicaoService: IRetornaDiasSemanaExibicaoService,
        private decimalPipe: DecimalPipe,
        private transloco: TranslocoService,
        private trataTimezoneRegraService: ITrataTimezoneRegraService,
        private formataMinutosHHMMSSService: IFormataMinutosHHMMSSService
    ) {
        this.traduzTextos();
    }

    public execute(formulas: string, parametrosUnidade): any {
        const formulaSemEspacos = this.trataTimezoneRegraService.adicionaTimezoneHRSLIGAR(formulas?.replace(/\s/g, ''));
        if (!formulaSemEspacos) return;
        if (formulaSemEspacos.includes('||') && formulaSemEspacos.includes('&&')) {
            return this.tratamentoBlocoOuComBlocoE(
                formulaSemEspacos.replace(/\(/g, '').replace(/\)/g, ''),
                parametrosUnidade
            );
        }
        if (!formulaSemEspacos.includes('||') && formulaSemEspacos.includes('&&')) {
            return this.tratamentoBlocoE(formulaSemEspacos, parametrosUnidade);
        }
        if (formulaSemEspacos.includes('||') && !formulaSemEspacos.includes('&&')) {
            return this.tratamentoBlocoOu(formulaSemEspacos.replace(/\(/g, '').replace(/\)/g, ''), parametrosUnidade);
        }
        if (!formulaSemEspacos.includes('||') && !formulaSemEspacos.includes('&&')) {
            return `(${this.formatarFormulaApenasParaVisualizacao(formulaSemEspacos, parametrosUnidade).replace(
                /\s{2,}/g,
                ' '
            )})`;
        }
    }

    private formatarFormulaApenasParaVisualizacao(formula, parametrosUnidade): any {
        const formulaDesestruturada = this.desestruturaStringFormulaParaObjetoService.execute(formula);
        return `${formulaDesestruturada.variavelEsquerda} ${formulaDesestruturada.valorEsquerda ? ' + ' : ''}${
            formulaDesestruturada.valorEsquerda
                ? this.decimalPipe.transform(formulaDesestruturada.valorEsquerda, '1.2-2')
                : ''
        } ${this.substituiSimboloPorDescricaoOperador(formulaDesestruturada.operador)} ${
            formulaDesestruturada.variavelDireita ?? ''
        } ${formulaDesestruturada.variavelDireita && formulaDesestruturada.valorDireita ? ' + ' : ''} ${
            formulaDesestruturada.valorDireita &&
            formulaDesestruturada.variavelEsquerda === VariaveisControleEnum.TEMPODECHORAS
                ? this.formataMinutosHHMMSSService.horasMinutos(parseInt(formulaDesestruturada.valorDireita))
                : formulaDesestruturada.valorDireita
                ? this.decimalPipe.transform(formulaDesestruturada.valorDireita, '1.2-2')
                : ''
        } ${formulaDesestruturada.valorDireita === 0 ? '0' : ''} ${
            formulaDesestruturada.diaSemana
                ? '[' + this.retornaDiasSemanaExibicaoService.execute(formulaDesestruturada.diaSemana) + ']'
                : ''
        } ${
            formulaDesestruturada.horarioInicial && formulaDesestruturada.horarioFinal
                ? formulaDesestruturada.horarioInicial + ' a ' + formulaDesestruturada.horarioFinal
                : ''
        }${
            parametrosUnidade &&
            (formulaDesestruturada.variavelEsquerda === VariaveisControleEnum.HRSPROIB ||
                formulaDesestruturada.variavelEsquerda === VariaveisControleEnum.HRSPROIBALL)
                ? parametrosUnidade?.horario_pico_inicio.substring(0, 5) +
                  ' a ' +
                  parametrosUnidade?.horario_pico_fim.substring(0, 5)
                : ''
        }`;
    }

    private substituiSimboloPorDescricaoOperador(operador: string): string {
        if (operador?.includes('>=')) return this.descricao.maiorOuIgual;
        if (operador?.includes('<=')) return this.descricao.menorOuIgual;
        if (operador?.includes('!=')) return this.descricao.diferente;
        if (operador?.includes('==')) return this.descricao.igual;
        if (operador?.includes('>')) return this.descricao.maiorQue;
        if (operador?.includes('<')) return this.descricao.menorQue;
        return operador ?? '';
    }

    private tratamentoBlocoOuComBlocoE(formula, parametrosUnidade): string {
        const blocoPai = formula.split('||');
        const BlocoOU = [];
        let blocosEFillho = [];
        blocoPai.forEach((formula) => {
            if (!formula.includes('&&')) {
                const formulaDesestruturada = this.formatarFormulaApenasParaVisualizacao(formula, parametrosUnidade);
                BlocoOU.push(`(${formulaDesestruturada})`);
            } else {
                blocosEFillho = blocoPai.map((formula) => formula.split('&&'));
                blocosEFillho = blocosEFillho
                    .filter((bloco) => bloco.length > 1)
                    .map((blocoE) =>
                        blocoE.map(
                            (formula) => `(${this.formatarFormulaApenasParaVisualizacao(formula, parametrosUnidade)})`
                        )
                    )
                    .map((blocoE) => `(${blocoE.join(` ${this.descricao.textoE} `)})`);
            }
        });
        return `${BlocoOU.length ? '(' : ''}${BlocoOU.join(` ${this.descricao.textoOU} `).replace(/\s{2,}/g, ' ')}${
            BlocoOU.length ? ')' + this.descricao.textoOU : ''
        } (${blocosEFillho.join(` ${this.descricao.textoOU} `).replace(/\s{2,}/g, ' ')})`;
    }

    private tratamentoBlocoOu(formula, parametrosUnidade): string {
        const blocoPai = formula.split('||');
        const BlocoOU = [];
        blocoPai.forEach((formula) => {
            if (!formula.includes('&&')) {
                const formulaDesestruturada = this.formatarFormulaApenasParaVisualizacao(formula, parametrosUnidade);
                BlocoOU.push(`(${formulaDesestruturada})`);
            }
        });
        return `(${BlocoOU.join(` ${this.descricao.textoOU} `).replace(/\s{2,}/g, ' ')})`;
    }

    private tratamentoBlocoE(formula, parametrosUnidade): string {
        const blocoPaiGeral = formula.split('&&((');
        const blocoPaiFormulas = blocoPaiGeral[0].split('&&');
        const blocoEPai = [];
        const blocoEFilhos = [];
        const blocoEFilhosApresentacao = [];
        blocoPaiFormulas.forEach((formula, index) => {
            const formulaDesestruturada = this.formatarFormulaApenasParaVisualizacao(
                formula.replace(/\(/g, '').replace(/\)/g, ''),
                parametrosUnidade
            );
            blocoEPai.push(`(${formulaDesestruturada})`);
        });
        blocoPaiGeral.forEach((formula, index) => {
            if (index !== 0) {
                blocoEFilhos.push(formula.split('&&'));
            }
        });
        blocoEFilhos.forEach((bloco) => {
            const formulasComParenteses = bloco.map((formula) => {
                const formulaDesestruturada = this.formatarFormulaApenasParaVisualizacao(formula, parametrosUnidade);
                return (formula = `(${formulaDesestruturada.replace(/\(/g, '').replace(/\)/g, '')})`);
            });
            blocoEFilhosApresentacao.push(formulasComParenteses.join(` ${this.descricao.textoE} `));
        });
        if (blocoEFilhosApresentacao.length > 0) {
            return `(${blocoEPai.join(` ${this.descricao.textoE} `).replace(/\s{2,}/g, ' ')}) ${
                this.descricao.textoE
            } (${blocoEFilhosApresentacao.join(` ${this.descricao.textoE} `).replace(/\s{2,}/g, ' ')})`;
        } else {
            return `(${blocoEPai.join(` ${this.descricao.textoE} `).replace(/\s{2,}/g, ' ')})`;
        }
    }

    private traduzTextos(): void {
        this.transloco
            .selectTranslate('MAIOR_OU_IGUAL')
            .pipe(take(1))
            .subscribe((valor) => (this.descricao.maiorOuIgual = valor));
        this.transloco
            .selectTranslate('MENOR_OU_IGUAL')
            .pipe(take(1))
            .subscribe((valor) => (this.descricao.menorOuIgual = valor));
        this.transloco
            .selectTranslate('DIFERENTE')
            .pipe(take(1))
            .subscribe((valor) => (this.descricao.diferente = valor));
        this.transloco
            .selectTranslate('IGUAL')
            .pipe(take(1))
            .subscribe((valor) => (this.descricao.igual = valor));
        this.transloco
            .selectTranslate('MAIOR_QUE')
            .pipe(take(1))
            .subscribe((valor) => (this.descricao.maiorQue = valor));
        this.transloco
            .selectTranslate('MENOR_QUE')
            .pipe(take(1))
            .subscribe((valor) => (this.descricao.menorQue = valor));
        this.transloco
            .selectTranslate('E')
            .pipe(take(1))
            .subscribe((valor) => (this.descricao.textoE = valor));
        this.transloco
            .selectTranslate('OU')
            .pipe(take(1))
            .subscribe((valor) => (this.descricao.textoOU = valor));
    }
}
