import { ProdutoTipoInterface } from 'app/shared/interfaces';

export interface GraoInterface {
    arquivado?: boolean;
    id?: string;
    nome?: string;
    produto_tipo?: ProdutoTipoInterface;
    produto_tipo_id?: number;
    notas?: string;
}
export interface GraoListaInterface {
    arquivado?: boolean;
    id?: string;
    nome: string;
    produto_tipo?: ProdutoTipoInterface;
    produto_tipo_id?: number;
    notas: string;
    densidade: number;
    peso_saca: number;
    estrutura_armazenagem_divisao?: {
        id: string;
        nome: string;
    }[];
}
