import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsuarioLogadoResumoUnidadeInterface } from 'app/shared/interfaces';

@Injectable({
	providedIn: 'root',
})
export class AutenticacaoUsuarioObservable {
	private usuario: BehaviorSubject<UsuarioLogadoResumoUnidadeInterface | null> = new BehaviorSubject(null);

	set setInformacoesAutenticacaoUsuario(value: UsuarioLogadoResumoUnidadeInterface) {
		this.usuario.next(value);
	}

	get getInformacoesAutenticacaoUsuario(): UsuarioLogadoResumoUnidadeInterface {
		return this.usuario.getValue();
	}

	get buscaInformacoesAutenticacaoUsuario$(): Observable<UsuarioLogadoResumoUnidadeInterface> {
		return this.usuario.asObservable();
	}
}
