import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { TooltipArmazemComponent } from 'app/shared/components/tooltip-armazem/tooltip-armazem.component';

@NgModule({
    declarations: [TooltipArmazemComponent],
    imports: [CommonModule, MatTooltipModule, MatIconModule, TranslocoRootModule],
    exports: [TooltipArmazemComponent],
})
export class TooltipArmazemModule {}
