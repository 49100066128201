import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { DataUltimaComunicacaoModule } from 'app/shared/components/data-ultima-comunicacao/data-ultima-comunicacao.module';
import { EstoqueInformacoesGeraisArmazemComponent } from 'app/shared/components/estoque-informacoes-gerais-armazem/estoque-informacoes-gerais-armazem.component';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule, DataUltimaComunicacaoModule],
    declarations: [EstoqueInformacoesGeraisArmazemComponent],
    exports: [EstoqueInformacoesGeraisArmazemComponent],
})
export class EstoqueInformacoesGeraisArmazemModule {}
