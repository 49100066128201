<div class="flex flex-col">
    <!-- Amostragem -->
    <div class="w-full flex justify-between items-center rounded text-xs sm:text-base mt-1">
        <div *ngIf="amostragem?.length" class="w-full flex flex-col overflow-y-auto">
            <div *ngFor="let amostra of amostragem">
                <div class="flex flex-row w-full items-center bg-white min-h-[44px] px-2">
                    <div class="w-1/2">
                        <div>
                            <p class="bg-[#E6F6FD] px-2 rounded-md max-w-max wl-cor-primaria">
                                {{ amostra?.nome_divisao }}
                            </p>
                        </div>
                    </div>
                    <div class="flex w-full flex-row text-sm justify-around items-center">
                        <div class="flex flex-col w-max-content text-center">
                            <div class="text-[#444444] text-base">{{ 'CULTIVAR' | transloco }}</div>
                            <span class="text-[#444444]">
                                {{ amostra?.produto?.nome ? amostra?.produto?.nome : '-' }}
                            </span>
                        </div>
                        <div class="flex flex-col w-max-content text-center">
                            <div class="wl-cor-primaria">{{ 'ARMAZENAGEM_GRAOS_SCREEN.DENSIDADE' | transloco }}</div>
                            <span class="text-[#444444]">
                                {{
                                    amostra?.produto?.produto_tipo?.densidade
                                        ? (amostra?.produto?.produto_tipo?.densidade | number : '1.1-1')
                                        : '-'
                                }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="flex flex-row w-full items-center bg-[#f8fafb] px-2 min-h-[44px]">
                    <div class="w-[40%]">
                        <div class="flex flex-row min-w-36">
                            <span class="flex flex-row justify-center">
                                <div class="flex flex-col">
                                    <p class="text-base">
                                        {{ 'AMOSTRAGEM' | transloco }}
                                    </p>

                                    <p class="w-auto flex items-center text-xs">
                                        {{ amostra?.data_amostra | date : 'dd/MM/yyyy' }}
                                    </p>
                                </div>
                                <ng-container *ngIf="amostra?.data_amostra">
                                    <ng-container
                                        *ngIf="verificaValidadeDeAmostragemService.execute(amostra?.data_amostra)"
                                    >
                                        <p
                                            class="flex items-center"
                                            matTooltipPosition="above"
                                            matTooltip="{{
                                                'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_AMOSTRAGEM_RECENTE'
                                                    | transloco
                                            }}"
                                            matTooltipClass="tooltip-padrao tooltip-amostragem"
                                        >
                                            <mat-icon class="text-[15px] text-[#34901c]"> check_circle </mat-icon>
                                        </p>
                                    </ng-container>

                                    <ng-container
                                        *ngIf="!verificaValidadeDeAmostragemService.execute(amostra?.data_amostra)"
                                    >
                                        <p
                                            class="flex items-center"
                                            matTooltipPosition="above"
                                            matTooltip="{{
                                                'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_FAVOR_REALIZAR_AMOSTRAGEM'
                                                    | transloco
                                            }}"
                                            matTooltipClass="tooltip-padrao tooltip-amostragem"
                                        >
                                            <mat-icon class="text-[15px] text-[#a44040] blink"> warning </mat-icon>
                                        </p>
                                    </ng-container>
                                </ng-container>
                            </span>
                        </div>
                    </div>

                    <div class="flex w-full flex-row text-sm justify-between">
                        <div class="flex flex-col wl-cor-primaria text-center mx-1">
                            <p
                                matTooltipPosition="above"
                                matTooltip="{{
                                    'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_UMIDADE_GRAO' | transloco
                                }}"
                                matTooltipClass="tooltip-padrao tooltip-amostragem"
                            >
                                {{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.UG' | transloco }} (%)
                            </p>
                            <span
                                class="text-[#0b0c0e]"
                                [ngClass]="amostra?.umidade_controle ? '' : 'opacity-50 text-center rounded-md'"
                            >
                                {{ amostra?.umidade_controle ? (amostra?.umidade_controle | number : '1.1-1') : '-' }}
                            </span>
                        </div>
                        <div class="flex flex-col wl-cor-primaria text-center mx-1">
                            <p
                                matTooltipPosition="above"
                                matTooltip="{{
                                    'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_AVARIADOS' | transloco
                                }}"
                                matTooltipClass="tooltip-padrao tooltip-amostragem"
                            >
                                {{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.AVR' | transloco }} (%)
                            </p>
                            <span
                                class="text-[#0b0c0e]"
                                [ngClass]="amostra?.avariados_controle ? '' : 'opacity-50 text-center rounded-md'"
                            >
                                {{
                                    amostra?.avariados_controle ? (amostra?.avariados_controle | number : '1.1-1') : '-'
                                }}
                            </span>
                        </div>
                        <div class="flex flex-col wl-cor-primaria text-center mx-1">
                            <p
                                matTooltipPosition="above"
                                matTooltip="{{
                                    'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.TOOLTIP_IMPUREZAS' | transloco
                                }}"
                                matTooltipClass="tooltip-padrao tooltip-amostragem"
                            >
                                {{ 'ARMAZENAGEM_LISTA_ARMAZENS_VISAO_DETALHADA_SCREEN.IMP' | transloco }} (%)
                            </p>
                            <span
                                class="text-[#0b0c0e]"
                                [ngClass]="amostra?.impureza_controle ? '' : 'opacity-50 text-center rounded-md'"
                            >
                                {{ amostra?.impureza_controle ? (amostra.impureza_controle | number : '1.1-1') : '-' }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
