import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataUltimaComunicacaoArmazemComponent } from 'app/shared/components/data-ultima-comunicacao-armazem/data-ultima-comunicacao-armazem.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { ModalEquipamentosFullscreenModule } from 'app/shared/components/modal-equipamentos-fullscreen/modal-equipamentos-fullscreen.module';

@NgModule({
    declarations: [DataUltimaComunicacaoArmazemComponent],
    imports: [
        CommonModule, 
        MatIconModule, 
        MatTooltipModule, 
        TranslocoRootModule, 
        ModalEquipamentosFullscreenModule
    ],
    exports: [DataUltimaComunicacaoArmazemComponent],
})
export class DataUltimaComunicacaoArmazemModule {}
