import { Injectable } from '@angular/core';
import moment from 'moment';

export abstract class ITrataTimezoneRegraService {
    abstract removeTimezoneHRSLIGAR(formula: string): string;
    abstract adicionaTimezoneHRSLIGAR(formula: string): string;
}

@Injectable()
export class TrataTimezoneRegraService implements ITrataTimezoneRegraService {
    removeTimezoneHRSLIGAR(formula: string): string {
        const regex = /\b\d{1,2}:\d{2}\b/g;
        if (formula.includes('HRSLIGAR')) {
            const horariosInicioFim = formula.match(regex);
            const horarioInicioFimSemUtc = horariosInicioFim.map(
                (horario) => `${moment(horario, 'HH:mm').utc(false).format('HH:mm')}`
            );
            return this.substutuiHorario(formula, horarioInicioFimSemUtc);
        } else {
            return formula;
        }
    }

    adicionaTimezoneHRSLIGAR(formula: string): string {
        const regex = /\b\d{1,2}:\d{2}\b/g;
        if (formula.includes('HRSLIGAR')) {
            const horariosInicioFim = formula.match(regex);
            const horarioInicioFimComUtc = horariosInicioFim.map(
                (horario) => `${moment(horario, 'HH:mm').add(moment().utcOffset(), 'minutes').format('HH:mm')}`
            );
            return this.substutuiHorario(formula, horarioInicioFimComUtc);
        } else {
            return formula;
        }
    }

    private substutuiHorario(formula: string, horarios: string[]): string {
        const regex = /\b\d{1,2}:\d{2}\b/g;
        return formula.replace(regex, () => horarios.shift());
    }
}
