import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SensoresInformacoesGeraisArmazemComponent } from 'app/shared/components/sensores-informacoes-gerais-armazem/sensores-informacoes-gerais-armazem.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule],
    declarations: [SensoresInformacoesGeraisArmazemComponent],
    exports: [SensoresInformacoesGeraisArmazemComponent],
})
export class SensoresInformacoesGeraisArmazemModule {}
