<ng-container *ngIf="!buscandoEstacoes; else carregando">
    <ng-container *ngIf="estacoes?.length; else semDados">
        <section *ngIf="listagemEstacoesMeteorologicas.data?.length > 0" class="relative estacao">
            <carousel [slidesDisponiveis]="slidesDisponiveis" (slideSelecionado)="selecionarEstacao($event)">
                <ng-container *ngFor="let listagem of listagemEstacoesMeteorologicas.data; let i = index">
                    <ng-container *ngIf="tamplateSlideSelecionado === listagemEstacoesMeteorologicas.data[i].id">
                        <div class="flex flex-col w-full px-1 py-0.5">
                            <div class="flex items-center border-b mt-1">
                                <span
                                    class="rounded-md text-center mb-0.5 px-2 py-[0.1px]"
                                    [ngClass]="carregaEstiloEstacaoMeteorologica(listagem)"
                                >
                                    {{ listagem.codigo }}
                                </span>
                                <span class="text-[#535767] text-sm leading-[14px] font-medium ml-2">{{
                                    listagem.nome
                                }}</span>
                            </div>
                            <app-template-informacoes-estacao-metereologica
                                [estacao]="(listagemEstacoesMeteorologicas?.data)[i]"
                            >
                            </app-template-informacoes-estacao-metereologica>
                        </div>
                    </ng-container>
                </ng-container>
            </carousel>
        </section>
    </ng-container>
</ng-container>

<ng-template #carregando class="absolute">
    <div class="h-[64px] flex">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>

<ng-template #semDados>
    <div class="flex flex-col w-full h-[64px] p-4">
        <div class="flex items-center border-b">
            <span class="text-[#535767] text-[14px] leading-4 font-medium mb-2">{{
                "INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT.ESTACAO_METEOROLOGICA" | transloco
            }}</span>
        </div>
        <span class="m-auto flex text-[#535767]">
            {{ "INFORMACOES_ESTACAO_METEREOLOGICA_COMPONENT.NENHUM_RESULTADO_ENCONTRADO" | transloco }}
        </span>
    </div>
</ng-template>
