export enum NomesProdutos {
    VAZIO = 'VAZIO',
    ARROZ = 'ARROZ',
    AVEIA = 'AVEIA',
    AZEVEM = 'AZEVÉM',
    BRACHIARIA = 'BRACHIARIA',
    CAFEDESCASCADO = 'CAFÉDESCASCADO',
    CAFEPERGAMINHO = 'CAFÉPERGAMINHO',
    CANOLA = 'CANOLA',
    CAROCODEALGODAO = 'CAROÇODEALGODÃO',
    CEVADA = 'CEVADA',
    ERVILHA = 'ERVILHA',
    FEIJAO = 'FEIJÃO',
    GERGELIM = 'GERGELIM',
    GIRASSOL = 'GIRASSOL',
    MILHETO = 'MILHETO',
    MILHO = 'MILHO',
    MILHOPIPOCA = 'MILHOPIPOCA',
    NABOFORRAGEIRO = 'NABOFORRAGEIRO',
    PAINCO = 'PAINÇO',
    SOJA = 'SOJA',
    SORGO = 'SORGO',
    TRIGO = 'TRIGO',
    TRITICALE = 'TRITICALE',
    CROTALARIA = 'CROTALARIA',
    CENTEIO = 'CENTEIO',
}
