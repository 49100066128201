import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { IListagemArmazenagemController, IResumoUnidadeController, UsuarioController } from 'app/shared/controllers';
import { AutenticacaoUsuarioObservable, UnidadeParametrizacaoObservable } from 'app/shared/observables';
import { PrimeiroAcessoObservable } from 'app/modules/auth/observables/primeiro-acesso.observable';

@Injectable({
    providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
    constructor(
        readonly unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable,
        readonly autenticacaoUsuarioObservable: AutenticacaoUsuarioObservable,
        private listagemArmazenagemController: IListagemArmazenagemController,
        private usuarioController: UsuarioController,
        private primeiroAcessoObservable: PrimeiroAcessoObservable,
        private resumoUnidadeController: IResumoUnidadeController
    ) {}

    resolve(): void {
        this.verificaPrimeiroAcesso();
        this.buscaResumoUnidade();
    }

    verificaPrimeiroAcesso(): void {
        const token = localStorage.getItem('hash_token');
        if (this.primeiroAcessoObservable.buscaPrimeiroAcesso == null) {
            this.usuarioController.verificarPrimeiroAcesso(token).subscribe();
        }
    }

    buscaResumoUnidade(): void {
        this.resumoUnidadeController.buscaResumoUnidade(true).subscribe();
    }

    buscaListaSimplesEstruturasArmazenagem(): void {
        const lista = localStorage.getItem('estruturasArmazenagem');
        if (!lista) {
            this.listagemArmazenagemController.buscarEstruturasArmazenagem().subscribe();
        }
    }
}
