import { Injectable } from '@angular/core';

import { BehaviorSubject, interval, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import moment from 'moment';

import { AtendimentoInterface } from 'app/shared/interfaces';

@Injectable({
    providedIn: 'root',
})
export class CheckInCheckOutObservable {
    private atendimento: BehaviorSubject<AtendimentoInterface> = new BehaviorSubject<AtendimentoInterface>(null);
    private passadorUnidadesBloqueado: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private contadorCheckOut: BehaviorSubject<string> = new BehaviorSubject<string>('00:00:00');
    private contadorPausado: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private checkInAtivo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private tempoDecorrido: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private atendimentoId: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private checkoutIniciado: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private carregandoDados: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private contadorSubscription: any;
    private tempoTotalPausado: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    private instantePausa: BehaviorSubject<moment.Moment> = new BehaviorSubject<moment.Moment>(null);

    get getCheckInAtivo(): boolean {
        return this.checkInAtivo.getValue();
    }

    get getCarregandoDados$(): Observable<boolean> {
        return this.carregandoDados.asObservable();
    }

    set setCarregandoDados(value: boolean) {
        this.carregandoDados.next(value);
    }

    get checkInAtivo$(): Observable<boolean> {
        return this.checkInAtivo.asObservable();
    }

    get getAtendimentoId(): string {
        return this.atendimentoId.getValue();
    }

    set setAtendimentoId(value: string) {
        this.atendimentoId.next(value);
    }

    get getAtendimento(): AtendimentoInterface {
        return this.atendimento.getValue();
    }

    set setAtendimento(value: AtendimentoInterface) {
        const currentValue = this.atendimento.getValue();

        if (JSON.stringify(currentValue) !== JSON.stringify(value)) {
            this.atendimento.next(value);
            if (value?.id) {
                this.setAtendimentoId = value.id;
            }
        }
    }

    get atendimento$(): Observable<AtendimentoInterface> {
        return this.atendimento.asObservable();
    }

    set setCheckInAtivo(value: boolean) {
        this.checkInAtivo.next(value);
        this.setPassadorUnidadesBloqueado = value;
    }

    get getPassadorUnidadesBloqueado(): boolean {
        return this.passadorUnidadesBloqueado.getValue();
    }

    get passadorUnidadesBloqueado$(): Observable<boolean> {
        return this.passadorUnidadesBloqueado.asObservable();
    }

    set setPassadorUnidadesBloqueado(value: boolean) {
        this.passadorUnidadesBloqueado.next(value);
    }

    get contadorCheckOut$(): Observable<string> {
        return this.contadorCheckOut.asObservable();
    }

    get getContadorCheckOut(): string {
        return this.contadorCheckOut.getValue();
    }

    set setContadorCheckOut(value: string) {
        this.contadorCheckOut.next(value);
    }

    get getTempoDecorrido(): number {
        return Math.floor(this.tempoDecorrido.getValue() / 60);
    }

    set setTempoDecorrido(value: number) {
        this.tempoDecorrido.next(value);
    }

    set setPausado(value: boolean) {
        this.contadorPausado.next(value);
    }

    get getPausado(): boolean {
        return this.contadorPausado.getValue();
    }

    get getCheckoutIniciado(): boolean {
        return this.checkoutIniciado.getValue();
    }

    get checkoutIniciado$(): Observable<boolean> {
        return this.checkoutIniciado.asObservable();
    }

    set setCheckoutIniciado(value: boolean) {
        this.checkoutIniciado.next(value);
    }

    get getTempoTotalPausado(): number {
        return this.tempoTotalPausado.getValue();
    }

    set setTempoTotalPausado(value: number) {
        this.tempoTotalPausado.next(value);
    }

    get getInstantePausa(): moment.Moment {
        return this.instantePausa.getValue();
    }

    set setInstantePausa(value: moment.Moment) {
        this.instantePausa.next(value);
    }

    registrarPausa(): void {
        this.setInstantePausa = moment();
    }

    calcularTempoPausado(): void {
        const instantePausa = this.getInstantePausa;

        if (instantePausa) {
            const agora = moment();
            const tempoPausado = agora.diff(instantePausa, 'seconds');
            this.setTempoTotalPausado = this.getTempoTotalPausado + tempoPausado;
            this.setInstantePausa = null;
        }
    }

    iniciarContador(): void {
        if (this.contadorSubscription) {
            this.contadorSubscription.unsubscribe();
        }

        const atendimento = this.getAtendimento;

        if (!atendimento) {
            this.setTempoDecorrido = 0;
            this.setContadorCheckOut = '00:00:00';
            return;
        }

        if (atendimento.instante_checkin) {
            const dataCheckIn = moment(atendimento.instante_checkin);
            const agora = moment();
            const diferencaEmSegundos = Math.abs(agora.diff(dataCheckIn, 'seconds') - this.getTempoTotalPausado);
            this.setTempoDecorrido = diferencaEmSegundos < 0 ? 0 : diferencaEmSegundos;
        } else {
            this.setTempoDecorrido = 0;
        }

        if (this.getAtendimento.status === 'pausado') return;

        this.contadorSubscription = interval(1000)
            .pipe(
                startWith(0),
                map(() => {
                    if (!this.contadorPausado.getValue() && this.checkInAtivo.getValue()) {
                        const atendimento = this.getAtendimento;

                        if (atendimento?.instante_checkin) {
                            const dataCheckIn = moment(atendimento.instante_checkin);
                            const agora = moment();
                            const diferencaEmSegundos = Math.abs(
                                agora.diff(dataCheckIn, 'seconds') - this.getTempoTotalPausado
                            );
                            this.tempoDecorrido.next(diferencaEmSegundos);
                        }
                    }
                    const duracao = moment.duration(this.tempoDecorrido.getValue(), 'seconds');
                    const tempoFormatado = moment.utc(duracao.asMilliseconds()).format('HH:mm:ss');
                    this.contadorCheckOut.next(tempoFormatado);
                })
            )
            .subscribe();
    }

    reiniciarContador(): void {
        if (this.contadorSubscription) {
            this.contadorSubscription.unsubscribe();
        }

        this.setTempoDecorrido = 0;
        this.setContadorCheckOut = '00:00:00';
        this.setTempoTotalPausado = 0;
        this.setInstantePausa = null;

        const atendimento = this.getAtendimento;

        if (atendimento?.instante_checkin) {
            const dataCheckIn = moment(atendimento.instante_checkin);
            const agora = moment();
            const diferencaEmSegundos = Math.abs(agora.diff(dataCheckIn, 'seconds'));
            this.setTempoDecorrido = diferencaEmSegundos < 0 ? 0 : diferencaEmSegundos;
        }

        this.contadorSubscription = interval(1000)
            .pipe(
                startWith(0),
                map(() => {
                    if (!this.contadorPausado.getValue() && this.checkInAtivo.getValue()) {
                        const atendimento = this.getAtendimento;
                        if (atendimento?.instante_checkin) {
                            const dataCheckIn = moment(atendimento.instante_checkin);
                            const agora = moment();
                            const diferencaEmSegundos = Math.abs(agora.diff(dataCheckIn, 'seconds'));
                            this.tempoDecorrido.next(diferencaEmSegundos);
                        }
                    }
                    const duracao = moment.duration(this.tempoDecorrido.getValue(), 'seconds');
                    const tempoFormatado = moment.utc(duracao.asMilliseconds()).format('HH:mm:ss');
                    this.contadorCheckOut.next(tempoFormatado);
                })
            )
            .subscribe();
    }
}
