<ng-container *ngIf="exibirNotificacao">
    <div class="w-full h-[40px] rounded bg-[#fcf1d4] text-[#B48707] flex items-center text-[13px] mb-2">
        <mat-icon class="ml-3 p-1" [svgIcon]="'icon-alert-amarelo'"></mat-icon>
        <p class="ml-2">
            <span class="font-semibold text-[13px] leading-4">
                {{ titulo }}
            </span>
            <br />
            <span class="font-normal text-[12px]">{{ mensagem }}</span>
        </p>
    </div>
</ng-container>
