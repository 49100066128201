import { Component, Input } from '@angular/core';
import { AmostragemInformacoesEstruturaArmazenagemInterface } from 'app/shared/interfaces';
import { IVerificaValidadeDeAmostragemService } from 'app/shared/services';

@Component({
    selector: 'app-amostragem-informacoes-gerais-armazem',
    templateUrl: './amostragem-informacoes-gerais-armazem.component.html',
    styleUrls: ['./amostragem-informacoes-gerais-armazem.component.scss'],
})
export class AmostragemInformacoesGeraisArmazemComponent {
    @Input() amostragem: AmostragemInformacoesEstruturaArmazenagemInterface[];

    constructor(readonly verificaValidadeDeAmostragemService: IVerificaValidadeDeAmostragemService) {}
}
