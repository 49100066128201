<div class="w-full h-full bg-white">
	<div class="flex flex-row h-full">
		<div class="overflow-x-scroll h-full w-full containerCanvaArmazem">
			<app-tooltip-armazem [pendulos]="armazemPendulos"></app-tooltip-armazem>

			<canvas
				#selector
				class="plain-warehouse-view"
				style="z-index: 11; position: relative"
				[attr.data-config]="config"
				[attr.data-thermal]="thermal"
				[attr.data-humidity]="humidity"
				[attr.data-thermocouples]="thermocouples"
				[attr.data-sequence]="sequence"
				[attr.data-emc]="emc"
				[attr.data-level]="level"
				[attr.data-communicatingCables]="communicatingCables"
				[attr.data-alias]="alias"
				[attr.data-cables]="cables"
				[attr.data-selected-map]="selected"
				width="100%"
			>
			</canvas>
		</div>
	</div>
</div>
