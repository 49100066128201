import { Component, Input } from '@angular/core';
import {
    AmostragemInformacoesEstruturaArmazenagemInterface,
    ProdutoInformacoesSiloInterface,
} from 'app/shared/interfaces';
import { IVerificaValidadeDeAmostragemService } from 'app/shared/services';

@Component({
    selector: 'app-grao-informacoes-gerais-armazem',
    templateUrl: './grao-informacoes-gerais-armazem.component.html',
    styleUrls: ['./grao-informacoes-gerais-armazem.component.scss'],
})
export class GraoInformacoesGeraisArmazemComponent {
    @Input() amostragem: AmostragemInformacoesEstruturaArmazenagemInterface;
    @Input() produto: ProdutoInformacoesSiloInterface;

    constructor(readonly verificaValidadeDeAmostragemService: IVerificaValidadeDeAmostragemService) {}
}
