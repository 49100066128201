<div class="h-max">
    <div class="text-[#0b0c0e] p-2 wl-cor-primaria flex">
        <mat-icon class="p-[2px] ml-[-3px]" [svgIcon]="'icon-silo-estoque'"></mat-icon>
        <span class="my-1 ml-2">{{ 'ESTOQUE_INFORMACOES_GERAIS_COMPONENT.ESTOQUE' | transloco }}</span>
    </div>
    <div>
        <ng-container *ngIf="!possuiSensorVolumetria3D()">
            <ng-container *ngIf="compactacaoLayout; else layoutDescompactado">
                <div class="w-full flex flex-col">
                    <!-- estoque informado -->
                    <div class="w-full flex flex-col">
                        <span
                            class="w-full py-2 px-4 flex flex-col justify-between items-center text-xs sm:text-base rounded"
                        >
                            <span class="w-full flex flex-row"
                                ><span class="wl-cor-primaria text-base"
                                    >{{ 'ESTOQUE_INFORMACOES_GERAIS_COMPONENT.INFORMADO' | transloco }}
                                </span>
                                <span class="ml-2">
                                    ({{
                                        calculaPercentualEstoque(
                                            estoque?.tonelada?.estoque_informado,
                                            estoque?.tonelada?.capacidade_estoque
                                        ) | number : '1.1-1'
                                    }}%)</span
                                ></span
                            >
                            <div class="w-full text-center">
                                <ng-container
                                    *ngIf="
                                        estoque?.tonelada?.estoque_informado == null ||
                                            estoque?.saca?.estoque_informado == null;
                                        then naoConfigurado;
                                        else EstoqueInformadoConfigurado
                                    "
                                ></ng-container>

                                <span
                                    class="progress-line mb-1"
                                    [ngClass]="
                                        estoque?.tonelada?.estoque_informado == null ||
                                        estoque?.saca?.estoque_informado == null
                                            ? ''
                                            : 'w-full'
                                    "
                                >
                                    <span
                                        class="line-bar"
                                        [ngStyle]="{
                                            'width.%':
                                                (estoque?.tonelada?.estoque_informado /
                                                    estoque?.tonelada?.capacidade_estoque) *
                                                100
                                        }"
                                    ></span>
                                </span>
                            </div>
                        </span>
                    </div>

                    <!-- estoque estimado -->
                    <div class="w-full flex flex-col">
                        <span
                            class="w-full py-2 px-4 flex flex-col justify-between items-center text-xs sm:text-base rounded"
                        >
                            <span class="w-full flex flex-row"
                                ><span class="wl-cor-primaria text-base">{{
                                    'ESTOQUE_INFORMACOES_GERAIS_COMPONENT.ESTIMADO' | transloco
                                }}</span>
                                <span class="ml-2">
                                    ({{
                                        calculaPercentualEstoque(
                                            estoque?.tonelada?.estoque_estimado,
                                            estoque?.tonelada?.capacidade_estoque
                                        ) | number : '1.1-1'
                                    }}%)</span
                                ></span
                            >
                            <div class="w-full text-center">
                                <ng-container
                                    *ngIf="
                                        estoque?.tonelada?.estoque_estimado == null ||
                                            estoque?.saca?.estoque_estimado == null;
                                        then naoConfigurado;
                                        else EstoqueEstimadoConfigurado
                                    "
                                ></ng-container>

                                <span
                                    class="progress-line mb-1"
                                    [ngClass]="
                                        estoque?.tonelada?.estoque_estimado == null ||
                                        estoque?.saca?.estoque_estimado == null
                                            ? ''
                                            : 'w-full'
                                    "
                                >
                                    <span
                                        class="line-bar"
                                        [ngStyle]="{
                                            'width.%':
                                                (estoque?.tonelada?.estoque_estimado /
                                                    estoque?.tonelada?.capacidade_estoque) *
                                                100
                                        }"
                                    ></span>
                                </span>
                            </div>
                        </span>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="possuiSensorVolumetria3D()">
            <div class="w-full flex items-center my-3 px-4 py-2">
                <div class="w-full flex flex-col">
                    <div>
                        <span class="flex items-center text-xs sm:text-base rounded">
                            <ng-container
                                *ngIf="
                                    sensorVolumetria?.tonelada?.med && estoque?.tonelada?.capacidade_estoque;
                                    else semDadosLeitura
                                "
                            >
                                <span class="wl-cor-primaria mr-0.5">
                                    {{ 'VISAO_DETALHADA_SILOS_SCREEN.MEDIDOR_VOLUME_3D' | transloco }}
                                </span>
                                ({{
                                    calculaPercentualEstoque(
                                        sensorVolumetria?.tonelada?.med,
                                        estoque?.tonelada?.capacidade_estoque
                                    ) | number : '1.1-1'
                                }}%)
                            </ng-container>

                            <ng-template #semDadosLeitura>
                                {{ 'VISAO_DETALHADA_SILOS_SCREEN.MEDIDOR_VOLUME_3D' | transloco }}
                                (-)
                            </ng-template>
                        </span>
                        <span class="flex items-center text-xs -mt-[2px]">
                            <app-data-ultima-comunicacao
                                [equipamentos]="
                                    formataArrayEquipamentosComponenteData(listaEquimentosSensoresVolumetria)
                                "
                                [visualizarTempo]="true"
                                [tamanhoIconeStatus]="'icon-size-09'"
                                [ocultarModal]="ocultarModal"
                                [apenasVisualizacao]="true"
                            >
                            </app-data-ultima-comunicacao>
                            <ng-container
                                *ngIf="possuiSensorVolumetria3D() && !equipamentoVolumeTria3DSemComunicacao()"
                            >
                                <p
                                    class="flex items-center"
                                    (mouseenter)="abrirTooltip($event)"
                                    (mouseleave)="fecharTooltip()"
                                >
                                    <mat-icon class="text-[15px] text-[#a44040] mb-1"> warning </mat-icon>
                                </p>
                            </ng-container>
                        </span>
                    </div>
                    <div class="mt-2">
                        <ng-container
                            *ngIf="
                                sensorVolumetria?.tonelada?.med === null || sensorVolumetria?.saca?.med === null;
                                then semLeitura3D;
                                else VolumetriaConfigurada
                            "
                        ></ng-container>

                        <span
                            class="progress-line mb-1"
                            [ngClass]="
                                sensorVolumetria?.tonelada?.med === null || sensorVolumetria?.saca?.med === null
                                    ? ''
                                    : 'w-full'
                            "
                        >
                            <span
                                class="line-bar"
                                [ngStyle]="{
                                    'width.%':
                                        (sensorVolumetria?.tonelada?.med / estoque?.tonelada?.capacidade_estoque) * 100
                                }"
                            ></span>
                        </span>
                    </div>
                </div>
            </div>
        </ng-container>

        <!-- capacidade -->
        <div class="w-full py-2 px-4 flex flex-col justify-between text-xs sm:text-base rounded">
            <div class="wl-cor-primaria text-base">
                {{ 'ESTOQUE_INFORMACOES_GERAIS_COMPONENT.CAPACIDADE' | transloco }}
            </div>
            <div>
                <span>
                    {{
                        estoque?.tonelada?.capacidade_estoque != null
                            ? (estoque?.tonelada?.capacidade_estoque | number : '1.1-1') + 't/'
                            : ('ESTOQUE_INFORMACOES_GERAIS_COMPONENT.NAO_CONFIGURADO' | transloco)
                    }}
                    {{
                        estoque?.saca?.capacidade_estoque != null
                            ? (estoque?.saca?.capacidade_estoque | number : '1.1-1') + 'sc'
                            : ''
                    }}
                </span>
            </div>
        </div>
    </div>
</div>

<ng-template #layoutDescompactado>
    <div class="w-full flex flex-row">
        <!-- estoque informado -->
        <div class="w-[50%] flex flex-col">
            <span class="w-full py-2 px-4 flex flex-col justify-between items-center text-xs sm:text-base rounded">
                <span class="w-full flex flex-row"
                    ><span class="wl-cor-primaria text-base"
                        >{{ 'ESTOQUE_INFORMACOES_GERAIS_COMPONENT.INFORMADO' | transloco }}
                    </span>
                    <span class="ml-2">
                        ({{
                            calculaPercentualEstoque(
                                estoque?.tonelada?.estoque_informado,
                                estoque?.tonelada?.capacidade_estoque
                            ) | number : '1.1-1'
                        }}%)</span
                    ></span
                >
                <div class="w-full text-center">
                    <ng-container
                        *ngIf="
                            estoque?.tonelada?.estoque_informado == null || estoque?.saca?.estoque_informado == null;
                            then naoConfigurado;
                            else EstoqueInformadoConfigurado
                        "
                    ></ng-container>

                    <span
                        class="progress-line mb-1"
                        [ngClass]="
                            estoque?.tonelada?.estoque_informado == null || estoque?.saca?.estoque_informado == null
                                ? ''
                                : 'w-full'
                        "
                    >
                        <span
                            class="line-bar"
                            [ngStyle]="{
                                'width.%':
                                    (estoque?.tonelada?.estoque_informado / estoque?.tonelada?.capacidade_estoque) * 100
                            }"
                        ></span>
                    </span>
                </div>
            </span>
        </div>

        <!-- estoque estimado -->
        <div class="w-[50%] flex flex-col">
            <span class="w-full py-2 px-4 flex flex-col justify-between items-center text-xs sm:text-base rounded">
                <span class="w-full flex flex-row"
                    ><span class="wl-cor-primaria text-base">{{
                        'ESTOQUE_INFORMACOES_GERAIS_COMPONENT.ESTIMADO' | transloco
                    }}</span>
                    <span class="ml-2">
                        ({{
                            calculaPercentualEstoque(
                                estoque?.tonelada?.estoque_estimado,
                                estoque?.tonelada?.capacidade_estoque
                            ) | number : '1.1-1'
                        }}%)</span
                    ></span
                >
                <div class="w-full text-center">
                    <ng-container
                        *ngIf="
                            estoque?.tonelada?.estoque_estimado == null || estoque?.saca?.estoque_estimado == null;
                            then naoConfigurado;
                            else EstoqueEstimadoConfigurado
                        "
                    ></ng-container>

                    <span
                        class="progress-line mb-1"
                        [ngClass]="
                            estoque?.tonelada?.estoque_estimado == null || estoque?.saca?.estoque_estimado == null
                                ? ''
                                : 'w-full'
                        "
                    >
                        <span
                            class="line-bar"
                            [ngStyle]="{
                                'width.%':
                                    (estoque?.tonelada?.estoque_estimado / estoque?.tonelada?.capacidade_estoque) * 100
                            }"
                        ></span>
                    </span>
                </div>
            </span>
        </div>
    </div>
</ng-template>

<ng-template #naoConfigurado> {{ 'ESTOQUE_INFORMACOES_GERAIS_COMPONENT.NAO_CONFIGURADO' | transloco }} </ng-template>

<ng-template #semLeiuta> {{ 'ESTOQUE_INFORMACOES_GERAIS_COMPONENT.AGUARDANDO_LEITURA' | transloco }} </ng-template>

<ng-template #semLeitura3D> -/- </ng-template>

<ng-template #EstoqueInformadoConfigurado>
    {{ estoque?.tonelada?.estoque_informado | number : '1.1-1' }}t/{{
        estoque?.saca?.estoque_informado | number : '1.1-1'
    }}sc
</ng-template>

<ng-template #EstoqueEstimadoConfigurado>
    {{ estoque?.tonelada?.estoque_estimado | number : '1.1-1' }}t/{{
        estoque?.saca?.estoque_estimado | number : '1.1-1'
    }}sc
</ng-template>
<ng-template #VolumetriaConfigurada>
    {{ sensorVolumetria?.tonelada?.med ? (sensorVolumetria.tonelada?.med | number : '1.1-1') : '0,0' }}t/{{
        sensorVolumetria?.saca?.med ? (sensorVolumetria.saca?.med | number : '1.1-1') : '0,0'
    }}sc
</ng-template>
