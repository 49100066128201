<ng-container *ngIf="!carregandoDados; else carregandoSpinner">
    <ng-container *ngIf="!checkInIniciado">
        <div class="w-[210px] flex justify-end">
            <span
                class="cursor-pointer flex items-center text-[#64748B] hover:text-[#019de8]"
                [matMenuTriggerFor]="menuCheckIn"
                #menuTriggerCheckIn="matMenuTrigger"
                [attr.aria-label]="'CHECK_IN_CHECK_OUT.FAZER_CHECK_IN' | transloco"
                [matTooltip]="'CHECK_IN_CHECK_OUT.FAZER_CHECK_IN' | transloco"
                matTooltipClass="tooltip-padrao break-keep"
                matTooltipPosition="above"
            >
                <mat-icon class="text-current">exit_to_app</mat-icon>
                <span class="ml-2 text-current">
                    {{ 'CHECK_IN_CHECK_OUT.CHECK_IN' | transloco }}
                </span>
            </span>
            <mat-menu #menuCheckIn="matMenu" class="px-3 py-2" [hasBackdrop]="true" [overlapTrigger]="false">
                <p class="text-center wl-cor-primaria mb-3">
                    {{ 'CHECK_IN_CHECK_OUT.CONFIRMAR_CHECK_IN' | transloco }}
                </p>
                <div class="flex justify-between">
                    <button
                        type="button"
                        class="action w-[105px] h-[43px] mr-5 leading-4 text-[14px] text-white wl-bg-cor-secundaria border-btn-salvar"
                        [attr.aria-label]="'CHECK_IN_CHECK_OUT.FAZER_CHECK_IN' | transloco"
                        (click)="iniciarCheckIn()"
                    >
                        {{ 'CHECK_IN_CHECK_OUT.SIM' | transloco }}
                    </button>
                    <button
                        type="button"
                        class="w-[105px] h-[43px] leading-4 text-[14px]"
                        [attr.aria-label]="'CHECK_IN_CHECK_OUT.CANCELAR' | transloco"
                        (click)="menuTriggerCheckIn.closeMenu()"
                    >
                        {{ 'CHECK_IN_CHECK_OUT.CANCELAR' | transloco }}
                    </button>
                </div>
            </mat-menu>
        </div>
    </ng-container>

    <ng-container *ngIf="checkInIniciado">
        <div class="w-[210px] flex justify-between items-center text-[#64748B] text-base">
            <mat-icon
                class="p-0.5 cursor-pointer hover:text-[#019de8]"
                [ngClass]="{ 'opacity-50 cursor-not-allowed': fazendoRequisicao }"
                [ngStyle]="{ pointerEvents: fazendoRequisicao ? 'none' : 'auto' }"
                (click)="!fazendoRequisicao && reiniciarContagemCheckOut()"
                [svgIcon]="'refresh'"
                [attr.aria-label]="'CHECK_IN_CHECK_OUT.REINICIAR_CHECK_IN' | transloco"
                [matTooltip]="'CHECK_IN_CHECK_OUT.REINICIAR_CHECK_IN' | transloco"
                [matTooltipDisabled]="fazendoRequisicao"
                matTooltipClass="tooltip-padrao break-keep"
                matTooltipPosition="above"
            ></mat-icon>
            <mat-icon
                class="p-0.5 cursor-pointer hover:text-[#019de8]"
                [ngClass]="{ 'opacity-50 cursor-not-allowed': fazendoRequisicao }"
                [ngStyle]="{ pointerEvents: fazendoRequisicao ? 'none' : 'auto' }"
                (click)="!fazendoRequisicao && alternarContagem()"
                [svgIcon]="atendimentoAtual?.status === 'pausado' ? 'play_arrow' : 'pause'"
                [attr.aria-label]="
                    atendimentoAtual?.status === 'pausado'
                        ? ('CHECK_IN_CHECK_OUT.RETOMAR_CHECK_IN' | transloco)
                        : ('CHECK_IN_CHECK_OUT.PAUSAR_CHECK_IN' | transloco)
                "
                [matTooltip]="
                    atendimentoAtual?.status === 'pausado'
                        ? ('CHECK_IN_CHECK_OUT.RETOMAR_CHECK_IN' | transloco)
                        : ('CHECK_IN_CHECK_OUT.PAUSAR_CHECK_IN' | transloco)
                "
                matTooltipClass="tooltip-padrao break-keep"
                matTooltipPosition="above"
            ></mat-icon>
            <span class="text-center w-[70px]">{{ tempoDecorrido$ | async }}</span>
            <span
                class="cursor-pointer hover:text-[#019de8]"
                [matMenuTriggerFor]="menu"
                #menuTrigger="matMenuTrigger"
                [attr.aria-label]="'CHECK_IN_CHECK_OUT.FAZER_CHECK_OUT' | transloco"
                [matTooltip]="'CHECK_IN_CHECK_OUT.FAZER_CHECK_OUT' | transloco"
                matTooltipClass="tooltip-padrao break-keep"
                matTooltipPosition="above"
            >
                {{ 'CHECK_IN_CHECK_OUT.CHECK_OUT' | transloco }}
            </span>
            <mat-menu #menu="matMenu" class="px-3 py-2" [hasBackdrop]="true" [overlapTrigger]="false">
                <p class="text-center wl-cor-primaria mb-3">
                    {{ 'CHECK_IN_CHECK_OUT.CONFIRMAR_CHECK_OUT' | transloco }}
                </p>
                <div class="flex justify-between">
                    <button
                        type="button"
                        class="action w-[105px] h-[43px] mr-5 leading-4 text-[14px] text-white wl-bg-cor-secundaria border-btn-salvar"
                        (click)="abrirCheckOut()"
                        [attr.aria-label]="'CHECK_IN_CHECK_OUT.FAZER_CHECK_OUT' | transloco"
                        [disabled]="checkInCheckOutObservable.getCheckoutIniciado"
                    >
                        {{ 'CHECK_IN_CHECK_OUT.SIM' | transloco }}
                    </button>
                    <button
                        type="button"
                        class="w-[105px] h-[43px] leading-4 text-[14px]"
                        (click)="menuTrigger.closeMenu()"
                        [attr.aria-label]="'CHECK_IN_CHECK_OUT.CANCELAR' | transloco"
                        [disabled]="checkInCheckOutObservable.getCheckoutIniciado"
                    >
                        {{ 'CHECK_IN_CHECK_OUT.CANCELAR' | transloco }}
                    </button>
                </div>
            </mat-menu>
        </div>
    </ng-container>
</ng-container>

<ng-template #carregandoSpinner>
    <div class="w-[110px] flex justify-center items-center">
        <mat-progress-spinner [diameter]="16" [mode]="'indeterminate'"></mat-progress-spinner>
    </div>
</ng-template>
