<ng-container *ngIf="!possuiStatusComunicacao && statusAutomatico != 'indefinido'">
    <span
        *ngIf="statusAutomatico"
        [ngClass]="classWidth"
        class="flex rounded-md p-1 sm:p-2 h-4 justify-center bg-[#B2E7A7] text-[#34901C] text-xs sm:text-base items-center cursor-default"        
    >
        {{"STATUS_AUTOMATICO_MANUAL_COMPONENT.A" | transloco}}
    </span>
    <span
        *ngIf="!statusAutomatico"
        [ngClass]="classWidth"
        class="flex rounded-md p-1 sm:p-2 h-4 justify-center bg-[#FBBC0566] text-[#B48707] text-xs sm:text-base items-center cursor-default"        
    >
        {{"STATUS_AUTOMATICO_MANUAL_COMPONENT.M" | transloco}}
    </span>
</ng-container>

<ng-container *ngIf="possuiStatusComunicacao && statusAutomatico != 'indefinido'">
    <ng-container *ngIf="equipamentoComunicando">
        <span
            *ngIf="statusAutomatico"
            [ngClass]="classWidth"
            class="flex rounded-md p-1 sm:p-2 h-4 justify-center bg-[#B2E7A7] text-[#34901C] text-xs sm:text-base items-center cursor-default"            
        >
            {{"STATUS_AUTOMATICO_MANUAL_COMPONENT.A" | transloco}}
        </span>
        <span
            *ngIf="!statusAutomatico"
            [ngClass]="classWidth"
            class="flex rounded-md p-1 sm:p-2 h-4 justify-center bg-[#FBBC0566] text-[#B48707] text-xs sm:text-base items-center cursor-default"            
        >
            {{"STATUS_AUTOMATICO_MANUAL_COMPONENT.M" | transloco}}
        </span>
    </ng-container>
    <ng-container
        *ngIf="!equipamentoComunicando && naoNuloOuIndefinido(equipamentoComunicando)"
    >
        <span
            [ngClass]="classWidth"
            class="flex rounded-md p-1 sm:p-2 h-4 justify-center bg-[#F9E3E3] text-[#A44040] text-xs sm:text-base items-center cursor-default"            
        >
            {{"STATUS_AUTOMATICO_MANUAL_COMPONENT.S_C" | transloco}}
        </span>
    </ng-container>
    <ng-container *ngIf="equipamentoComunicando === undefined || equipamentoComunicando === null">
        <span
            [ngClass]="classWidth"
            class="flex rounded-md p-1 sm:p-2 h-4 justify-center bg-[#E8E9EA] text-[#030229] text-xs sm:text-base items-center cursor-default"
        >
            -
        </span>
    </ng-container>
</ng-container>
<ng-container *ngIf="statusAutomatico === 'indefinido'">
    <span        
        [ngClass]="classWidth"
        class="flex rounded-md h-4 justify-center bg-[#E8E9EA] text-[#030229] text-base items-center cursor-default"
    >
        -
    </span>
</ng-container>
