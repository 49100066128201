import { OpcoesPersonalizadasEnum } from 'app/shared/components/calendario-duplo/enums';
import moment from 'moment';

export class PeriodoHelper {
    public static obterPeriodo(dataInicio: string, dataFim: string): OpcoesPersonalizadasEnum | null {
        const inicio = moment(dataInicio);
        const fim = moment(dataFim);
        const diffDias = fim.diff(inicio, 'days');

        switch (true) {
            case inicio.isSame(fim, 'day'):
                return OpcoesPersonalizadasEnum.HOJE;
            case diffDias === 1 && fim.isSame(moment(), 'day'):
                return OpcoesPersonalizadasEnum.ONTEM;
            case fim.diff(inicio, 'hours') <= 24:
                return OpcoesPersonalizadasEnum.ULTIMAS_24_HORAS;
            case inicio.isSame(moment().startOf('week'), 'day'):
                return OpcoesPersonalizadasEnum.ESTA_SEMANA;
            case diffDias === 6:
                return OpcoesPersonalizadasEnum.ULTIMOS_7_DIAS;
            case diffDias === 9:
                return OpcoesPersonalizadasEnum.ULTIMOS_10_DIAS;
            case inicio.isSame(moment().startOf('month'), 'day'):
                return OpcoesPersonalizadasEnum.ESTE_MES;
            case diffDias === 29:
                return OpcoesPersonalizadasEnum.ULTIMOS_30_DIAS;
            case diffDias >= 89 && diffDias <= 92:
                return OpcoesPersonalizadasEnum.ULTIMOS_3_MESES;
            case inicio.isSame(moment().startOf('year'), 'day'):
                return OpcoesPersonalizadasEnum.ESTE_ANO;
            case inicio.isSame(moment().subtract(1, 'year').startOf('year'), 'day'):
                return OpcoesPersonalizadasEnum.ANO_PASSADO;
            default:
                return OpcoesPersonalizadasEnum.ULTIMOS_7_DIAS;
        }
    }
}
