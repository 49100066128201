import { CookieService } from "app/core/services";

export function obterIdCliente(cookieService: CookieService): string {
    const valoresSalvosFiltro = localStorage.getItem('filtro');
    const valoresFormularioFiltro = valoresSalvosFiltro ? JSON.parse(valoresSalvosFiltro) : null;
    const clienteSelecionado = cookieService.obterCookie('clienteId');
    const unidadesLocalStorage = localStorage.getItem('unidades') ? JSON.parse(localStorage.getItem('unidades')!) : [];

    const idClienteFiltro = unidadesLocalStorage.find(
        (cliente: { id: string }) => cliente.id === valoresFormularioFiltro?.cliente
    );

    return valoresFormularioFiltro ? idClienteFiltro?.id ?? clienteSelecionado : clienteSelecionado;
}
