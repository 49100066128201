<div class="componente-modal w-full">
    <div class="d-flex mb-5 modal-botao-fechar">
        <mat-icon id="icon-close" (click)="dialogRef.close()">close</mat-icon>
    </div>

    <div class="d-flex text-center pb-lg-0 mt-2 modal-titulo">
        <p class="text-sm sm:text-2xl">
            {{ "MODAL_HISTORICO_ALTERACOES_PROGRAMA_COMPONENT.HISTORICO_ALTERACOES_PROGRAMA" | transloco }}
        </p>
    </div>

    <div
        class="componente-tabela-modal mb-5 mt-5 overflow-y-auto overflow-x-hidden max-h-100"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScroll()"
    >
        <ng-container *ngIf="!carregando; else carregandoTabela">
            <table mat-table [dataSource]="historico">
                <ng-container matColumnDef="data">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto w-[140px]">{{ "DATA_HORA" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        <span class="m-auto w-[140px]">
                            {{ (element.instante | date : "dd/MM/yyyy HH:mm") || "-" }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="programa">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto w-[160px]"> {{ "PROGRAMA" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        <span class="m-auto w-[160px]">
                            {{ element.programa_aeracao ? element.programa_aeracao?.nome : "-" }}
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="detalhes">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto w-[480px]">{{ "DETALHES" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        <ng-container *ngIf="element.programa_aeracao.regras.length > 0">
                            <div
                                class="flex flex-col border-b-[0.4px] border-[#f4f6f9] m-auto"
                                *ngFor="let regras of element.programa_aeracao.regras"
                            >
                                <span
                                    class="flex justify-start w-[480px] p-0 h-auto text-xs sm:text-base wl-cor-primaria font-normal"
                                    >{{ regras.nome || "-" }}</span
                                >
                                <span
                                    class="flex justify-start text-left w-[480px] p-0 h-auto text-xs sm:text-base text-[#030229] font-normal"
                                    >{{
                                        formatarFormulaApenasParaVisualizacao(
                                            regras.expressao_ligar,
                                            regras.expressao_manter_ligado
                                        ) || "-"
                                    }}</span
                                >
                            </div>
                        </ng-container>
                        <ng-container *ngIf="element.programa_aeracao.length === 0">-</ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="usuario">
                    <th mat-header-cell *matHeaderCellDef class="text-xs sm:text-base">
                        <span class="m-auto w-[160px]">{{ "USUARIO" | transloco }}</span>
                    </th>
                    <td mat-cell *matCellDef="let element" class="text-center text-xs sm:text-base">
                        <span class="m-auto w-[160px]">
                            {{ element.usuario ? element.usuario?.nome + " " + element.usuario.sobrenome : "-" }}
                        </span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="tabelaColunas"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: tabelaColunas"></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="carregandoDadosAdicionais">
            <div class="h-[100px] flex">
                <span class="m-auto flex">
                    <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
                </span>
            </div>
        </ng-container>
    </div>
</div>

<ng-template #carregandoTabela>
    <div class="h-[200px] flex">
        <span class="m-auto flex">
            <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"> </mat-progress-spinner>
        </span>
    </div>
</ng-template>

<ng-template [ngIf]="!carregando && historico.length == 0">
    <div class="flex">
        <span class="m-auto flex">
            {{ "NENHUM_REGISTRO_ENCONTRADO" | transloco }}
        </span>
    </div>
</ng-template>
