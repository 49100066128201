import { Injectable } from '@angular/core';
import { IApiTransportInterface } from 'app/core/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { AmostragemInformacoesEstruturaArmazenagemInterface, ResponseInterface } from 'app/shared/interfaces';
import { Observable } from 'rxjs';

export abstract class IAmostragemEstruturaArmazenagemController {
    abstract buscaAmostragens(
        id: string
    ): Observable<ResponseInterface<AmostragemInformacoesEstruturaArmazenagemInterface>>;
}
@Injectable({
    providedIn: 'root',
})
export class AmostragemEstruturaArmazenagemController implements IAmostragemEstruturaArmazenagemController {
    private transport: IApiTransportInterface;

    constructor(public readonly apiTransportService: ApiTransportService) {
        this.transport = this.apiTransportService.execute('/armazenagem/visao-detalhada');
    }

    buscaAmostragens(
        IdEstruturaArmazenagem: string
    ): Observable<ResponseInterface<AmostragemInformacoesEstruturaArmazenagemInterface>> {
        return this.transport.http.get<ResponseInterface<AmostragemInformacoesEstruturaArmazenagemInterface>>(
            `${this.transport.path}/estrutura-armazenagem/${IdEstruturaArmazenagem}/amostragem`
        );
    }
}
