import { Injectable } from '@angular/core';

import { finalize, Observable } from 'rxjs';

import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces';
import { AtendimentoInterface } from 'app/shared/interfaces';

import {
    CategoriaAtendimentoInterface,
    PayloadCheckOutInterface,
} from 'app/layout/common/check-in-check-out/interfaces';
import { CheckInCheckOutObservable } from 'app/shared/observables';
export abstract class ICheckInCheckOutController {
    abstract buscarCategoriasAtendimento(): Observable<CategoriaAtendimentoInterface>;
    abstract iniciarNovoAtendimento(): Observable<AtendimentoInterface>;
    abstract encerrarAtendimento(
        atendimentoID: string,
        payload: PayloadCheckOutInterface
    ): Observable<AtendimentoInterface>;
    abstract pausarAtendimento(atendimentoID: string): Observable<AtendimentoInterface>;
    abstract retomarAtendimento(atendimentoID: string): Observable<AtendimentoInterface>;
    abstract reiniciarAtendimento(atendimentoID: string, motivo: string): Observable<AtendimentoInterface>;
}
@Injectable({
    providedIn: 'root',
})
export class CheckInCheckOutController implements ICheckInCheckOutController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private checkInCheckOutObservable: CheckInCheckOutObservable
    ) {
        this.transport = this.apiTransportService.execute('/');
    }

    buscarCategoriasAtendimento(): Observable<CategoriaAtendimentoInterface> {
        return this.transport.http.get<CategoriaAtendimentoInterface>(
            `${this.transport.path}base/categoria/atendimento`
        );
    }

    iniciarNovoAtendimento(): Observable<AtendimentoInterface> {
        this.checkInCheckOutObservable.setCarregandoDados = true;
        return this.transport.http.post<AtendimentoInterface>(`${this.transport.path}geral/atendimento`, {}).pipe(
            finalize(() => {
                this.checkInCheckOutObservable.setCarregandoDados = false;
            })
        );
    }

    encerrarAtendimento(atendimentoID: string, payload: any): Observable<AtendimentoInterface> {
        return this.transport.http.put<AtendimentoInterface>(
            `${this.transport.path}geral/atendimento/${atendimentoID}/encerrar-atendimento`,
            payload
        );
    }

    pausarAtendimento(atendimentoID: string): Observable<AtendimentoInterface> {
        return this.transport.http.put<AtendimentoInterface>(
            `${this.transport.path}geral/atendimento/${atendimentoID}/pausar-atendimento`,
            {}
        );
    }

    retomarAtendimento(atendimentoID: string): Observable<AtendimentoInterface> {
        return this.transport.http.put<AtendimentoInterface>(
            `${this.transport.path}geral/atendimento/${atendimentoID}/retomar-atendimento`,
            {}
        );
    }

    reiniciarAtendimento(atendimentoID: string, motivo: string): Observable<AtendimentoInterface> {
        return this.transport.http.put<AtendimentoInterface>(
            `${this.transport.path}geral/atendimento/${atendimentoID}/reiniciar-atendimento`,
            { motivo }
        );
    }
}
