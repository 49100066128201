import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { DataUltimaComunicacaoModule } from 'app/shared/components/data-ultima-comunicacao/data-ultima-comunicacao.module';
import { SensorCo2InformacoesGeraisArmazemComponent } from 'app/shared/components/sensor-co2-informacoes-gerais-armazem/sensor-co2-informacoes-gerais-armazem.component';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule, DataUltimaComunicacaoModule],
    declarations: [SensorCo2InformacoesGeraisArmazemComponent],
    exports: [SensorCo2InformacoesGeraisArmazemComponent],
})
export class SensorCo2InformacoesGeraisArmazemModule {}
