<div class="flex flex-row items-center">
	<div class="flex flex-row items-center mr-0.5">
		<span
			class="circle mr-1"
			[ngClass]="{
                    'com-execucao': dataSincronizador?.comunicacao_local_com_nuvem?.status,
                    'sem-execucao': !dataSincronizador?.comunicacao_local_com_nuvem?.status
                }"
		></span>
		<span class="font-normal text-sm">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.LOCAL" | transloco }}/</span>
		<span class="font-normal text-sm">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.NUVEM" | transloco }}</span>
		<mat-icon
			(click)="mostrarHistorico(tipoSincronizadorEnum.NuvemLocal)"
			class="cursor-pointer pr-2"
			#tooltip="matTooltip"
			[matTooltip]="'Momentos da última sincronização' + '\n' + 'de dados entre o sistema local e o ' + '\n' + 'sistema em nuvem'"
			matTooltipClass="tooltip-padrao"
			matTooltipPosition="above"
		>
			arrow_drop_down
		</mat-icon>
	</div>
	<div class="flex flex-row items-center ml-0.5">
		<span
			class="circle mr-1"
			[ngClass]="{
                'com-execucao': dataSincronizador?.coletor_comunicacao?.status,
                'sem-execucao': !dataSincronizador?.coletor_comunicacao?.status
            }"
		></span>
		<span class="font-normal text-sm">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.COLETOR" | transloco }}/</span>
		<span class="font-normal text-sm">{{ "ULTIMA_SINCRONIZACAO_COMPONENT.SINCRONIZADOR" | transloco }}</span>
		<mat-icon
			(click)="mostrarHistorico(tipoSincronizadorEnum.ColetorSincronizador)"
			class="cursor-pointer pr-2"
			#tooltip="matTooltip"
			[matTooltip]="'Momentos da última sincronização' + '\n' + 'de dados entre o coletor de dados e' + '\n' + 'o equipamento'"
			matTooltipClass="tooltip-padrao"
			matTooltipPosition="above"
		>
			arrow_drop_down
		</mat-icon>
	</div>
</div>
