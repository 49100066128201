export enum NomesAeracaoGraos {
    AERACAO = 'Aeração',
    AUTOMATICO_MAIUSCULO = 'Automatico',
    AUTOMATICO_MAIUSCULO_CORRGIDO = 'Automática',
    AUTOMATICO = 'automatico',
    AUTOMATICO_CORRIGIDO_MINUSCULO = 'automática',
    RESFRIAMENTO_E_CONSERVACAO = 'resfriamento e conservacao',
    RESFRIAMENTO_E_CONSERVACAO_MAIUSCULO = 'Resfriamento e conservacao',
    RESFRIAMENTO_E_CONSERVACAO_BACK = 'resfriamento_e_conservacao',
    RESFRIAMENTO_E_CONSERVACAO_CORRIGIDO = 'resfriamento e conservação',
    RESFRIAMENTO_E_CONSERVACAO_MAIUSCULO_CORRIGIDO = 'Resfriamento e conservação',
    CONTROLE_MANUAL_DO_SISTEMA = 'Controle do sistema em manual',
    CONSERVACAO = 'conservacao',
    CONSERVACAO_MAIUSCULO = 'Conservacao',
    CONSERVACAO_CORRIGIDO = 'Conservação',
    SEM_ESPACO_COMUNICACAO = 'Sem comunicação',
    SEM_COMUNICACAO = 'Sem_comunicacao',
    AERACAO_MANUAL = 'aeracao manual',
    RESFRIAMENTO = 'resfriamento',
    SECAGEM = 'secagem',
    CORRETIVA = 'corretiva',
    MANUAL = 'manual',
}
