import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

import { TooltipAeradorComponent } from 'app/shared/components/listagem-aeradores/tooltip-aerador';
import { ListagemAeradoresArmazemComponent } from 'app/shared/components/listagem-aeradores/listagem-aeradores-armazem.component';
import { StatusAeracaoArmazemModule } from 'app/shared/components/status-aeracao-armazem/status-aeracao-armazem.module';
import { MatTableModule } from '@angular/material/table';
import { StatusOnOffArmazemModule } from 'app/shared/components/status-on-off-armazem/status-on-off-armazem.module';
import { StatusAutomaticoManualArmazemModule } from 'app/shared/components/status-automatico-manual-armazem/status-automatico-manual-armazem.module';

@NgModule({
    declarations: [TooltipAeradorComponent, ListagemAeradoresArmazemComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        MatTableModule,
        TranslocoModule,
        StatusAeracaoArmazemModule,
        StatusOnOffArmazemModule,
        StatusAutomaticoManualArmazemModule,
    ],
    exports: [ListagemAeradoresArmazemComponent],
})
export class ListagemAeradoresArmazemModule {}
