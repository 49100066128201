import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { CookieService } from 'app/core/services/cookies/cookie.service';
import { GrupoClientesModel, UnidadeModel } from 'app/layout/common/header-select-customer/models';
import { redirecionamentoRotas } from 'app/shared/constants';
import { IClientesController, IListagemArmazenagemController, IResumoUnidadeController } from 'app/shared/controllers';
import { EstadoNavegacaoUnidadesEnum } from 'app/shared/enums';
import { FormataDataHelper, naoNuloOuIndefinido } from 'app/shared/helpers';
import { InfoInterface } from 'app/shared/interfaces';
import {
    AutenticacaoUsuarioObservable,
    CheckInCheckOutObservable,
    SincronizadorEquipamentoObservable,
    UnidadeParametrizacaoObservable,
} from 'app/shared/observables';
import { LoginAutomaticoService, StatusRequisicaoHttpService } from 'app/shared/services';
import { firstValueFrom, ReplaySubject, Subject, take, takeUntil } from 'rxjs';

@Component({
    selector: 'app-header-select-customer',
    templateUrl: './header-select-customer.component.html',
    styleUrls: ['./header-select-customer.component.scss'],
})
export class HeaderSelectCustomerComponent implements OnInit, OnDestroy {
    @ViewChild('singleSelect') singleSelect: MatSelect;

    @Output() trocaUnidade = new EventEmitter();

    clientesFiltrados: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    unidadeFiltroCtrl: UntypedFormControl = new UntypedFormControl();
    unidadeCtrl: UntypedFormControl = new UntypedFormControl();

    clientes: GrupoClientesModel[] = [];
    carregandoUndiades: boolean = true;
    voltarPrimeiraUnidadeBtn: boolean;
    idUnidadeSelecionada: string = '';
    statusRequisicoes: boolean = true;
    avancarUltimaUnidadeBtn: boolean;
    buscandoUnidade: boolean = false;
    selectAberto: boolean = false;
    idSelecionado: string = '';
    avancarUnidadeBtn: boolean;
    voltarUnidadeBtn: boolean;

    redirecionamentoRota = redirecionamentoRotas;

    naoNuloOuIndefinido = naoNuloOuIndefinido;

    protected onDestroy = new Subject<void>();

    constructor(
        private sincronizadorEquipamentoObservable: SincronizadorEquipamentoObservable,
        private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable,
        private listagemArmazenagemController: IListagemArmazenagemController,
        private autenticacaoUsuarioObservable: AutenticacaoUsuarioObservable,
        private statusRequisicaoHttpService: StatusRequisicaoHttpService,
        private checkInCheckOutObservable: CheckInCheckOutObservable,
        private resumoUnidadeController: IResumoUnidadeController,
        private clientesController: IClientesController,
        private loginAutomatico: LoginAutomaticoService,
        private cookieService: CookieService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.buscarClientesEUnidadesPorUsuario();
        this.unidadeFiltroCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(() => {
            this.filtrarUnidades();
        });

        this.verificarCookie();
        this.verificarRequisicoesPendentes();
    }

    async buscarClientesEUnidadesPorUsuario(): Promise<void> {
        const clientes = JSON.parse(localStorage.getItem('unidades'));

        if (clientes) {
            this.setaClienteJaCarregado(clientes);
            this.atualizarInformacoesUsuarioLogado();
            return;
        } else {
            this.atualizarInformacoesUsuarioLogado();

            const todosClientes = await firstValueFrom(
                this.clientesController.buscarClientesEUnidadesPorUsuario().pipe(take(1))
            );

            const clientesComUnidades = todosClientes?.filter((cliente) => cliente.unidades.length);

            if (clientesComUnidades) {
                localStorage.setItem('unidades', JSON.stringify(clientesComUnidades));
            }

            await this.setaClienteJaCarregado(clientesComUnidades);
        }
    }

    setaClienteJaCarregado(clientesComUnidades: GrupoClientesModel[]): void {
        clientesComUnidades?.forEach((unidade, index) => {
            unidade.unidades.forEach((item) => {
                item.indexGrupo = index;
            });
        });

        this.clientes = clientesComUnidades;

        this.setarValorInicial();

        this.carregandoUndiades = false;
    }

    verificarRequisicoesPendentes(): void {
        this.statusRequisicaoHttpService.carregandoRequisicoes
            .subscribe((response) => {
                this.statusRequisicoes = response;

                if (this.statusRequisicoes) {
                    this.desabilitarBotoes();
                } else if (!this.statusRequisicoes && !this.checkInCheckOutObservable.getPassadorUnidadesBloqueado) {
                    this.habilitarBotoes();
                }
            })
            .add(() => {
                this.iniciarObservadorPassadorUnidadesBloqueado();
            });
    }

    iniciarObservadorPassadorUnidadesBloqueado(): void {
        this.checkInCheckOutObservable.passadorUnidadesBloqueado$.subscribe((response) => {
            if (response) {
                this.desabilitarBotoes();
            } else {
                this.habilitarBotoes();
            }
        });
    }

    focoSelectUnidade(valor: boolean): void {
        if (this.statusRequisicoes || this.checkInCheckOutObservable.getPassadorUnidadesBloqueado) {
            this.selectAberto = false;
            this.buscandoUnidade = false;
            return;
        }
        this.selectAberto = valor;
        this.buscandoUnidade = valor;
    }

    setarValorInicial(): void {
        const clienteIdLogin = this.cookieService.obterCookie('clienteId');
        const unidadeIdLogin = this.cookieService.obterCookie('unidadeId');
        const indexClienteLogin = this.clientes.findIndex((cliente) => cliente.id === clienteIdLogin);
        const indexUnidadeLogin = this.clientes[indexClienteLogin].unidades.findIndex(
            (unidade) => unidade.id === unidadeIdLogin
        );

        let indexUnidadeAtual = 0;

        if (
            JSON.parse(localStorage.getItem('unidadeAtual')) === null ||
            JSON.parse(localStorage.getItem('unidadeAtual')) === undefined
        ) {
            this.unidadeCtrl.setValue(this.clientes[indexClienteLogin ?? 0].unidades[indexUnidadeLogin ?? 0]);

            localStorage.setItem(
                'unidadeAtual',
                JSON.stringify(this.clientes[indexClienteLogin ?? 0].unidades[indexUnidadeLogin ?? 0])
            );
        } else {
            const unidadeAtual = JSON.parse(localStorage.getItem('unidadeAtual'));

            indexUnidadeAtual = this.clientes[unidadeAtual.indexGrupo].unidades.findIndex(
                (unidade) => unidade.id === unidadeAtual.id
            );

            this.unidadeCtrl.setValue(this.clientes[unidadeAtual.indexGrupo].unidades[indexUnidadeAtual ?? 0]);
        }

        this.clientesFiltrados.next(this.clientes.slice());
        const unidadeAtual = JSON.parse(localStorage.getItem('unidadeAtual'));

        this.configurarSetasNavegacao(
            unidadeAtual.indexGrupo,
            this.clientes.length,
            indexUnidadeAtual,
            this.clientes[unidadeAtual.indexGrupo].unidades?.length
        );
    }

    opcaoSelecionadaNoSelect(evento): void {
        this.redirecionamentoAlteracaoUnidade();

        localStorage.setItem('unidadeAtual', JSON.stringify(evento.value));

        this.idUnidadeSelecionada = evento.value.id;
        this.idSelecionado = this.clientes[evento.value.indexGrupo].id;

        this.atualizaClienteId(this.idSelecionado);
        this.atualizaUnidadeId(this.idUnidadeSelecionada);

        this.atualizarResumoUnidade();
        this.atualizaListaSimplesEstruturaArmazenagem();

        this.configurarSetasNavegacao(
            evento.value.indexGrupo,
            this.clientes.length,
            this.retornaIndexUnidade(this.idUnidadeSelecionada),
            this.clientes[evento.value.indexGrupo].unidades.length
        );
    }

    mostrarClienteSelecionado(grupo: GrupoClientesModel, unidade: UnidadeModel): string {
        if (this.selectAberto) {
            return naoNuloOuIndefinido(unidade.nome)
                ? '(' + unidade.nome + ')' + ' ' + unidade.cidade.nome + ' - ' + unidade.cidade.uf.sigla
                : unidade.cidade.nome + ' - ' + unidade.cidade.uf.sigla;
        }
        return naoNuloOuIndefinido(unidade.nome)
            ? grupo.nome + ': ' + '(' + unidade.nome + ')' + ' ' + unidade.cidade.nome + ' - ' + unidade.cidade.uf.sigla
            : grupo.nome + ': ' + unidade.cidade.nome + ' - ' + unidade.cidade.uf.sigla;
    }

    verificarSelectAberto(evento: boolean): void {
        this.selectAberto = evento;
    }

    retornaIndexUnidade(id: string): number {
        let index = null;
        this.clientes.forEach((filial: any) => {
            filial.unidades.forEach((elemento, posicao) => {
                if (id === elemento.id) {
                    index = posicao;
                }
            });
        });
        return index;
    }

    voltarUnidade(): void {
        this.redirecionamentoAlteracaoUnidade();

        let posicaoAtualGrupo = this.unidadeCtrl.value.indexGrupo;
        let lengthUnidadesGrupoAtual = this.clientes[posicaoAtualGrupo].unidades.length;
        let unidadeAnterior = null;

        const posicaoUnidadeAtual = this.retornaIndexUnidade(this.unidadeCtrl.value.id);

        if (posicaoUnidadeAtual > 0) {
            unidadeAnterior = this.clientes[posicaoAtualGrupo].unidades[posicaoUnidadeAtual - 1];

            this.unidadeCtrl.setValue(unidadeAnterior);
            this.atualizaClienteId(this.clientes[posicaoAtualGrupo].id);
            this.atualizaUnidadeId(unidadeAnterior.id);
            localStorage.setItem('unidadeAtual', JSON.stringify(unidadeAnterior));
        } else {
            posicaoAtualGrupo = posicaoAtualGrupo - 1;
            if (posicaoAtualGrupo < 0) {
                posicaoAtualGrupo = 0;
            }
            lengthUnidadesGrupoAtual = this.clientes[posicaoAtualGrupo].unidades.length;

            unidadeAnterior = this.clientes[posicaoAtualGrupo]?.unidades[lengthUnidadesGrupoAtual - 1];

            this.unidadeCtrl.setValue(unidadeAnterior);
            this.atualizaClienteId(this.clientes[posicaoAtualGrupo].id);
            this.atualizaUnidadeId(unidadeAnterior.id);
            localStorage.setItem('unidadeAtual', JSON.stringify(unidadeAnterior));
        }

        this.atualizarResumoUnidade();
        this.atualizaListaSimplesEstruturaArmazenagem();

        this.configurarSetasNavegacao(
            posicaoAtualGrupo,
            this.clientes.length,
            this.retornaIndexUnidade(unidadeAnterior.id),
            this.clientes[posicaoAtualGrupo].unidades.length,
            EstadoNavegacaoUnidadesEnum.ANTERIOR
        );
    }

    voltarPrimeiraUnidade(): void {
        this.redirecionamentoAlteracaoUnidade();

        const primeiraUnidade = this.clientes[0].unidades[0];
        this.unidadeCtrl.setValue(primeiraUnidade);
        this.atualizaClienteId(this.clientes[0].id);
        this.atualizaUnidadeId(primeiraUnidade.id);
        localStorage.setItem('unidadeAtual', JSON.stringify(primeiraUnidade));

        this.atualizarResumoUnidade();
        this.atualizaListaSimplesEstruturaArmazenagem();

        this.configurarSetasNavegacao(0, this.clientes.length, 0, this.clientes[0].unidades.length);
    }

    avancarUnidade(): void {
        this.redirecionamentoAlteracaoUnidade();

        let posicaoAtualGrupo = this.unidadeCtrl.value.indexGrupo;
        let lengthUnidadesGrupoAtual = this.clientes[posicaoAtualGrupo].unidades.length;
        let proximaUnidade = null;

        const posicaoUnidadeAtual = this.retornaIndexUnidade(this.unidadeCtrl.value.id);

        if (posicaoUnidadeAtual < lengthUnidadesGrupoAtual - 1) {
            proximaUnidade = this.clientes[posicaoAtualGrupo].unidades[posicaoUnidadeAtual + 1];

            this.unidadeCtrl.setValue(proximaUnidade);
            this.atualizaClienteId(this.clientes[posicaoAtualGrupo].id);
            this.atualizaUnidadeId(proximaUnidade.id);
            localStorage.setItem('unidadeAtual', JSON.stringify(proximaUnidade));
        } else {
            posicaoAtualGrupo = posicaoAtualGrupo + 1;
            if (posicaoAtualGrupo >= this.clientes.length) {
                posicaoAtualGrupo = this.clientes.length - 1;
            }

            proximaUnidade = this.clientes[posicaoAtualGrupo].unidades[0];

            this.unidadeCtrl.setValue(proximaUnidade);
            this.atualizaClienteId(this.clientes[posicaoAtualGrupo].id);
            this.atualizaUnidadeId(proximaUnidade.id);
            localStorage.setItem('unidadeAtual', JSON.stringify(proximaUnidade));
        }

        this.atualizarResumoUnidade();
        this.atualizaListaSimplesEstruturaArmazenagem();

        this.configurarSetasNavegacao(
            posicaoAtualGrupo,
            this.clientes.length,
            this.retornaIndexUnidade(proximaUnidade.id),
            this.clientes[posicaoAtualGrupo].unidades.length,
            EstadoNavegacaoUnidadesEnum.PROXIMO
        );
    }

    avancarUltimaUnidade(): void {
        this.redirecionamentoAlteracaoUnidade();

        const ultimoGrupo = this.clientes[this.clientes.length - 1];
        const ultimaUnidade = ultimoGrupo.unidades[ultimoGrupo.unidades.length - 1];

        this.unidadeCtrl.setValue(ultimaUnidade);
        this.atualizaClienteId(ultimoGrupo.id);
        this.atualizaUnidadeId(ultimaUnidade.id);
        localStorage.setItem('unidadeAtual', JSON.stringify(ultimaUnidade));

        this.atualizarResumoUnidade();
        this.atualizaListaSimplesEstruturaArmazenagem();

        this.configurarSetasNavegacao(
            this.clientes.length - 1,
            this.clientes.length,
            ultimoGrupo.unidades.length - 1,
            ultimoGrupo.unidades.length
        );
    }

    atualizaClienteId(id: string): void {
        this.cookieService.definirCookie('id', id);
        this.unidadeParametrizacaoObservable.setParametrosUnidade = null;
    }

    atualizaUnidadeId(id: string): void {
        this.cookieService.definirCookie('unidadeId', id);
        this.atualizaInformacoesGlobaisDaUnidade();
        this.trocaUnidade.emit(true);
    }

    atualizaInformacoesGlobaisDaUnidade(): void {
        this.atualizarInformacoesUsuarioLogado();

        this.checkInCheckOutObservable.setAtendimento = null;

        this.resumoUnidadeController
            .buscaResumoUnidade()
            .pipe(take(1))
            .subscribe((response) => {
                this.unidadeParametrizacaoObservable.setParametrosUnidade = {
                    ...response,
                    unidade_parametrizacao: {
                        ...response.unidade_parametrizacao,
                        horario_pico_fim: FormataDataHelper.adicionaTimezoneHHMMss(
                            response.unidade_parametrizacao.horario_pico_fim
                        ),
                        horario_pico_inicio: FormataDataHelper.adicionaTimezoneHHMMss(
                            response.unidade_parametrizacao.horario_pico_inicio
                        ),
                    },
                };

                if (response.atendimento) {
                    this.checkInCheckOutObservable.setAtendimento = response.atendimento;
                } else {
                    this.checkInCheckOutObservable.setAtendimento = null;
                }
            });
        this.sincronizadorEquipamentoObservable.setSincronizador = null;
    }

    configurarSetasNavegacao(
        indexGrupo: number,
        lengthGrupo: number,
        indexUnidade?: number,
        lengthUnidades?: number,
        estado?: string
    ) {
        let primeiroGrupo = indexGrupo === 0;
        let ultimoGrupo = indexGrupo === lengthGrupo - 1;
        let primeiroUnidade = indexUnidade === 0;
        let ultimoUnidade = indexUnidade === lengthUnidades - 1;

        if (estado === undefined) {
            this.voltarUnidadeBtn = this.voltarPrimeiraUnidadeBtn = primeiroUnidade && primeiroGrupo;
            this.avancarUnidadeBtn = this.avancarUltimaUnidadeBtn = ultimoUnidade && ultimoGrupo;
        } else if (estado === EstadoNavegacaoUnidadesEnum.PRIMEIRO) {
            this.voltarUnidadeBtn = this.voltarPrimeiraUnidadeBtn = true;
            this.avancarUnidadeBtn = this.avancarUltimaUnidadeBtn = false;
        } else if (estado === EstadoNavegacaoUnidadesEnum.ULTIMO) {
            this.voltarUnidadeBtn = this.voltarPrimeiraUnidadeBtn = false;
            this.avancarUnidadeBtn = this.avancarUltimaUnidadeBtn = true;
        } else if (estado === EstadoNavegacaoUnidadesEnum.PROXIMO || estado === EstadoNavegacaoUnidadesEnum.ANTERIOR) {
            this.voltarUnidadeBtn = this.voltarPrimeiraUnidadeBtn = primeiroUnidade && primeiroGrupo;
            this.avancarUnidadeBtn = this.avancarUltimaUnidadeBtn = ultimoUnidade && ultimoGrupo;
        }
    }

    private atualizarResumoUnidade(): void {
        this.resumoUnidadeController.atualizarResumoUnidade().subscribe({
            next: (response) => {
                if (response.atendimento) {
                    this.checkInCheckOutObservable.setAtendimento = response.atendimento;
                } else {
                    this.checkInCheckOutObservable.setAtendimento = null;
                }
            },
            error: (error) => {
                console.error(error);
            },
        });
    }

    private desabilitarBotoes(): void {
        this.voltarPrimeiraUnidadeBtn = true;
        this.voltarUnidadeBtn = true;
        this.avancarUnidadeBtn = true;
        this.avancarUltimaUnidadeBtn = true;
        this.unidadeFiltroCtrl.disable();
        this.unidadeCtrl.disable();
    }

    private habilitarBotoes(): void {
        const unidadeAtual = JSON.parse(localStorage.getItem('unidadeAtual'));

        const indexUnidadeAtual = this.retornaIndexUnidade(unidadeAtual?.id);

        this.unidadeFiltroCtrl.enable();
        this.unidadeCtrl.enable();

        this.configurarSetasNavegacao(
            unidadeAtual?.indexGrupo,
            this?.clientes?.length,
            indexUnidadeAtual,
            this?.clientes[unidadeAtual?.indexGrupo]?.unidades?.length
        );
    }

    private redirecionamentoAlteracaoUnidade(): void {
        for (let rota in this.redirecionamentoRota) {
            if (window.location.pathname.includes(rota)) {
                this.cookieService.definirCookie('alteracaoRotaUnidade', 'true');
            }
        }
    }

    private atualizarInformacoesUsuarioLogado(): void {
        this.resumoUnidadeController.buscaResumoUnidade().subscribe((response) => {
            this.autenticacaoUsuarioObservable.setInformacoesAutenticacaoUsuario = response?.usuario;
        });
    }

    protected filtrarUnidades(): void {
        if (!this.clientes) {
            return;
        }
        let buscar = this.unidadeFiltroCtrl.value;
        if (!buscar) {
            this.clientesFiltrados.next(this.clientes.slice());
            return;
        } else {
            buscar = buscar.toLowerCase();
        }
        const grupoClientesCopia = this.copiarGrupoUnidades(this.clientes);
        const unidadesEncontradas = this.filtrarUnidadePorNomeOuCliente(grupoClientesCopia, buscar);

        this.clientesFiltrados.next(unidadesEncontradas);
    }

    filtrarUnidadePorNomeOuCliente(grupoClientesCopia: GrupoClientesModel[], buscar: string): GrupoClientesModel[] {
        const unidadesEncontradas = grupoClientesCopia.filter((cliente: GrupoClientesModel) => {
            const mostrarGrupoUnidades = cliente.nome.toLowerCase().indexOf(buscar) > -1;
            if (!mostrarGrupoUnidades) {
                cliente.unidades = cliente.unidades.filter(
                    (unidade: UnidadeModel) => unidade.nome.toLowerCase().indexOf(buscar) > -1
                );
            }

            return cliente.unidades.length > 0;
        });
        return unidadesEncontradas;
    }

    private atualizaListaSimplesEstruturaArmazenagem(): void {
        this.listagemArmazenagemController.buscarEstruturasArmazenagem().subscribe();
    }

    private copiarGrupoUnidades(clientesGrupo: GrupoClientesModel[]): GrupoClientesModel[] {
        const clientesGrupoCopia = [];
        clientesGrupo.forEach((cliente) => {
            clientesGrupoCopia.push({
                nome: cliente.nome,
                unidades: cliente.unidades.slice(),
            });
        });
        return clientesGrupoCopia;
    }

    private verificarCookie(): void {
        const cookie = this.cookieService.obterCookie('info');

        if (cookie) {
            try {
                const decodedCookie = decodeURIComponent(cookie);
                const info: InfoInterface = JSON.parse(decodedCookie);

                this.loginAutomatico.iniciarConfiguracoes(info);

                this.clientes.forEach((cliente, index) => {
                    cliente.unidades.forEach((unidade) => {
                        if (unidade.id === info.unidade.id) {
                            this.unidadeCtrl.setValue(unidade);
                        }
                    });
                });

                this.cookieService.apagarCookie('info');
                this.loginAutomatico.redirecionarPagina(info);
            } catch (error) {
                console.error('Erro ao analisar o cookie:', error);
            }
        }
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
