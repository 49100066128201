<div class="text-[#0b0c0e] wl-cor-primaria flex mb-2">
    <mat-icon class="p-[2px] ml-[-3px]" [svgIcon]="'icon-silo-estoque'"></mat-icon>
    <span class="my-1 ml-2">{{ "ESTOQUE_INFORMACOES_GERAIS_COMPONENT.ESTOQUE" | transloco }}</span>
</div>
<div class="striped">
    <div *ngIf="!verificaObjetoVazio(sensorVolumetria)" class="flex flex-row">
        <div class="flex flex-col w-[60%]">
            <span class="p-2.5 flex items-center text-xs sm:text-base rounded">
                <ng-container
                    *ngIf="
                        sensorVolumetria?.tonelada?.med && estoque?.tonelada?.capacidade_estoque;
                        else semDadosLeitura
                    "
                >
                    {{ "ESTOQUE_INFORMACOES_GERAIS_COMPONENT.VOLUMETRIA" | transloco }} ({{
                        calculaPercentualEstoque(sensorVolumetria?.tonelada?.med, estoque?.tonelada?.capacidade_estoque)
                            | number : "1.1-1"
                    }}%)
                </ng-container>
                <ng-template #semDadosLeitura
                    >{{ "ESTOQUE_INFORMACOES_GERAIS_COMPONENT.VOLUMETRIA" | transloco }} (0,0%)</ng-template
                >

                <span>
                    <mat-icon
                        [svgIcon]="'icon-info'"
                        class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
                        (click)="abrirModalVolumetria()"
                    ></mat-icon>
                </span>
                <p
                    *ngIf="sensoresVolumeComFalha()"
                    class="flex items-center"
                    matTooltipPosition="above"
                    matTooltip="{{ 'ESTOQUE_INFORMACOES_GERAIS_COMPONENT.SENSOR_NIVEL_TRANSMISSOR' | transloco }}"
                    matTooltipClass="tooltip-padrao tooltip-amostragem"
                >
                    <mat-icon class="text-[15px] text-[#a44040] blink"> warning </mat-icon>
                </p>
            </span>
            <span class="px-2.5 flex items-center text-xs sm:text-base rounded">
                <app-data-ultima-comunicacao
                    [equipamentos]="formataArrayEquipamentosComponenteData(listaEquimentosSensoresVolumetria)"
                    [visualizarTempo]="true"
                    [tamanhoIconeStatus]="'icon-size-09'"
                    [ocultarModal]="ocultarModal"
                >
                </app-data-ultima-comunicacao>
            </span>
        </div>
        <div class="text-right flex flex-col w-[40%] items-end p-[10px]">
            <ng-container
                *ngIf="
                    sensorVolumetria?.tonelada?.med === null || sensorVolumetria?.saca?.med === null;
                    then semLeiuta;
                    else VolumetriaConfigurada
                "
            ></ng-container>

            <span
                class="progress-line mb-1"
                [ngClass]="
                    sensorVolumetria?.tonelada?.med === null || sensorVolumetria?.saca?.med === null ? '' : 'w-20'
                "
            >
                <span
                    class="line-bar"
                    [ngStyle]="{
                        'width.%': (sensorVolumetria?.tonelada?.med / estoque?.tonelada?.capacidade_estoque) * 100
                    }"
                ></span>
            </span>
        </div>
    </div>
    <div>
        <span class="p-2.5 flex justify-between items-center text-xs sm:text-base rounded">
            <span
                >{{ "ESTOQUE_INFORMACOES_GERAIS_COMPONENT.ESTOQUE_INFORMADO" | transloco }} ({{
                    calculaPercentualEstoque(
                        estoque?.tonelada?.estoque_informado,
                        estoque?.tonelada?.capacidade_estoque
                    ) | number : "1.1-1"
                }}%)</span
            >
            <div class="text-right">
                <ng-container
                    *ngIf="
                        estoque?.tonelada?.estoque_informado == null || estoque?.saca?.estoque_informado == null;
                        then naoConfigurado;
                        else EstoqueInformadoConfigurado
                    "
                ></ng-container>

                <span
                    class="progress-line mb-1"
                    [ngClass]="
                        estoque?.tonelada?.estoque_informado == null || estoque?.saca?.estoque_informado == null
                            ? ''
                            : 'w-20'
                    "
                >
                    <span
                        class="line-bar"
                        [ngStyle]="{
                            'width.%':
                                (estoque?.tonelada?.estoque_informado / estoque?.tonelada?.capacidade_estoque) * 100
                        }"
                    ></span>
                </span>
            </div>
        </span>
    </div>
    <div>
        <span class="p-2.5 flex justify-between items-center text-xs sm:text-base rounded">
            <span
                >{{ "ESTOQUE_INFORMACOES_GERAIS_COMPONENT.ESTOQUE_ESTIMADO" | transloco }} ({{
                    calculaPercentualEstoque(estoque?.tonelada?.estoque_estimado, estoque?.tonelada?.capacidade_estoque)
                        | number : "1.1-1"
                }}%)</span
            >
            <div class="text-right">
                <ng-container
                    *ngIf="
                        estoque?.tonelada?.estoque_estimado == null || estoque?.saca?.estoque_estimado == null;
                        then naoConfigurado;
                        else EstoqueEstimadoConfigurado
                    "
                ></ng-container>

                <span
                    class="progress-line mb-1"
                    [ngClass]="
                        estoque?.tonelada?.estoque_estimado == null || estoque?.saca?.estoque_estimado == null
                            ? ''
                            : 'w-20'
                    "
                >
                    <span
                        class="line-bar"
                        [ngStyle]="{
                            'width.%':
                                (estoque?.tonelada?.estoque_estimado / estoque?.tonelada?.capacidade_estoque) * 100
                        }"
                    ></span>
                </span>
            </div>
        </span>
    </div>
    <div class="p-2.5 flex justify-between items-center text-xs sm:text-base rounded">
        <div>{{ "ESTOQUE_INFORMACOES_GERAIS_COMPONENT.CAPACIDADE_TOTAL" | transloco }}</div>
        <div class="text-right">
            <span>
                {{
                    estoque?.tonelada?.capacidade_estoque != null
                        ? (estoque?.tonelada?.capacidade_estoque | number : "1.1-1") + "t/"
                        : ("ESTOQUE_INFORMACOES_GERAIS_COMPONENT.NAO_CONFIGURADO" | transloco)
                }}
                <br />
                {{
                    estoque?.saca?.capacidade_estoque != null
                        ? (estoque?.saca?.capacidade_estoque | number : "1.1-1") + "sc"
                        : ""
                }}
            </span>
        </div>
    </div>
</div>

<ng-template #naoConfigurado> {{ "ESTOQUE_INFORMACOES_GERAIS_COMPONENT.NAO_CONFIGURADO" | transloco }} </ng-template>

<ng-template #semLeiuta> {{ "ESTOQUE_INFORMACOES_GERAIS_COMPONENT.AGUARDANDO_LEITURA" | transloco }} </ng-template>

<ng-template #EstoqueInformadoConfigurado>
    {{ estoque?.tonelada?.estoque_informado | number : "1.1-1" }}t/
    <br />
    {{ estoque?.saca?.estoque_informado | number : "1.1-1" }}sc
</ng-template>

<ng-template #EstoqueEstimadoConfigurado>
    {{ estoque?.tonelada?.estoque_estimado | number : "1.1-1" }}t/
    <br />
    {{ estoque?.saca?.estoque_estimado | number : "1.1-1" }}sc
</ng-template>
<ng-template #VolumetriaConfigurada>
    {{ sensorVolumetria?.tonelada?.med ? (sensorVolumetria.tonelada?.med | number : "1.1-1") : "0,0" }}t/
    <br />
    {{ sensorVolumetria?.saca?.med ? (sensorVolumetria.saca?.med | number : "1.1-1") : "0,0" }}sc
</ng-template>
