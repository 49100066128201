<div class="componente-modal overflow-x-hidden">
    <div class="modal-titulo flex flex-col px-3 py-6">
        <div class="text-center">
            <p class="text-center wl-cor-primaria text-xl">
                {{ 'CHECK_IN_CHECK_OUT.DESEJA_REINICIAR_CHECK_IN' | transloco }}
            </p>
        </div>
        <form [formGroup]="formReiniciarCheckIn" class="flex flex-col">
            <div class="mb-5 mt-3">
                <span class="text-sm text-[#535767] mb-1">{{ 'CHECK_IN_CHECK_OUT.EXPLIQUE_MOTIVO' | transloco }}</span>
                <textarea
                    formControlName="motivo"
                    class="w-full p-1 min-w-[428px] border rounded text-sm text-[#535767] bg-gray-100"
                    rows="6"
                ></textarea>
            </div>
            <div class="flex justify-center mt-6">
                <button
                    type="button"
                    class="px-5 h-[40px] leading-5 text-md text-[#535767] border-[#535767] mr-4"
                    (click)="dialogRef.close()"
                >
                    {{ 'CANCELAR' | transloco }}
                </button>
                <button
                    type="button"
                    class="wl-bg-cor-secundaria border-none px-5 h-[40px] leading-5 text-md text-white ml-4 disabled:opacity-50 disabled:cursor-not-allowed"
                    [disabled]="formReiniciarCheckIn.invalid"
                    (click)="confirmar()"
                >
                    {{ 'CONFIRMAR' | transloco }}
                </button>
            </div>
        </form>
    </div>
</div>
