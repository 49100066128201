import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertasAeracaoArmazemComponent } from 'app/shared/components/alertas-aeracao-armazem/alertas-aeracao-armazem.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule],
    declarations: [AlertasAeracaoArmazemComponent],
    exports: [AlertasAeracaoArmazemComponent],
})
export class AlertasAeracaoArmazemModule {}
