export interface AmostragemInformacoesEstruturaArmazenagemInterface {
    id: string;
    estrutura_armazenagem_id: string;
    estrutura_armazenagem_divisao_id: string;
    nome_divisao?: string;
    data_amostra: string;
    umidade_controle: number;
    avariados_controle: number;
    impureza_controle: number;
    produto: {
        id: string;
        nome: string;
        produto_tipo: {
            nome: string;
            densidade: number;
        };
    };
}
