import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EstruturaArmazenagemDivisaoPendulo } from 'app/shared/interfaces';
import { AsideMenuObservable } from 'app/shared/observables';
import { Subject, takeUntil } from 'rxjs';

import {
    TooltipArmazemElementInterface,
    TooltipArmazemEventInterface,
} from 'app/shared/components/tooltip-armazem/interface/tooltip-armazem.interface';

@Component({
    selector: 'app-tooltip-armazem',
    templateUrl: './tooltip-armazem.component.html',
    styleUrls: ['./tooltip-armazem.component.scss'],
})
export class TooltipArmazemComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() pendulos: EstruturaArmazenagemDivisaoPendulo[];

    penduloAtual: EstruturaArmazenagemDivisaoPendulo;
    menuFechado: boolean = true;
    tooltipAnterior: number = 0;
    tempoFecharTooltip: any;

    private unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(private asideMenuObservable: AsideMenuObservable, private _changeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
       this.ordenaPendulos();

        this.asideMenuObservable.menuMinimizadoSubject.pipe(takeUntil(this.unsubscribeAll)).subscribe((hide) => {
            this.menuFechado = hide;
            this._changeDetectorRef.detectChanges();
        });
    }

    ngAfterViewInit(): void {
        if (!this.pendulos) {
            return;
        }

        document.getElementById('tooltipCustomPendulo').hidden = true;

        document.addEventListener('cableDetailsEvent', (event: TooltipArmazemEventInterface) => {
            clearTimeout(this.tempoFecharTooltip);
            this.tooltipCustomizado(event);
            this._changeDetectorRef.detectChanges();
        });
    }

    private ordenaPendulos(): void {
        if (this.pendulos) {
            this.pendulos.sort((a, b) => a?.pendulo?.codigo - b?.pendulo?.codigo);
        }
    }

    private configuraPosicaoTooltip(
        element: TooltipArmazemElementInterface,
        event: TooltipArmazemEventInterface
    ): void {
        let menuWidth = 90;

        if (!this.menuFechado) {
            menuWidth = 280;
        }

        const widthTooltip = element?.style.width.replace(/[^0-9]/g, '');
        const heightTooltip = element?.style.height.replace(/[^0-9]/g, '');
        element.style.left = event?.detail.pageX - parseFloat(widthTooltip) / 2 - menuWidth + 'px';
        element.style.top = event?.detail.pageY - parseFloat(heightTooltip) * 5 + 'px';
    }

    private tooltipCustomizado(event: TooltipArmazemEventInterface): void {
        const element = document.getElementById('tooltipCustomPendulo');

        if (element) {
            if (this.tooltipAnterior !== event.detail.id) {
                element.hidden = true;
                this.tooltipAnterior = event.detail.id;
            }

            this.penduloAtual = this.pendulos[event.detail.id - 1];
            this.configuraPosicaoTooltip(element, event);

            element.hidden = false;
            this.tempoFecharTooltip = setTimeout(() => {
                element.hidden = true;
                clearTimeout(this.tempoFecharTooltip);
            }, 3000);

            this._changeDetectorRef.markForCheck();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
}
