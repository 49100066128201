import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { configuracaoTamanhoModal } from 'app/shared/constants';
import { CategoriaEstruturaArmazenagemEnum, EstadoComunicacaoEnum, MostrarTooltipSiloEnum } from 'app/shared/enums';

import { EquipamentoInformacoesSiloInterface } from 'app/shared/interfaces';
import {
    IRetonaInstanteMaisRecenteEquipamentoService,
    IRetornaStatusComunicacaoService,
    RetornaEstiloStatusComunicacao,
} from 'app/shared/services';
import { ModalEquipamentosFullscreenComponent } from 'app/shared/components';

@Component({
    selector: 'app-data-ultima-comunicacao-armazem',
    templateUrl: './data-ultima-comunicacao-armazem.component.html',
    styleUrls: ['./data-ultima-comunicacao-armazem.component.scss'],
})
export class DataUltimaComunicacaoArmazemComponent implements OnChanges {
    @Input() equipamentos: EquipamentoInformacoesSiloInterface[];
    @Input() visualizarTempo: boolean;
    @Input() ocultarModal?: boolean | false;
    @Input() tamanhoIconeStatus?: string;
    @Input() dataEvento?: string;
    @Input() idTooltip?: string;
    @Input() totalEquipamento?: number;
    @Input() estruturaArmazenagem: { nome: string; categoria: CategoriaEstruturaArmazenagemEnum };
    @Input() apenasVisualizacao: boolean = false;

    classTooltip: string = 'tooltip-sem-comunicacao';
    traduzPagina = {
        traducaoComunicacao: '',
        traducaoFalhaComunicacao: '',
        traducaoComunicacaoParcial: '',
    };
    dataMaisRecente: string;
    mostrarTooltip: string = MostrarTooltipSiloEnum.HIDDEN;
    posicaoHorizontalTooltip: number;
    listaStatusComunicacao: boolean[] = [];
    listaInstantesComunicacao: string[] = [];
    modalEquipamentos: boolean = false;

    estadoComunicacaoEnum = EstadoComunicacaoEnum;

    constructor(
        readonly dialog: MatDialog,
        readonly retornaStatusComunicacaoService: IRetornaStatusComunicacaoService,
        private changeDetectorRef: ChangeDetectorRef,
        private retonaInstanteMaisRecenteEquipamentoService: IRetonaInstanteMaisRecenteEquipamentoService,
        private translocoService: TranslocoService
    ) {
        this.traduzPagina.traducaoFalhaComunicacao = this.translocoService.translate('FALHA_DE_COMUNICACAO');
        this.traduzPagina.traducaoComunicacao = this.translocoService.translate('COMUNICANDO');
        this.traduzPagina.traducaoComunicacaoParcial = this.translocoService.translate('COMUNICACAO_PARCIAL');
    }

    ngOnChanges(): void {
        this.listaStatusComunicacao = [];
        this.listaInstantesComunicacao = [];
        this.equipamentos?.forEach((equipamento) => {
            this.listaStatusComunicacao.push(equipamento.equipamento_comunicacao?.status);
            this.listaInstantesComunicacao.push(equipamento.equipamento_comunicacao?.instante);
        });
        this.dataMaisRecente = this.retonaInstanteMaisRecenteEquipamentoService.execute(this.listaInstantesComunicacao);
        this.changeDetectorRef.detectChanges();

        if (this.equipamentos?.length && !this.ocultarModal) {
            this.modalEquipamentos = true;
        }

        this.posicionarTooltip();
    }

    posicionarTooltip(): void {
        const alturaTooltipRate = 16;
        const alturaTooltipOffset = 69;
        const larguraTooltipOffset = 216;

        if (this.equipamentos?.length > 0) {
            this.totalEquipamento = this.equipamentos.length;
        }
        const posicaoVerticalTooltip = -(this.totalEquipamento * alturaTooltipRate + alturaTooltipOffset);
        const dataUltimaComunicacao = document.getElementById(`${this.idTooltip ?? 'dataUltimaComunicacao'}`);
        if (dataUltimaComunicacao) {
            this.posicaoHorizontalTooltip = dataUltimaComunicacao.clientWidth;
        }

        const tooltipSilo = document.getElementById(this.idTooltip ? this.idTooltip + 'tooltipSilo' : 'tooltipSilo');

        if (tooltipSilo) {
            this.posicaoHorizontalTooltip = this.posicaoHorizontalTooltip - larguraTooltipOffset;
            tooltipSilo.style.left = this.posicaoHorizontalTooltip.toString() + 'px';
            tooltipSilo.style.top = posicaoVerticalTooltip.toString() + 'px';
        }
    }

    statusComunicacao(): string {
        return RetornaEstiloStatusComunicacao.prototype.statusComunicacaoSilo(
            this.retornaStatusComunicacaoService.execute(this.listaStatusComunicacao),
            this.traduzPagina.traducaoComunicacao,
            this.traduzPagina.traducaoFalhaComunicacao,
            this.traduzPagina.traducaoComunicacaoParcial
        );
    }

    abrirTooltip(): void {
        if (this.ocultarModal) return;
        this.mostrarTooltip = MostrarTooltipSiloEnum.BLOCK;
    }

    fecharTooltip(): void {
        if (this.ocultarModal) return;
        this.mostrarTooltip = MostrarTooltipSiloEnum.HIDDEN;
    }

    abrirModalEquipamentos(): void {
        const screenSize = window.screen;
        const getWidthById = document.getElementById('classicLayout').clientWidth;

        this.dialog.open(ModalEquipamentosFullscreenComponent, {
            id: 'ListaEquipamentos',
            minWidth: getWidthById,
            minHeight: screenSize.height * configuracaoTamanhoModal.fullScreen.adaptacaoHeight,
            data: { equipamentos: this.equipamentos, estrutura: this.estruturaArmazenagem ?? null },
        });
    }
}
