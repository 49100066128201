import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

import { MatDialog } from '@angular/material/dialog';

import { Subject, takeUntil } from 'rxjs';
import { isEmpty } from 'lodash';

import { SincronizadorEquipamentoHistoricoTipoEnum } from 'app/layout/common/sincronizador-equipamento-historico/enums';
import { SincronizadorEquipamentoHistoricoComponent } from 'app/layout/common/sincronizador-equipamento-historico';
import { ISincronizadorController } from 'app/layout/common/sincronizador-equipamento';
import { SincronizadorEquipamentoObservable } from 'app/shared/observables';
import { SincronizadorModel } from 'app/shared/models';

@Component({
    selector: 'app-sincronizador-equipamento',
    templateUrl: './sincronizador-equipamento.componente.html',
    styleUrls: ['./sincronizador-equipamento.componente.scss'],
})
export class SincronizadorEquipamentoComponent implements OnInit, OnDestroy {
    tipoSincronizadorEnum = SincronizadorEquipamentoHistoricoTipoEnum;
    dataSincronizador!: SincronizadorModel;
    objetoVazio: boolean = true;

    private destroy$ = new Subject<boolean>();

    constructor(
        public readonly dialog: MatDialog,

        private readonly sincronizadorController: ISincronizadorController,

        private sincronizadorEquipamentoObservable: SincronizadorEquipamentoObservable,
        private breakpointObserver: BreakpointObserver
    ) {}

    ngOnInit(): void {
        this.buscarUltimaSincronizacao();
    }

    buscarUltimaSincronizacao(): void {
        if (this.sincronizadorEquipamentoObservable.getSincronizador$) {
            this.sincronizadorEquipamentoObservable.buscaSincronizador$
                .pipe(takeUntil(this.destroy$))
                .subscribe((sincronizadorData) => {
                    this.dataSincronizador = sincronizadorData;
                    this.objetoVazio =
                        isEmpty(this.dataSincronizador?.coletor_comunicacao) &&
                        isEmpty(this.dataSincronizador?.comunicacao_local_com_nuvem);
                });
            return;
        }

        this.sincronizadorController
            .buscaUltimaSincronizacao()
            .pipe(takeUntil(this.destroy$))
            .subscribe({
                next: (data) => {
                    this.dataSincronizador = data;
                    this.objetoVazio =
                        isEmpty(this.dataSincronizador?.coletor_comunicacao) &&
                        isEmpty(this.dataSincronizador?.comunicacao_local_com_nuvem);
                },
                error: (error) => {
                    console.log('error: ', error);
                },
            });
    }

    mostrarHistorico(tipo: SincronizadorEquipamentoHistoricoTipoEnum): void {
        const smallScreen = this.breakpointObserver.isMatched('(max-width: 600px)');
        const minWidth = smallScreen ? '100%' : '920px';
        const minHeight = smallScreen ? 'auto' : '600px';
        this.dialog.open(SincronizadorEquipamentoHistoricoComponent, {
            minWidth,
            minHeight,
            data: {
                tipo,
            },
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next(null);
        this.destroy$.complete();
    }
}
