<div id="tooltipCustomPendulo" class="absolute z-99 rounded popoverCable" style="width: 200px; height: 80px">
    <div class="w-full bg-slate-200 text-center">
        <span *ngIf="penduloAtual">{{ "P" + (penduloAtual?.pendulo?.codigo | number : "2.0") }}</span>
    </div>
    <div *ngIf="penduloAtual?.pendulo?.codigo" class="w-full bg-slate-100 text-sm p-1.5 text-left">
        <p>
            {{ "TIPO" | transloco }}:
            {{ penduloAtual?.pendulo?.pendulo_propriedade?.digital_termopar ?? ("AGUARDANDO_LEITURA" | transloco) }}
        </p>
        <p
            *ngIf="
                penduloAtual?.pendulo?.pendulo_canal?.canal &&
                penduloAtual?.pendulo?.pendulo_propriedade.sensor_temperatura_quantidade
            "
        >
            {{ "CANAL" | transloco }} {{ penduloAtual?.pendulo?.pendulo_canal?.canal }},
            {{ penduloAtual?.pendulo?.pendulo_propriedade.sensor_temperatura_quantidade }} {{ "SENSORES" | transloco }}
        </p>
        <p *ngIf="penduloAtual?.pendulo?.pendulo_leitura?.instante">
            <span> {{ "STATUS" | transloco }}: </span>
            <span>
                {{
                    penduloAtual?.pendulo?.pendulo_leitura?.instante
                        ? (penduloAtual?.pendulo?.pendulo_leitura?.instante | date : "dd/MM/yyyy HH:mm")
                        : ("AGUARDANDO_LEITURA" | transloco)
                }}
            </span>
        </p>
    </div>
</div>
