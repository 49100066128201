import { Injectable } from "@angular/core";
import { EstacaoMeteorologicaInterface } from "app/shared/interfaces";

export abstract class IOrdenarEstacoesService {
  abstract execute(estacoes: EstacaoMeteorologicaInterface[]): void;
}

@Injectable({
  providedIn: "root",
})
export class OrdenarEstacoesService {
  execute(estacoes: EstacaoMeteorologicaInterface[]): void {
    estacoes.sort((a, b) => {
      const instanteA = a.ultima_leitura?.validacao_leitura?.instante
        ? new Date(a.ultima_leitura.validacao_leitura.instante).getTime()
        : 0;

      const instanteB = b.ultima_leitura?.validacao_leitura?.instante
        ? new Date(b.ultima_leitura.validacao_leitura.instante).getTime()
        : 0;

      if (instanteA !== instanteB) {
        return instanteB - instanteA;
      }

      const statusA = a.ultima_leitura?.validacao_leitura?.status ? 1 : 0;
      const statusB = b.ultima_leitura?.validacao_leitura?.status ? 1 : 0;

      return statusB - statusA;
    });
  }
}
