import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';

import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CheckInCheckOutObservable } from 'app/shared/observables';
import { StatusAtendimentoEnum } from 'app/shared/enums';

import { ICheckInCheckOutController } from 'app/layout/common/check-in-check-out/controllers';
import { configuracaoTamanhoModal } from 'app/shared/constants';
import { AtendimentoInterface } from 'app/shared/interfaces';
import { IAlertaService } from 'app/shared/services';

import {
    ModalReiniciarCheckinComponent,
    ModalRetomarCheckInComponent,
} from 'app/layout/common/check-in-check-out/components';

@Component({
    selector: 'app-header-check-in-check-out',
    templateUrl: './header-check-in-check-out.component.html',
    styleUrls: ['./header-check-in-check-out.component.scss'],
})
export class HeaderCheckInCheckOutComponent implements OnInit, OnDestroy {
    @ViewChild('menuTrigger') private readonly menuTrigger: MatMenuTrigger;
    @ViewChild('menuCheckIn', { static: true, read: MatMenuTrigger })
    private readonly menuCheckInTrigger: MatMenuTrigger;

    atendimentoAtual: AtendimentoInterface;
    carregandoDados = false;
    fazendoRequisicao = false;
    checkInIniciado = false;
    contadorPausado = false;
    checkOutAberto = false;

    readonly tempoDecorrido$: Observable<string>;

    private readonly unsubscribeAll = new Subject<void>();

    constructor(
        private checkInCheckOutController: ICheckInCheckOutController,
        public checkInCheckOutObservable: CheckInCheckOutObservable,
        private breakpointObserver: BreakpointObserver,
        private notificacaoService: IAlertaService,
        private transloco: TranslocoService,
        private dialog: MatDialog,
        private router: Router
    ) {
        this.tempoDecorrido$ = this.checkInCheckOutObservable.contadorCheckOut$;
    }

    async ngOnInit(): Promise<void> {
        await this.iniciarObservadorAtendimento();

        this.checkInCheckOutObservable.getCarregandoDados$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((carregando) => {
                this.carregandoDados = carregando;
            });
    }

    iniciarCheckIn(): void {
        if (this.menuCheckInTrigger) {
            this.menuCheckInTrigger.closeMenu();
        }

        if (!this.atendimentoAtual) {
            this.iniciarNovoAtendimento();
        }
    }

    private inicializarAtendimentoExistente(atendimento: AtendimentoInterface): void {
        this.checkInIniciado = true;
        this.checkInCheckOutObservable.setCheckInAtivo = true;
        this.checkInCheckOutObservable.setAtendimentoId = atendimento.id;
        this.checkInCheckOutObservable.setAtendimento = atendimento;

        if (atendimento.status === StatusAtendimentoEnum.andamento_checkin) {
            this.checkInCheckOutObservable.setPassadorUnidadesBloqueado = true;
            this.checkInCheckOutObservable.iniciarContador();
        } else if (atendimento.status === StatusAtendimentoEnum.pausado) {
            this.checkInCheckOutObservable.setPassadorUnidadesBloqueado = false;
            this.contadorPausado = true;
            this.checkInCheckOutObservable.setPausado = true;
            this.checkInCheckOutObservable.iniciarContador();
        }
    }

    private iniciarNovoAtendimento(): void {
        this.checkInCheckOutController
            .iniciarNovoAtendimento()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe({
                next: (response) => {
                    this.checkInIniciado = true;
                    this.checkInCheckOutObservable.setCheckInAtivo = true;
                    this.checkInCheckOutObservable.setCheckoutIniciado = false;
                    this.checkInCheckOutObservable.setPassadorUnidadesBloqueado = true;
                    this.checkInCheckOutObservable.setAtendimentoId = response.id;
                    this.checkInCheckOutObservable.setAtendimento = response;
                    this.contadorPausado = false;
                    this.checkInCheckOutObservable.setPausado = false;

                    this.checkInCheckOutObservable.iniciarContador();
                },
                error: (error) => {
                    console.error(error);
                },
            });
    }

    reiniciarContagemCheckOut(): void {
        this.fazendoRequisicao = true;

        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');

        const dialogRef = this.dialog.open(ModalReiniciarCheckinComponent, {
            width: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.width
                : configuracaoTamanhoModal.confirmacao.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.height
                : configuracaoTamanhoModal.confirmacao.desktop.height,
            disableClose: true,
        });

        dialogRef
            .afterClosed()
            .subscribe((resultado) => {
                if (resultado) {
                    this.checkInCheckOutController
                        .reiniciarAtendimento(this.atendimentoAtual.id, resultado.motivo)
                        .pipe(takeUntil(this.unsubscribeAll))
                        .subscribe({
                            next: (response) => {
                                this.checkInCheckOutObservable.setAtendimento = response;
                                this.checkInCheckOutObservable.reiniciarContador();

                                this.notificacaoService.mostrarSucesso(
                                    this.transloco.translate('CHECK_IN_CHECK_OUT.CHECK_IN_REINICIADO_SUCESSO')
                                );
                            },
                            error: (error) => {
                                console.log(error);
                            },
                        });
                }
            })
            .add(() => {
                this.fazendoRequisicao = false;
            });
    }

    alternarContagem(): void {
        if (this.atendimentoAtual?.status === StatusAtendimentoEnum.andamento_checkin) {
            this.fazendoRequisicao = true;
            this.checkInCheckOutController
                .pausarAtendimento(this.atendimentoAtual.id)
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe({
                    next: (response) => {
                        this.fazendoRequisicao = true;
                        this.checkInCheckOutObservable.setPausado = true;
                        this.atendimentoAtual = response;
                        this.checkInCheckOutObservable.setAtendimento = response;
                        this.checkInCheckOutObservable.setPassadorUnidadesBloqueado = false;
                        this.checkInCheckOutObservable.registrarPausa();
                        this.notificacaoService.mostrarSucesso(
                            this.transloco.translate('CHECK_IN_CHECK_OUT.CHECK_IN_PAUSADO_SUCESSO')
                        );
                    },
                    error: (error) => {
                        console.error(error);
                    },
                })
                .add(() => {
                    this.fazendoRequisicao = false;
                });
        } else if (this.atendimentoAtual?.status === StatusAtendimentoEnum.pausado) {
            const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');

            const dialogRef = this.dialog.open(ModalRetomarCheckInComponent, {
                width: mobile
                    ? configuracaoTamanhoModal.confirmacao.mobile.width
                    : configuracaoTamanhoModal.confirmacao.desktop.width,
                minHeight: mobile
                    ? configuracaoTamanhoModal.confirmacao.mobile.height
                    : configuracaoTamanhoModal.confirmacao.desktop.height,
                disableClose: true,
            });

            dialogRef
                .afterClosed()
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe((resultado) => {
                    if (resultado) {
                        this.fazendoRequisicao = true;

                        this.checkInCheckOutController
                            .retomarAtendimento(this.atendimentoAtual.id)
                            .pipe(takeUntil(this.unsubscribeAll))
                            .subscribe({
                                next: (response) => {
                                    this.checkInCheckOutObservable.calcularTempoPausado();
                                    this.atendimentoAtual = response;
                                    this.checkInCheckOutObservable.setAtendimento = response;
                                    this.checkInCheckOutObservable.setPassadorUnidadesBloqueado = true;
                                    this.checkInCheckOutObservable.setCheckInAtivo = true;
                                    this.checkInCheckOutObservable.setPausado = false;
                                    this.checkInCheckOutObservable.iniciarContador();
                                    this.notificacaoService.mostrarSucesso(
                                        this.transloco.translate('CHECK_IN_CHECK_OUT.CHECK_IN_RETOMADO_SUCESSO')
                                    );
                                },
                                error: (error) => {
                                    console.error(error);
                                },
                            })
                            .add(() => {
                                this.fazendoRequisicao = false;
                            });
                    }
                });
        }
    }

    abrirCheckOut(): void {
        if (this.checkOutAberto) {
            return;
        }

        this.checkOutAberto = true;
        this.checkInCheckOutObservable.setCheckoutIniciado = true;
        this.checkInCheckOutObservable.setPassadorUnidadesBloqueado = true;

        if (this.menuTrigger) {
            this.menuTrigger.closeMenu();
        }

        this.contadorPausado = true;
        this.checkInCheckOutObservable.setPausado = true;
        this.router.navigate(['/check-in-check-out/check-out'], { skipLocationChange: true });
    }

    private iniciarObservadorCheckInAtivo(): void {
        this.checkInCheckOutObservable.checkInAtivo$.pipe(takeUntil(this.unsubscribeAll)).subscribe((ativo) => {
            if (ativo !== this.checkInIniciado) {
                this.checkInIniciado = ativo;
                if (ativo) {
                    this.checkInCheckOutObservable.iniciarContador();
                }
            }
        });
    }

    private async iniciarObservadorAtendimento(): Promise<void> {
        await this.checkInCheckOutObservable.atendimento$.pipe(takeUntil(this.unsubscribeAll)).subscribe({
            next: (atendimento) => {
                if (!atendimento) {
                    this.atendimentoAtual = atendimento;
                    this.checkInCheckOutObservable.setAtendimento = atendimento;
                    this.checkInIniciado = false;
                    this.checkInCheckOutObservable.setCheckInAtivo = false;
                    return;
                }

                if (atendimento) {
                    this.atendimentoAtual = atendimento;
                    this.checkInCheckOutObservable.setAtendimento = atendimento;
                    this.inicializarAtendimentoExistente(atendimento);
                    return;
                }
            },
        });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
}
