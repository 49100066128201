import { TranslocoRootModule } from 'app/transloco-root.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { StatusAeracaoModule } from 'app/shared/components/status-aeracao/status-aeracao.module';
import { StatusAutomaticoManualComIconeDescricaoModule } from 'app/shared/components/status-automatico-manual-com-icone-descricao/status-automatico-manual-com-icone-descricao.module';
import { StatusOnOffModule } from 'app/shared/components/status-on-off/status-on-off.module';
import { AeracaoVisaoDetalhadaArmazemComponent } from 'app/shared/components/aeracao-visao-detalhada-armazem/aeracao-visao-detalhada-armazem.component';
import { StatusAutomaticoManualModule } from 'app/shared/components/status-automatico-manual/status-automatico-manual.module';
import { ModalAcionamentoManulAeracaoModule } from 'app/shared/components/modal-acionamento-manul-aeracao/modal-acionamento-manul-aeracao.module';
import { ModalHistoricoAlteracoesProgramaModule } from 'app/shared/components/modal-historico-alteracoes-programa/modal-historico-alteracoes-programa.module';
import { MatPaginatorModule } from '@angular/material/paginator';
@NgModule({
	imports: [
		CommonModule,
		MatIconModule,
		StatusAutomaticoManualComIconeDescricaoModule,
		StatusOnOffModule,
		MatSelectModule,
		ReactiveFormsModule,
		MatTableModule,
		StatusAeracaoModule,
		StatusAutomaticoManualModule,
		ModalAcionamentoManulAeracaoModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
		MatMenuModule,
		ModalHistoricoAlteracoesProgramaModule,
		TranslocoRootModule,
		MatPaginatorModule,
	],

	declarations: [AeracaoVisaoDetalhadaArmazemComponent],
	exports: [AeracaoVisaoDetalhadaArmazemComponent],
})
export class AeracaoVisaoDetalhadaArmazemModule {}
