<ng-container *ngIf="mostrarAlertaSensor3D()">
    <div
        class="min-h-[67px] rounded text-xs sm:text-base p-2 flex"
        [ngStyle]="{
            'background-color': '#FFBA0933',
            color: '#956C04'
        }"
    >
        <div class="flex flex-row sm:w-[5%] w-[10%] items-center justify-center my-auto">
            <mat-icon [svgIcon]="'mat_solid:warning'" class="cursor-default fill-[#FFBA09]"></mat-icon>
        </div>
        <div class="flex flex-col sm:w-[95%] w-[90%] justify-start my-auto">
            <p>
                <ng-container>
                    {{ 'VISAO_DETALHADA_SILOS_SCREEN.MENSAGEM_SENSOR_3D_COMUNICANDO' | transloco }}
                </ng-container>
            </p>
        </div>
    </div>
</ng-container>
