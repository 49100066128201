<div class="cursor-default px-2">
	<div class="text-[#0b0c0e] wl-cor-primaria flex items-center mb-4 w-full">
		<div class="flex items-center mt-2 w-[90%] justify-start">
			<mat-icon class="p-[2px] ml-[-3px]" [svgIcon]="'icone-aeracao-wl'"></mat-icon>
			<span class="my-1 ml-2">{{ 'VISAO_DETALHADA_SILOS_SCREEN.AERACAO' | transloco }}</span>
		</div>
		<div class="flex items-center w-[10%] justify-end" *ngIf="!historicoSilo && !ocultarControles">
			<button
				(click)="abrirModalAcionamentoManual(retornarConfiguracaoStatusAeracao(statusAeracao), silo)"
				class="rounded-md h-9 w-12 bg-transparent border-0 disabled:opacity-60"
				[disabled]="
					!permissaoAdministrar || retornarConfiguracaoStatusAeracao(statusAeracao)
						? !retornarConfiguracaoStatusAeracao(statusAeracao)?.acionamento_manual.habilitado
						: true
				"
			>
				<mat-icon class="p-[2px] ml-[-3px] icon-size-[50px]" [svgIcon]="'icone-acionamento-aeracao'"></mat-icon>
			</button>
		</div>
	</div>
	<ng-container *ngIf="!ocultarControles">
		<div class="flex items-center w-full my-3 px-2">
			<div class="flex items-center w-[40%] justify-between">
				<span class="items-center flex text-base] truncate"
					>{{ 'VISAO_DETALHADA_SILOS_SCREEN.CONTROLE_AERACAO' | transloco }}
					<span *ngIf="!historicoSilo">
						<mat-icon
							[svgIcon]="'icon-info'"
							class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
							(click)="mostrarHistoricoControleAeracao(silo.id)"
						></mat-icon>
					</span>
				</span>
			</div>
			<div class="flex items-center w-[60%] justify-end aeracao">
				<app-status-automatico-manual-com-icone-descricao
					[statusAutomatico]="controleAeracao"
				></app-status-automatico-manual-com-icone-descricao>
				<ng-container *ngIf="!historicoSilo">
					<mat-icon
						*ngIf="!carregando || campoEmEdicao != tipoCampoEmEdicaoEnum.CONTROLE; else carregandoDados"
						class="ml-2 cursor-pointer"
						svgIcon="mat_outline:edit"
						[matMenuTriggerFor]="!permissaoAdministrar ? null : menuSilo"
						[ngClass]="{
							'opacity-50 cursor-default': !permissaoAdministrar,
							'cursor-pointer': permissaoAdministrar
						}"
					></mat-icon>
					<mat-menu #menuSilo="matMenu">
						<button
							mat-menu-item
							[ngStyle]="{
								'background-color': controleAeracao ? 'var(--cor-primaria-opacidade)' : '',
								color: controleAeracao ? 'var(--cor-primaria)' : '#535767'
							}"
							(click)="editarControleAeracao(true)"
						>
							<mat-icon
								class="wl-cor-primaria icon-size-4 mr-2 cursor-pointer"
								[svgIcon]="'mat_solid:check_circle'"
								[ngStyle]="{
									fill: controleAeracao ? 'var(--cor-primaria)' : '#535767'
								}"
							></mat-icon>
							<span class="text-base">{{
								'STATUS_AUTOMATICO_MANUAL_ICONE_COMPONENT.AUTOMATICO' | transloco
							}}</span>
						</button>
						<hr class="my-0 w-[90%] mx-auto" />
						<button
							mat-menu-item
							[ngStyle]="{
								'background-color': !controleAeracao ? 'var(--cor-primaria-opacidade)' : '',
								color: !controleAeracao ? 'var(--cor-primaria)' : '#535767'
							}"
							(click)="editarControleAeracao(false)"
						>
							<mat-icon
								class="wl-cor-primaria icon-size-4 mr-2 cursor-pointer"
								[svgIcon]="'mat_solid:back_hand'"
								[ngStyle]="{
									fill: !controleAeracao ? 'var(--cor-primaria)' : '#535767'
								}"
							></mat-icon>
							<span class="text-base">{{
								'STATUS_AUTOMATICO_MANUAL_ICONE_COMPONENT.MANUAL' | transloco
							}}</span>
						</button>
					</mat-menu>
				</ng-container>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="!ocultarControles">
		<div class="flex items-center w-full my-3 px-2">
			<div class="flex items-center w-[80%] justify-start">
				<span class="items-center flex text-base"
					>{{ 'VISAO_DETALHADA_SILOS_SCREEN.COMANDO_AERACAO' | transloco }}
					<span *ngIf="!historicoSilo">
						<mat-icon
							[svgIcon]="'icon-info'"
							class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
							(click)="mostrarHistoricoComandoAeracao(silo.id)"
						></mat-icon>
					</span>
				</span>
				<div class="ml-2">
					<app-status-on-off
						[reduzirWidth]="true"
						[statusOn]="comandoAeracao"
						[possuiStatusComunicacao]="false"
					></app-status-on-off>
				</div>
			</div>

			<div class="items-center w-[35%] aeracao justify-end">
				<div class="flex flex-col items-end">
					<span class="items-center flex text-base"
						>{{ 'VISAO_DETALHADA_SILOS_SCREEN.PROGRAMA' | transloco }}
						<span *ngIf="!historicoSilo">
							<mat-icon
								[svgIcon]="'icon-info'"
								class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
								(click)="mostrarHistoricoAlteracoesPrograma(silo.id)"
							></mat-icon>
						</span>
					</span>
				</div>
				<div
					class="flex flex-col items-end my-2 containerSelectAeracao text-base"
					[ngClass]="historicoSilo ? '' : '-mr-[14px]'"
				>
					<mat-form-field
						[hideRequiredMarker]="true"
						class="my-auto text-base"
						(click)="customizaPanelSelect()"
						*ngIf="!historicoSilo"
					>
						<mat-select
							id="select-aeracao"
							panelClass="selectAeracao"
							[formControl]="programaControle"
							#selectPrograma
							(selectionChange)="editarPrograma($event.value)"
						>
							<mat-option value="null" class="text-[13px]">
								{{ 'VISAO_DETALHADA_SILOS_SCREEN.NENHUM' | transloco }}
							</mat-option>
							<mat-option
								*ngFor="let programa of listaDeProgramas?.data"
								[value]="programa.id"
								class="text-[13px]"
							>
								{{ programa?.nome }}
							</mat-option>
						</mat-select>
						<ng-container *ngIf="!historicoSilo">
							<mat-icon
								*ngIf="!carregando || campoEmEdicao != tipoCampoEmEdicaoEnum.PROGRAMA; else carregandoDados"
								class="ml-2 cursor-pointer"
								svgIcon="mat_outline:edit"
								[ngClass]="{
									'opacity-50 cursor-default': !permissaoAdministrar,
									'cursor-pointer': permissaoAdministrar
								}"
							></mat-icon>
						</ng-container>
					</mat-form-field>
					<span *ngIf="historicoSilo" class="text-[13px]">
						{{ programaSetadoSilo?.nome ?? ('VISAO_DETALHADA_SILOS_SCREEN.NENHUM' | transloco) }}
					</span>
				</div>
			</div>

		</div>

		<div class="my-3 px-2 flex flex-row justify-between">
			<div
				class="flex items-center"
				*ngIf="tempoAeracaoAutomatica || tempoAeracaoManual"
			>
				<div class="w-full">
					<span class="text-base">{{ 'VISAO_DETALHADA_SILOS_SCREEN.TEMPO_DE_FUNC' | transloco }}</span>
                    <div class="w-full flex flex-row items-center my-2">
                    <div class="flex flex-row items-center justify-end" *ngIf="tempoAeracaoAutomatica">
						<span
							class="flex rounded-md w-9 p-2 h-[23px] justify-center bg-[#B2E7A7] text-[#34901C] text-base items-center mr-2"
						>
							{{ 'VISAO_DETALHADA_SILOS_SCREEN.A' | transloco }}
						</span>
						<span class="text-base">{{ tempoAeracaoAutomatica }}</span>
					</div>
                    <div class="sm:ml-2 flex flex-row items-center justify-end" *ngIf="tempoAeracaoManual">
						<span
							class="flex rounded-md w-9 p-2 h-[23px] justify-center bg-[#FBBC0566] text-[#B48707] text-base items-center mr-2"
						>
							{{ 'VISAO_DETALHADA_SILOS_SCREEN.M' | transloco }}
						</span>
						<span class="text-base">{{ tempoAeracaoManual }}</span>
					</div>
                </div>
				</div>				
			</div>

			<div class="flex flex-col items-center">
				<span class="text-base">{{ 'VISAO_DETALHADA_SILOS_SCREEN.OBJETIVO' | transloco }}</span>
				<ng-container *ngIf="!historicoSilo">
					<span class="text-[13px] my-2">
						{{ objetivoProgramaUsado ?? ('VISAO_DETALHADA_SILOS_SCREEN.NENHUM' | transloco) }}
					</span>
				</ng-container>
				<ng-container *ngIf="historicoSilo">
					<span class="text-[13px] my-2"
						>{{
							objetivoProgramaUsadoHistorico(programaSetadoSilo?.objetivo ?? programaSetadoSilo?.objetivo_programa) ??
								('VISAO_DETALHADA_SILOS_SCREEN.NENHUM' | transloco)
						}}
					</span>
				</ng-container>
			</div>

			<div class="flex flex-col items-end">
				<div class="flex items-center">
					<span class="items-center flex text-base"
						>{{ 'VISAO_DETALHADA_SILOS_SCREEN.VARIAVEL_DE_AQUECIMENTO' | transloco }}
						<span *ngIf="!historicoSilo">
							<mat-icon
								[svgIcon]="'icon-info'"
								class="wl-cor-primaria ml-0.5 p-1.5 cursor-pointer"
								(click)="mostrarHistoricoVariavelAquecimento(silo.id)"
							></mat-icon>
						</span>
					</span>
				</div>
				<div class="flex flex-col items-end my-2 containerSelectAeracao text-base -mr-[14px]">
					<mat-form-field
						[hideRequiredMarker]="true"
						class="my-auto text-base"
						(click)="customizaPanelSelect()"
						*ngIf="
							!historicoSilo &&
							listaVariavelAquecimento &&
							(silo?.sensores?.sensor_plenum?.length <= 0 || !silo?.sensores?.sensor_plenum?.length)
						"
					>
						<mat-select
							id="select-aeracao"
							panelClass="selectAeracao"
							[formControl]="variavelAquecimentoControle"
							#selectPrograma
							(selectionChange)="editarVariavel($event.value)"
						>
							<mat-option
								class="text-base"
								*ngFor="let variavel of Object.keys(listaVariavelAquecimento); let i = index"
								[value]="variavel"
							>
								{{ listaVariavelAquecimento[variavel] | number : '1.1' : 'pt' }} °C
							</mat-option>
						</mat-select>
						<ng-container *ngIf="!historicoSilo">
							<mat-icon
								*ngIf="!carregando || campoEmEdicao != tipoCampoEmEdicaoEnum.VARIAVEL_AQUECIMENTO; else carregandoDados"
								class="ml-2 cursor-pointer"
								svgIcon="mat_outline:edit"
								[ngClass]="{
									'opacity-50 cursor-default': !permissaoAdministrar,
									'cursor-pointer': permissaoAdministrar
								}"
							></mat-icon>
						</ng-container>
					</mat-form-field>
				</div>
				<span
					*ngIf="
						historicoSilo && (silo?.sensores?.sensor_plenum?.length <= 0 || !silo?.sensores?.sensor_plenum?.length)
					"
				>
					{{ variavelAquecimento ? (variavelAquecimento | number : '1.1' : 'pt') : 'Nenhum' }}
				</span>
				<span *ngIf="silo?.sensores?.sensor_plenum.length > 0">
					{{ 'VISAO_DETALHADA_SILOS_SCREEN.PLENUM' | transloco }}
				</span>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #carregandoDados>
	<mat-progress-spinner [diameter]="24" [mode]="'indeterminate'" class="ml-2"> </mat-progress-spinner>
</ng-template>
