import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  OrigemDadosColetor,
  ProdutosProcerEnum,
  SiloBaseEnum,
} from 'app/shared/enums';
import {
  AeracaoInformacoesSiloInterface,
  EstruturaArmazenagemDivisaoAerador,
  InformacoesSiloInterface,
} from 'app/shared/interfaces';
import { UnidadeParametrizacaoObservable } from 'app/shared/observables';
import { take } from 'rxjs';

declare let $: any;
declare let PlainSiloView: any;

@Component({
  selector: 'app-visao-plana-silo',
  templateUrl: './visao-plana-silo.component.html',
  styleUrls: ['./visao-plana-silo.component.scss'],
})
export class VisaoPlanaSiloComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild('selector') selector: ElementRef;

  @Input() silo: InformacoesSiloInterface;
  @Input() config: string;
  @Input() level: string;
  @Input() thermal: string;
  @Input() humidity: string;
  @Input() sequence: string;
  @Input() emc: string;
  @Input() thermocouples: string;
  @Input() selected: number;
  @Input() communicatingCables: string;
  @Input() communicatingInfo: any;
  @Input() historical?: boolean;
  @Input() siloViewOptions: any;
  @Input() alias: string;
  @Input() cables?: [];
  @Input() historicalDate?: Date;
  @Input() configWidthDivTime?: string;
  @Input() viewTimeSilo: boolean;
  @Input() exibirTooltip: boolean = true;
  @Input() exibirAeradores?: boolean = false;

  listaAeradores: EstruturaArmazenagemDivisaoAerador[] = [];
  possuiAeracaoAutomatica?: boolean = false;
  aeracao: AeracaoInformacoesSiloInterface;
  ordenacaoPendulos: string;

  element: any;
  instance: any;

  constructor(
    private zone: NgZone,
    private _changeDetectorRef: ChangeDetectorRef,
    private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable
  ) {}

  ngOnInit(): void {
    if (this.silo?.estrutura_armazenagem_divisao_aerador?.length > 0) {
      this.listaAeradores = this.silo.estrutura_armazenagem_divisao_aerador;
      this.aeracao = this.silo.aeracao;
    }
  }

  ngAfterViewInit(): void {
    this.buscarUnidadesParametrizacao();
    this._changeDetectorRef.detectChanges();
  }

  private initPlugin(): void {
    this.element = $(this.selector.nativeElement);
    this.setViewOptions(this.element);
    this.instance = new PlainSiloView(this.element);
  }

  private setViewOptions(_element: any): void {
    this.podeDispararEventoCustomizadoAeradores(_element);
    if (this.siloViewOptions) {
      if (this.siloViewOptions === SiloBaseEnum.EM_V) {
        _element.attr('data-cone-bottom', true);
        const sensoresTelhado = this.adequaEspacamentoSensores().split(';');

        if (!sensoresTelhado.every((valor) => valor === '0')) {
          _element.attr('data-y-offset', this.colocaSensoresMesmaLinha());
          _element.attr('data-roof-sensors', this.adequaEspacamentoSensores());
        }
      }
      if (this.siloViewOptions === SiloBaseEnum.PLANA) {
        _element.attr('data-large-roof-window', true);
        const sensoresTelhado = this.adequaEspacamentoSensores().split(';');

        if (
          sensoresTelhado.length > 15 &&
          sensoresTelhado.some((item) => item !== '0')
        ) {
          _element.attr('data-roof-sensors', this.adequaEspacamentoSensores());
        }

        if (
          !sensoresTelhado.every((valor) => valor === '0') &&
          !this.ordenacaoPendulos
        ) {
          _element.attr('data-roof-sensors', this.adequaEspacamentoSensores());
        }
      }

      if (this.siloViewOptions === SiloBaseEnum.SEMI_V) {
        _element.attr('data-semicone-bottom', true);
        _element.attr('data-y-offset', this.colocaSensoresMesmaLinha());
        _element.attr('data-roof-sensors', this.adequaEspacamentoSensores());
      }
    }
  }

  private adequaEspacamentoSensores(): string {
    let min = 0;
    let max = 0;
    let numeroSensoresArrayInt = [];
    let arraySensoresTelhado = [];
    const numeroSensoresArrayString = this.config.split(';');
    numeroSensoresArrayString.forEach((sensor) => {
      numeroSensoresArrayInt.push(parseInt(sensor));
    });
    min = Math.min(...numeroSensoresArrayInt);
    max = Math.max(...numeroSensoresArrayInt);
    numeroSensoresArrayInt.forEach((sensor) => {
      if (sensor > min) {
        arraySensoresTelhado.push(sensor - min);
      } else {
        arraySensoresTelhado.push(0);
      }
    });
    return arraySensoresTelhado.join(';');
  }

  private colocaSensoresMesmaLinha(): string {
    let arrayNumeroPendulos = [];
    const numeroSensoresArrayString = this.config.split(';');
    numeroSensoresArrayString.forEach(() => {
      arrayNumeroPendulos.push(1);
    });
    return arrayNumeroPendulos.join(';');
  }

  private podeDispararEventoCustomizadoAeradores(_element): void {
    if (this.exibirAeradores) {
      _element.attr('data-exibir-aeradores', true);
    } else {
      _element.attr('data-exibir-aeradores', false);
    }
  }

  private buscarUnidadesParametrizacao(): void {
    this.unidadeParametrizacaoObservable.buscaParametrosUnidade$.subscribe({
      next: (response) => {
        if (response !== null) {
          this.possuiAeracaoAutomatica = response.unidade_produto_interno.some(
            (elemento) =>
              elemento.produto === ProdutosProcerEnum.AERACAO_AUTOMATICA
          );
          this.ordenacaoPendulos =
            response.unidade_parametrizacao?.origem_dados ===
              OrigemDadosColetor.COLETOR_CERES &&
            this.diferencaSensoresMenorEMaiorPendulo() <= 6
              ? this.sequence
              : undefined;
        }
        this.zone.runOutsideAngular(() => this.initPlugin());
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  private diferencaSensoresMenorEMaiorPendulo(): number {
    let numeroSensoresArrayInt = [];

    const numeroSensoresArrayString = this.config.split(';');
    numeroSensoresArrayString.forEach((sensor) => {
      numeroSensoresArrayInt.push(parseInt(sensor));
    });

    return (
      Math.max(...numeroSensoresArrayInt) - Math.min(...numeroSensoresArrayInt)
    );
  }

  ngOnDestroy(): void {
    this.element.attr('data-exibir-aeradores', false);
  }
}
