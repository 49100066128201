import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ResumoUnidadeInterface } from 'app/shared/interfaces';

@Injectable({
    providedIn: 'root',
})
export class UnidadeParametrizacaoObservable {
    private unidadeParametrizacao: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private resumoUnidadeCarregado = new BehaviorSubject<boolean>(false);

    set setParametrosUnidade(value: ResumoUnidadeInterface) {
        this.unidadeParametrizacao.next(value);
        this.resumoUnidadeCarregado.next(true);
    }

    get getParametrosUnidade$(): ResumoUnidadeInterface {
        return this.unidadeParametrizacao.getValue();
    }

    get buscaParametrosUnidade$(): Observable<ResumoUnidadeInterface> {
        return this.unidadeParametrizacao.asObservable();
    }

    get resumoUnidadeCarregado$(): Observable<boolean> {
        return this.resumoUnidadeCarregado.asObservable();
    }
}
