import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusAeracaoArmazemComponent } from 'app/shared/components/status-aeracao-armazem/status-aeracao-armazem.component';

@NgModule({
    imports: [CommonModule, MatIconModule],
    declarations: [StatusAeracaoArmazemComponent],
    exports: [StatusAeracaoArmazemComponent],
})
export class StatusAeracaoArmazemModule {}
