<div
    *ngIf="visualizarTempo"
    [ngClass]="[
        tamanhoIconeStatus ? 'mt-[-5px]' : 'flex justify-end w-full mb-[-15px]',
        modalEquipamentos ? 'cursor-pointer' : ''
    ]"
    [id]="idTooltip ?? 'dataUltimaComunicacao'"
    (window:resize)="posicionarTooltip()"
>
    <span
        class="flex items-center text-sm font-medium"
        [ngClass]="apenasVisualizacao ? 'cursor-default' : 'cursor-pointer'"
        (mouseenter)="!modalEquipamentos ? abrirTooltip() : ''"
        (mouseleave)="!modalEquipamentos ? fecharTooltip() : ''"
        (click)="modalEquipamentos ? abrirModalEquipamentos() : ''"
    >
        <span class="flex" [ngClass]="tamanhoIconeStatus ?? 'timerCommunication'">
            <mat-icon
                [ngStyle]="{ color: dataMaisRecente ? statusComunicacao().cor : '#D65757E5' }"
                svgIcon="mat_solid:circle"
            >
            </mat-icon>
        </span>
        <span
            class="text-[#535767] rounded px-1.5 py-0.5"
            [ngClass]="apenasVisualizacao ? 'cursor-default' : 'cursor-pointer bg-white'"
        >
            <ng-container *ngIf="dataEvento">
                {{ dataEvento | date : "dd/MM/yyyy HH:mm" }}
            </ng-container>
            <ng-container *ngIf="!dataEvento">
                {{
                    dataMaisRecente === "Aguardando leitura"
                        ? ("DATA_ULTIMA_COMUNICACAO_COMPONENT.AGUARDANDO_LEITURA" | transloco)
                        : (dataMaisRecente | date : "dd/MM/yyyy HH:mm")
                }}
            </ng-container>
        </span>
    </span>
</div>

<div
    *ngIf="visualizarTempo === false"
    id="semDadaComTooltip"
    (window:resize)="posicionarTooltip()"
    [ngClass]="modalEquipamentos ? 'cursor-pointer' : ''"
>
    <span
        class="flex cursor-pointer"
        (mouseenter)="!modalEquipamentos ? abrirTooltip() : ''"
        (mouseleave)="!modalEquipamentos ? fecharTooltip() : ''"
        (click)="modalEquipamentos ? abrirModalEquipamentos() : ''"
    >
        <span [ngClass]="tamanhoIconeStatus ?? 'timerCommunication'" class="flex">
            <mat-icon
                [ngStyle]="{ color: dataMaisRecente ? statusComunicacao().cor : '#D65757E5' }"
                svgIcon="mat_solid:circle"
            >
            </mat-icon>
        </span>
    </span>
</div>

<div
    class="absolute bg-[#F4F6F9] text-xs text-center text-[#535767] font-normal z-99 rounded pb-2 w-[236px] {{
        mostrarTooltip
    }}"
    [id]="idTooltip ? idTooltip + 'tooltipSilo' : 'tooltipSilo'"
    *ngIf="equipamentos?.length > 0"
>
    <div class="w-full flex flex-col p-2">
        <span>{{ "DATA_ULTIMA_COMUNICACAO_COMPONENT.COMUNICACAO_GERAL_EQUIPAMENTOS" | transloco }}</span>
        <span
            class="text-sm text-[#34901C] font-medium"
            *ngIf="retornaStatusComunicacaoService.execute(listaStatusComunicacao) === estadoComunicacaoEnum.ALL"
            >{{ "DATA_ULTIMA_COMUNICACAO_COMPONENT.COMUNICANDO" | transloco }}</span
        >
        <span
            class="text-sm text-[#FBBC05] font-medium"
            *ngIf="retornaStatusComunicacaoService.execute(listaStatusComunicacao) === estadoComunicacaoEnum.PARTIAL"
            >{{ "DATA_ULTIMA_COMUNICACAO_COMPONENT.COMUNICACAO_PARCIAL" | transloco }}</span
        >
        <span
            class="text-sm text-[#A44040] font-medium"
            *ngIf="retornaStatusComunicacaoService.execute(listaStatusComunicacao) === estadoComunicacaoEnum.NONE"
            >{{ "DATA_ULTIMA_COMUNICACAO_COMPONENT.FALHA_COMUNICACAO" | transloco }}</span
        >
    </div>
    <div class="w-full flex flex-row justify-between px-2" *ngFor="let equipamento of equipamentos">
        <span class="text-[11px]">{{
            equipamento?.nome ? equipamento.nome : ("DATA_ULTIMA_COMUNICACAO_COMPONENT.AGUARDANDO_LEITURA" | transloco)
        }}</span>
        <div class="iconeComunicacao flex items-center">
            <div class="flex flex-row justify-center items-center w-3 mb-[2px]">
                <mat-icon
                    [ngStyle]="{
                        color:
                            equipamento?.equipamento_comunicacao?.status &&
                            equipamento?.equipamento_comunicacao?.status != 'indefinido'
                                ? '#01FF70'
                                : '#D65757E5'
                    }"
                    svgIcon="mat_solid:circle"
                ></mat-icon>
            </div>
            <div class="text-[11px] w-28">
                {{
                    equipamento?.equipamento_comunicacao?.instante &&
                    equipamento?.equipamento_comunicacao?.instante !== null
                        ? (equipamento?.equipamento_comunicacao?.instante | date : "dd/MM/yyyy HH:mm")
                        : ("DATA_ULTIMA_COMUNICACAO_COMPONENT.AGUARDANDO_LEITURA" | transloco)
                }}
            </div>
        </div>
    </div>
</div>
