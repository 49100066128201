import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { InformacoesMonitoramentoInterface } from 'app/modules/armazenagem/visualizacao/painel-geral/interfaces';
import { ApiTransportService } from 'app/core/services/transport';
import { IApiTransportInterface } from 'app/core/interfaces';
import { IObjectToAnyService } from 'app/shared/services';
import { ApiPaginacao } from 'app/shared/models';

const MOCK_HISTORICO: InformacoesMonitoramentoInterface[] = [
    {
        id: '1',
        instante_checkin: '2024-03-20T10:00:00',
        tempo_ultimo_checkout: 7200,
        atendimento_contato: [
            {
                id: '1',
                categoria_contato: {
                    id: '1',
                    nome: 'Telefone',
                },
            },
        ],
        usuario: {
            id: '1',
            nome: 'João',
            sobrenome: 'Silva',
        },
        notas: 'Atendimento realizado com sucesso',
    },
    {
        id: '2',
        instante_checkin: '2024-03-20T09:30:00',
        tempo_ultimo_checkout: 5400,
        atendimento_contato: [
            {
                id: '2',
                categoria_contato: {
                    id: '2',
                    nome: 'Email',
                },
            },
            {
                id: '5',
                categoria_contato: {
                    id: '5',
                    nome: 'chamado',
                    notas: 'chamado teste aberto',
                },
            },
        ],
        usuario: {
            id: '2',
            nome: 'Maria',
            sobrenome: 'Santos',
        },
        notas: 'Cliente solicitou retorno',
    },
    {
        id: '3',
        instante_checkin: '2024-03-20T09:00:00',
        tempo_ultimo_checkout: 3600,
        atendimento_contato: [
            {
                id: '3',
                categoria_contato: {
                    id: '3',
                    nome: 'whatsapp',
                },
            },
        ],
        usuario: {
            id: '3',
            nome: 'Pedro',
            sobrenome: 'Oliveira',
        },
        notas: 'Dúvida resolvida',
    },
];

export abstract class IInformacoesMonitoramentoController {
    abstract buscaHistoricoAtendimento(
        paginacao: ApiPaginacao,
        usuarioId?: string
    ): Observable<InformacoesMonitoramentoInterface[]>;
}

@Injectable({
    providedIn: 'root',
})
export class InformacoesMonitoramentoController {
    private transport: IApiTransportInterface;

    constructor(
        public readonly apiTransportService: ApiTransportService,
        private readonly objectToAnyService: IObjectToAnyService
    ) {
        this.transport = this.apiTransportService.execute('/geral');
    }

    buscaHistoricoAtendimento(
        paginacao: ApiPaginacao,
        usuarioId?: string
    ): Observable<InformacoesMonitoramentoInterface[]> {
        this.transport.options.params = this.objectToAnyService.toQueryString({
            page: paginacao.page,
            limit: paginacao.limit,
            usuarioId: usuarioId,
        });

        // Retornando mock de dados
        return of(MOCK_HISTORICO);

        // Chamada real da API (comentada para uso futuro)
        // return this.transport.http.get(`${this.transport.path}/painel-historico-atendimento`, this.transport.options);
    }
}
