<ng-container>
    <div class="flex flex-row w-full justify-end">
        <div class="flex text-base flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            {{ 'SENSORES_INFORMACOES_GERAIS_COMPONENT.MIN' | transloco }}
        </div>
        <div class="flex text-base flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            {{ 'SENSORES_INFORMACOES_GERAIS_COMPONENT.MED' | transloco }}
        </div>
        <div class="flex text-base flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
            {{ 'SENSORES_INFORMACOES_GERAIS_COMPONENT.MAX' | transloco }}
        </div>
    </div>
</ng-container>

<!-- TEMPERATURA GERAL -->
<div
    *ngIf="verificaTipoSensorExistente(tipoSensorEnum.DIGITAL) && verificaTipoSensorExistente(tipoSensorEnum.TERMOPAR)"
    class="pl-2 flex justify-between items-center rounded h-[30px] bg-[#f8fafb]"
>
    <div class="lg:w-[75%] w-[135px] sm:w-full text-base">
        {{ 'SENSORES_INFORMACOES_GERAIS_COMPONENT.TEMP_GERAL' | transloco }}
    </div>
    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.temperatura?.geral?.min ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresTemperatura(sensores?.temperatura?.geral?.min)?.fundo,
                color: defineCoresTemperatura(sensores?.temperatura?.geral?.min)?.cor
            }"
        >
            {{ sensores?.temperatura?.geral?.min ? (sensores?.temperatura?.geral?.min | number : '1.1-1') : '-' }}
        </span>
    </span>

    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.temperatura?.geral?.med ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresTemperatura(sensores?.temperatura?.geral?.med)?.fundo,
                color: defineCoresTemperatura(sensores?.temperatura?.geral?.med)?.cor
            }"
        >
            {{ sensores?.temperatura?.geral?.med ? (sensores?.temperatura?.geral?.med | number : '1.1-1') : '-' }}
        </span>
    </span>

    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.temperatura?.geral?.max ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresTemperatura(sensores?.temperatura?.geral?.max)?.fundo,
                color: defineCoresTemperatura(sensores?.temperatura?.geral?.max)?.cor
            }"
        >
            {{ sensores?.temperatura?.geral?.max ? (sensores?.temperatura?.geral?.max | number : '1.1-1') : '-' }}
        </span>
    </span>
</div>

<!-- TEMPERATURA DIGITAL -->
<div
    *ngIf="verificaTipoSensorExistente(tipoSensorEnum.DIGITAL)"
    class="pl-2 flex justify-between items-center rounded h-[30px]"
>
    <div class="lg:w-[75%] w-[135px] sm:w-full text-base">
        {{ 'SENSORES_INFORMACOES_GERAIS_COMPONENT.TEMP_DIGITAL' | transloco }}
    </div>
    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.temperatura?.digital?.min !== null
                    ? 'pl-3 pr-3'
                    : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresTemperatura(sensores?.temperatura?.digital?.min)?.fundo,
                color: defineCoresTemperatura(sensores?.temperatura?.digital?.min)?.cor
            }"
        >
            {{
                sensores?.temperatura?.digital?.min !== null
                    ? (sensores?.temperatura?.digital?.min | number : '1.1-1')
                    : '-'
            }}
        </span>
    </span>

    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.temperatura?.digital?.med !== null
                    ? 'pl-3 pr-3'
                    : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresTemperatura(sensores?.temperatura?.digital?.med)?.fundo,
                color: defineCoresTemperatura(sensores?.temperatura?.digital?.med)?.cor
            }"
        >
            {{
                sensores?.temperatura?.digital?.med !== null
                    ? (sensores?.temperatura?.digital?.med | number : '1.1-1')
                    : '-'
            }}
        </span>
    </span>

    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.temperatura?.digital?.max !== null
                    ? 'pl-3 pr-3'
                    : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresTemperatura(sensores?.temperatura?.digital?.max)?.fundo,
                color: defineCoresTemperatura(sensores?.temperatura?.digital?.max)?.cor
            }"
        >
            {{
                sensores?.temperatura?.digital?.max !== null
                    ? (sensores?.temperatura?.digital?.max | number : '1.1-1')
                    : '-'
            }}
        </span>
    </span>
</div>

<!-- TEMPERATURA TERMOPAR -->
<div
    *ngIf="verificaTipoSensorExistente(tipoSensorEnum.TERMOPAR)"
    class="pl-2 flex justify-between items-center rounded h-[30px] bg-[#f8fafb]"
>
    <div class="lg:w-[75%] w-[135px] sm:w-full text-base">
        {{ 'SENSORES_INFORMACOES_GERAIS_COMPONENT.TEMP_TERMOPAR' | transloco }}
    </div>

    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.temperatura?.termopar?.min !== null
                    ? 'pl-3 pr-3'
                    : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresTemperatura(sensores?.temperatura?.termopar?.min)?.fundo,
                color: defineCoresTemperatura(sensores?.temperatura?.termopar?.min)?.cor
            }"
        >
            {{
                sensores?.temperatura?.termopar?.min !== null
                    ? (sensores?.temperatura?.termopar?.min | number : '1.1-1')
                    : '-'
            }}
        </span>
    </span>

    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.temperatura?.termopar?.med !== null
                    ? 'pl-3 pr-3'
                    : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresTemperatura(sensores?.temperatura?.termopar?.med)?.fundo,
                color: defineCoresTemperatura(sensores?.temperatura?.termopar?.med)?.cor
            }"
        >
            {{
                sensores?.temperatura?.termopar?.med !== null
                    ? (sensores?.temperatura?.termopar?.med | number : '1.1-1')
                    : '-'
            }}
        </span>
    </span>

    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.temperatura?.termopar?.max !== null
                    ? 'pl-3 pr-3'
                    : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresTemperatura(sensores?.temperatura?.termopar?.max)?.fundo,
                color: defineCoresTemperatura(sensores?.temperatura?.termopar?.max)?.cor
            }"
        >
            {{
                sensores?.temperatura?.termopar?.max !== null
                    ? (sensores?.temperatura?.termopar?.max | number : '1.1-1')
                    : '-'
            }}
        </span>
    </span>
</div>

<!-- UMIDADE RELATIVA -->
<div *ngIf="temSensorUmidade" class="pl-2 flex justify-between items-center rounded h-[30px]">
    <div class="lg:w-[75%] w-[135px] sm:w-full text-base">
        {{ 'SENSORES_INFORMACOES_GERAIS_COMPONENT.UMIDADE_RELATIVA' | transloco }}
    </div>

    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.umidade?.min !== null ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresUmidade(sensores?.umidade?.min)?.fundo,
                color: defineCoresUmidade(sensores?.umidade?.min)?.cor
            }"
        >
            {{ sensores?.umidade?.min !== null ? (sensores?.umidade?.min | number : '1.1-1') : '-' }}
        </span>
    </span>

    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.umidade?.med !== null ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresUmidade(sensores?.umidade?.med)?.fundo,
                color: defineCoresUmidade(sensores?.umidade?.med)?.cor
            }"
        >
            {{ sensores?.umidade?.med !== null ? (sensores?.umidade?.med | number : '1.1-1') : '-' }}
        </span>
    </span>

    <span class="flex flex-col mx-2 wl-cor-primaria max-w-12 w-12 sm:max-w-[56.5px] sm:w-[56.5px]">
        <span
            class="rounded-md sm:min-w-[56.5px] flex justify-center text-center pt-[2px] h-[24px]"
            [ngClass]="
                sensores?.umidade?.max !== null ? 'pl-3 pr-3' : 'opacity-50 px-[1.4rem] text-[#535767] bg-[#CECECE]'
            "
            [ngStyle]="{
                background: defineCoresUmidade(sensores?.umidade?.max)?.fundo,
                color: defineCoresUmidade(sensores?.umidade?.max)?.cor
            }"
        >
            {{ sensores?.umidade?.max !== null ? (sensores?.umidade?.max | number : '1.1-1') : '-' }}
        </span>
    </span>
</div>
