import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { configuracaoTamanhoModal } from 'app/shared/constants';
import { MensagensCO2Enum, PpmEnum } from 'app/shared/enums';
import {
    ICriaPaletaCoresTermometriaService,
    IFormataEquipamentosComponenteDataService,
    IMontaListaEquipamentosSensoresService,
} from 'app/shared/services';
import { ModalEscalaCoresCO2Component } from 'app/shared/components/modal-escala-cores-co2/modal-escala-cores-co2.component';
import { ModalInformativoGenericoComponent } from 'app/shared/components/modal-informativo-generico/modal-informativo-generico.component';
import { TranslocoService } from '@ngneat/transloco';

@Component({
    selector: 'app-sensor-co2-informacoes-gerais-armazem',
    templateUrl: './sensor-co2-informacoes-gerais-armazem.component.html',
    styleUrls: ['./sensor-co2-informacoes-gerais-armazem.component.scss'],
})
export class SensorCo2InformacoesGeraisArmazemComponent {
    @Input() sensorCO2: any;
    @Input() equipamentos: any[];
    @Input() sensoresCO2Equipamentos: any[];

    Object = Object;
    ppmEnum = PpmEnum;
    listaEquimentosSensoresCO2: any[] = [];
    ocultarModal: boolean = true;

    defineCoresTemperatura = this.criaPaletaCoresSensoresService.criarPaletaTemperatura;
    defineCoresUmidade = this.criaPaletaCoresSensoresService.criarPaletaUmidade;
    defineCoresCO2 = this.criaPaletaCoresSensoresService.criarPaletaCO2;

    constructor(
        readonly criaPaletaCoresSensoresService: ICriaPaletaCoresTermometriaService,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver,
        private formataEquipamentosComponenteDataService: IFormataEquipamentosComponenteDataService,
        private montaListaEquipamentosSensoresService: IMontaListaEquipamentosSensoresService,
        private transloco: TranslocoService
    ) {}

    ngOnChanges(): void {
        this.montaListagemEquipamentosSensores();
    }

    abrirModalEscalaCores(): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        this.dialog.open(ModalEscalaCoresCO2Component, {
            minWidth: mobile
                ? configuracaoTamanhoModal.informativo.mobile.width
                : configuracaoTamanhoModal.informativo.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.informativo.mobile.height
                : configuracaoTamanhoModal.informativo.desktop.height,
        });
    }

    abrirModalCondensacao(): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');
        this.dialog.open(ModalInformativoGenericoComponent, {
            minWidth: mobile
                ? configuracaoTamanhoModal.informativo.mobile.width
                : configuracaoTamanhoModal.informativo.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.informativo.mobile.height
                : configuracaoTamanhoModal.informativo.desktop.height,
            data: {
                titulo: this.transloco.translate(MensagensCO2Enum.TITULO_MODAL_CONDENSACAO),
                mensagem: this.transloco.translate(MensagensCO2Enum.MENSAGEM_MODAL_CONDENSACAO),
            },
        });
    }

    formataArrayEquipamentosComponenteData(equipamentos: any): any[] {
        return this.formataEquipamentosComponenteDataService.execute(equipamentos);
    }

    private montaListagemEquipamentosSensores(): void {
        this.listaEquimentosSensoresCO2 = this.montaListaEquipamentosSensoresService.execute(
            this.sensoresCO2Equipamentos,
            this.formataArrayEquipamentosComponenteData(this.equipamentos)
        );        
    }
}
