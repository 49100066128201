import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DatePipe } from '@angular/common';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog } from '@angular/material/dialog';

import { Subject, takeUntil, take } from 'rxjs';

import { editorTextoConfig } from 'app/layout/common/check-in-check-out/data/editor-texto-config';
import { ICheckInCheckOutController } from 'app/layout/common/check-in-check-out/controllers';
import { tituloPagina } from 'app/layout/common/check-in-check-out/data/titulo-pagina';
import { IAlertaService, SetaTituloPaginaService } from 'app/shared/services';
import { CheckInCheckOutObservable } from 'app/shared/observables';
import { ModalConfirmacaoComponent } from 'app/shared/components';
import { configuracaoTamanhoModal } from 'app/shared/constants';
import { AtendimentoInterface } from 'app/shared/interfaces';

import {
    CategoriaAtividadeCheckInCheckOutInterface,
    CategoriaContatoCheckInCheckOutInterface,
} from 'app/layout/common/check-in-check-out/interfaces';

@Component({
    selector: 'app-check-in-check-out',
    templateUrl: './check-in-check-out.component.html',
    styleUrls: ['./check-in-check-out.component.scss'],
})
export class CheckInCheckOutComponent implements OnInit, OnDestroy {
    @ViewChild('atividadeSelect') atividadeSelect: ElementRef<HTMLInputElement>;

    permitirClienteVisualizarControl: UntypedFormControl = new UntypedFormControl();
    atividades: CategoriaAtividadeCheckInCheckOutInterface[];
    contatos: CategoriaContatoCheckInCheckOutInterface[];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    atendimentoAtual: AtendimentoInterface;
    editorTextoConfig = editorTextoConfig;
    atividadesSelecionadas: string[] = [];
    formGroup: FormGroup;
    tempoAnaliseInicial: number;
    encerrandoCheckOut: boolean = false;

    private unsubscribeAll: Subject<void> = new Subject<void>();

    constructor(
        private checkInCheckOutController: ICheckInCheckOutController,
        private checkInCheckOutObservable: CheckInCheckOutObservable,
        private breakpointObserver: BreakpointObserver,
        private changeDetectorRef: ChangeDetectorRef,
        private setaTitulo: SetaTituloPaginaService,
        private notificacaoService: IAlertaService,
        private transloco: TranslocoService,
        private formBuilder: FormBuilder,
        readonly dialog: MatDialog,
        private router: Router,
        private datePipe: DatePipe
    ) {
        this.buscarCategoriasAtendimento();
        this.setaTitulo.setaTituloPaginaService(tituloPagina);
        this.permitirClienteVisualizarControl.setValue(false);
    }

    ngOnInit(): void {
        this.checkInCheckOutObservable.setPausado = true;

        this.iniciarObservadorCheckInAtivo();
        this.iniciarObservadorAtendimento();
    }

    adicionarAtividade(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();

        if (value) {
            this.atividadesSelecionadas.push(value);
        }
    }

    removerAtividade(atividade: string): void {
        const index = this.atividadesSelecionadas.indexOf(atividade);

        if (this.atividadesSelecionadas.indexOf(atividade) >= 0) {
            this.atividadesSelecionadas.splice(index, 1);
        }
    }

    selecionarAtividades(event: MatAutocompleteSelectedEvent): void {
        this.atividadesSelecionadas.push(event.option.viewValue);
        this.atividadeSelect.nativeElement.value = '';

        this.formGroup.get('atividade').setValue(this.atividadesSelecionadas);
    }

    exibirAtividadesDisponiveis(): CategoriaAtividadeCheckInCheckOutInterface[] {
        return this.atividades?.filter((atividade) => !this.atividadesSelecionadas.includes(atividade.nome));
    }

    abrirModalConfirmacao(): void {
        const mobile = this.breakpointObserver.isMatched('(max-width: 600px)');

        const dialogRef = this.dialog.open(ModalConfirmacaoComponent, {
            width: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.width
                : configuracaoTamanhoModal.confirmacao.desktop.width,
            minHeight: mobile
                ? configuracaoTamanhoModal.confirmacao.mobile.height
                : configuracaoTamanhoModal.confirmacao.desktop.height,
            data: {
                mensagem: this.transloco.translate('CHECK_IN_CHECK_OUT.DESEJA_REALMENTE_CONTINUAR'),
                icone: 'warning_amber',
                cor_size: 'wl-cor-primaria',
            },
        });

        dialogRef.afterClosed().subscribe((resultado) => {
            if (resultado) {
                this.finalizarCheckOut();
            }
        });
    }

    finalizarCheckOut(): void {
        this.encerrandoCheckOut = true;
        this.checkInCheckOutController
            .encerrarAtendimento(this.atendimentoAtual.id, this.formatarFormulario(this.formGroup))
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe({
                next: () => {
                    this.notificacaoService.mostrarSucesso(
                        this.transloco.translate('CHECK_IN_CHECK_OUT.CHECK_OUT_REGISTRADO_SUCESSO')
                    );
                    this.checkInCheckOutObservable.setCheckInAtivo = false;
                    this.checkInCheckOutObservable.setAtendimento = null;
                    this.checkInCheckOutObservable.setPausado = false;
                    this.checkInCheckOutObservable.setCheckoutIniciado = false;
                    this.checkInCheckOutObservable.setPassadorUnidadesBloqueado = false;
                },
                error: (error) => {
                    console.log(error);
                },
            })
            .add(() => {
                this.encerrandoCheckOut = false;
                this.router.navigateByUrl('armazenagem/visualizacao/painel-geral');
            });
    }

    contatoTipoChamadoAtivo(): boolean {
        const contatoTipoChamado = this.contatos.find((contato) => contato.nome.includes('Chamado'));

        return contatoTipoChamado ? this.formGroup.get(`${contatoTipoChamado.id}`)?.value : false;
    }

    formatarFormulario(formGroup: FormGroup): any {
        const atendimentoAtividade = this.atividadesSelecionadas.map((atividadeNome) => {
            const atividade = this.atividades.find((a) => a.nome === atividadeNome);
            return {
                categoria_atividade_id: atividade?.id,
            };
        });

        const atendimentoContato = this.contatos
            .filter((contato) => formGroup.get(`${contato.id}`).value)
            .map((contato) => {
                const contatoFormatado: any = {
                    categoria_contato_id: contato.id,
                };

                if (contato.nome.includes('Chamado')) {
                    contatoFormatado.notas = formGroup.get('notasChamado').value;
                }

                return contatoFormatado;
            });

        return {
            visivel_cliente: this.permitirClienteVisualizarControl.value,
            tempo_analise: this.tempoAnaliseInicial,
            atendimento_atividade: atendimentoAtividade,
            atendimento_contato: atendimentoContato,
            notas: formGroup.get('anotacao').value,
        };
    }

    inicializarContadorCheckOut(): void {
        const tempoFormatado = this.checkInCheckOutObservable.getContadorCheckOut;

        const [horas, minutos, segundos] = tempoFormatado.split(':').map(Number);
        this.tempoAnaliseInicial = Math.floor(horas * 60 + minutos + segundos / 60);

        this.formGroup.get('tempoAnaliseAteCheckout')?.setValue(tempoFormatado);
    }

    cancelarCheckOut(): void {
        this.checkInCheckOutObservable.setCheckoutIniciado = false;
        this.checkInCheckOutObservable.setPassadorUnidadesBloqueado = false;
        this.checkInCheckOutObservable.setPausado = false;
        this.router.navigateByUrl('armazenagem/visualizacao/painel-geral', { skipLocationChange: true });
        this.checkInCheckOutObservable.setAtendimento = this.atendimentoAtual;
    }

    private iniciarObservadorCheckInAtivo(): void {
        this.checkInCheckOutObservable.checkInAtivo$.pipe(take(1)).subscribe((checkInAtivo) => {
            if (!checkInAtivo) {
                this.router.navigateByUrl('armazenagem/visualizacao/painel-geral');
                return;
            }
        });
    }

    private iniciarObservadorAtendimento(): void {
        this.checkInCheckOutObservable.atendimento$.pipe(takeUntil(this.unsubscribeAll)).subscribe((atendimento) => {
            if (atendimento) {
                this.atendimentoAtual = atendimento;
            }
        });
    }

    private atualizarDadosAtendimento(atendimento: AtendimentoInterface): void {
        if (this.formGroup) {
            this.formGroup
                .get('dataCheckIn')
                .setValue(this.datePipe.transform(atendimento.instante_checkin, 'dd/MM/yyyy HH:mm:ss'));

            this.formGroup
                .get('tempoDesdeUltimoCheckIn')
                .setValue(
                    atendimento.tempo_ultimo_checkin
                        ? this.converterMinutosEmDias(atendimento.tempo_ultimo_checkin)
                        : null
                );

            this.formGroup
                .get('tempoDesdeUltimoContatoEnviado')
                .setValue(
                    atendimento.tempo_ultimo_contato
                        ? this.converterMinutosEmDias(atendimento.tempo_ultimo_contato)
                        : null
                );
        }
    }

    private converterMinutosEmDias = (minutos: number): string => {
        if (minutos === null || minutos === undefined) return null;

        if (minutos === 0) return this.transloco.translate('CHECK_IN_CHECK_OUT.MENOS_DE_UM_DIA');

        const dias = Math.floor(minutos / 1440);

        return dias === 0
            ? this.transloco.translate('CHECK_IN_CHECK_OUT.MENOS_DE_UM_DIA')
            : `${dias} ${this.transloco.translate('CHECK_IN_CHECK_OUT.DIAS')}`;
    };

    private buscarCategoriasAtendimento(): void {
        this.checkInCheckOutController
            .buscarCategoriasAtendimento()
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe({
                next: (response) => {
                    this.contatos = response.categoria_contato;
                    this.atividades = response.categoria_atividade;
                    this.inicializarFormulario();
                    this.atualizarDadosAtendimento(this.atendimentoAtual);
                },
                error: (error) => {
                    console.log(error);
                },
            })
            .add(() => {
                this.changeDetectorRef.detectChanges();
            });
    }

    private desabilitarCampos(): void {
        this.formGroup.get('dataCheckIn').disable();
        this.formGroup.get('tempoAnaliseAteCheckout').disable();
        this.formGroup.get('tempoDesdeUltimoCheckIn').disable();
        this.formGroup.get('tempoDesdeUltimoContatoEnviado').disable();
    }

    private inicializarFormulario(): void {
        const contatosControls = this.contatos?.reduce((acc, contato) => {
            acc[String(contato.id)] = [false];
            return acc;
        }, {});

        this.formGroup = this.formBuilder.group({
            dataCheckIn: [null, [Validators.required]],
            tempoAnaliseAteCheckout: [null, [Validators.required]],
            tempoDesdeUltimoCheckIn: [null, [Validators.required]],
            tempoDesdeUltimoContatoEnviado: [null, [Validators.required]],
            atividade: [null, [Validators.required]],
            notasChamado: null,
            anotacao: null,
            nenhumContato: [false],
            ...contatosControls,
        });

        this.definindoCampoNenhumContato();

        this.desabilitarCampos();
        this.inicializarContadorCheckOut();
    }

    private definindoCampoNenhumContato(): void {
        this.formGroup
            .get('nenhumContato')
            .valueChanges.pipe(takeUntil(this.unsubscribeAll))
            .subscribe((nenhumContato) => {
                if (nenhumContato) {
                    this.contatos.forEach((contato) => {
                        const controle = this.formGroup.get(String(contato.id));
                        if (controle) {
                            controle.setValue(false);
                            controle.disable();
                        }
                    });
                } else {
                    this.contatos.forEach((contato) => {
                        const controle = this.formGroup.get(String(contato.id));
                        if (controle) {
                            controle.enable();
                        }
                    });
                }
            });
    }

    cancelarCheckIn(): void {
        this.checkInCheckOutObservable.setCheckoutIniciado = false;
        this.checkInCheckOutObservable.setPassadorUnidadesBloqueado = false;
        this.checkInCheckOutObservable.setPausado = false;
        this.router.navigateByUrl('armazenagem/visualizacao/painel-geral');
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
