import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusAutomaticoManualArmazemComponent } from 'app/shared/components/status-automatico-manual-armazem/status-automatico-manual-armazem.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
    imports: [CommonModule, TranslocoModule],
    declarations: [StatusAutomaticoManualArmazemComponent],
    exports: [StatusAutomaticoManualArmazemComponent],
})
export class StatusAutomaticoManualArmazemModule {}
