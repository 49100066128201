import { Injectable } from '@angular/core';

import { Observable, map } from 'rxjs';

import { ProdutosProcerEnum } from 'app/shared/enums';
import { ResumoUnidadeInterface } from 'app/shared/interfaces';
import { UnidadeParametrizacaoObservable } from 'app/shared/observables';

export abstract class IUnidadeProdutosObservable {
	abstract contemProdutoAeracao(): Observable<boolean>;
}

@Injectable({
	providedIn: 'root',
})
export class UnidadeProdutosObservable implements IUnidadeProdutosObservable {
	unidade: ResumoUnidadeInterface;

	constructor(private unidadeParametrizacaoObservable: UnidadeParametrizacaoObservable) {}

	contemProdutoAeracao(): Observable<boolean> {
		return this.unidadeParametrizacaoObservable.buscaParametrosUnidade$.pipe(
			map((unidade: ResumoUnidadeInterface) => {
				return this.verificarProdutoAeracao(unidade);
			})
		);
	}

	private verificarProdutoAeracao(unidade: ResumoUnidadeInterface): boolean {
		return unidade?.unidade_produto_interno.some((e: any) => e.produto === ProdutosProcerEnum.AERACAO_AUTOMATICA);
	}
}
