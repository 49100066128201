import { Component, Input } from '@angular/core';
import { AeradorInterface } from 'app/shared/interfaces';

@Component({
  selector: 'app-tooltip-aerador',
  templateUrl: './tooltip-aerador.component.html',
  styleUrls: ['./tooltip-aerador.component.scss']
})
export class TooltipAeradorComponent {
  @Input() aerador: AeradorInterface;
  @Input() visaoPlanificadaArmazem: boolean = false;
}
