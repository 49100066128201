import { TranslocoModule } from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertaAeracaoSensor3DComponent } from 'app/shared/components/alerta-aeracao-sensor-3d/alerta-aeracao-sensor-3d.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [CommonModule, MatIconModule, TranslocoModule],
    declarations: [AlertaAeracaoSensor3DComponent],
    exports: [AlertaAeracaoSensor3DComponent],
})
export class AlertasAeracaoSensor3DModule {}
