import { StatusAeracaoModel } from 'app/shared/models';

export const configuracoesStatusAeracaoEsConstant: StatusAeracaoModel[] = [
    {
        codigo: '0A',
        icone: {
            tipo: 'icone-aguardando',
            texto: 'Confirmación de comando',
            classIcone: 'color-[#185BC3]',
            modal: {
                titulo: 'Confirmación de comando',
                subtitulo: 'Esperando MEP.',
                mensagem: 'Comando enviado. Esperando confirmación de que fue ejecutado.',
            },
        },
        alerta: {
            cor: '#185BC3',
            background: '#185BC333',
        },
        aerador: {
            cor: 'azul',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '0B',
        icone: {
            tipo: 'icone-aguardando',
            texto: 'Confirmación de comando',
            classIcone: 'color-[#185BC3]',
            modal: {
                titulo: 'Confirmación de comando',
                subtitulo: 'Esperando MEP.',
                mensagem: 'Comando enviado. Esperando confirmación de que fue ejecutado.',
            },
        },
        alerta: {
            cor: '#185BC3',
            background: '#185BC333',
        },
        aerador: {
            cor: 'azul-mao-amarela',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '-1',
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Estado indefinido',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Estado indefinido',
                subtitulo: 'Estado indefinido',
                mensagem: 'Estado de aireación indefinido.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '1A',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falla de comunicación',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falla de comunicación',
                subtitulo: 'Sin comunicación (relé).',
                mensagem: 'El equipo responsable de la activación no se ha comunicado desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '1B',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falla de comunicación',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falla de comunicación',
                subtitulo: 'Sin comunicación (relé).',
                mensagem: 'El equipo responsable de la activación no se ha comunicado desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '2A',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falla de comunicación',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falla de comunicación',
                subtitulo: 'Sin comunicación (llave).',
                mensagem: 'El equipo responsable de la lectura de las llaves no se ha comunicado desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '2B',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falla de comunicación',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falla de comunicación',
                subtitulo: 'Sin comunicación (llave).',
                mensagem: 'El equipo responsable de la lectura de las llaves no se ha comunicado desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '3A',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falla de comunicación',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falla de comunicación',
                subtitulo: 'Sin comunicación (estado).',
                mensagem:
                    'El equipo responsable de informar si el comando fue ejecutado no se ha comunicado desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '3B',
        falha_comunicacao: true,
        icone: {
            tipo: 'mat_solid:circle',
            texto: 'Falla de comunicación',
            classIcone: 'fill-[#A44040]',
            modal: {
                titulo: 'Falla de comunicación',
                subtitulo: 'Sin comunicación (estado).',
                mensagem:
                    'El equipo responsable de informar si el comando fue ejecutado no se ha comunicado desde {{valor}}',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '101',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Panel eléctrico o equipo Procer',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Panel eléctrico o equipo Procer',
                subtitulo: 'El aerador no encendió. Verificar disyuntores del panel.',
                mensagem:
                    'El sistema está configurado para activación automática e intentó encender el aerador. Los interruptores del panel están en la posición automática, pero el estado del motor no está activado. Es posible que el panel eléctrico o el equipo Procer estén defectuosos. Verificar disyuntores del panel.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual de aireación',
                mensagem:
                    '¿Realmente desea <strong>apagar</strong> la aireación de <strong>{{valor}}</strong>? Al confirmar, el sistema ya no controlará automáticamente los aeradores.',
                botaoAcao: 'apagar',
            },
        },
    },
    {
        codigo: '102',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Panel eléctrico o equipo Procer',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Panel eléctrico o equipo Procer',
                subtitulo: 'Aeradores a través del sistema estarían apagados.',
                mensagem:
                    'Los interruptores del panel están en la posición automática y el estado del motor está activado. Es posible que el panel eléctrico o el equipo Procer estén defectuosos.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual de aireación',
                mensagem:
                    '¿Realmente desea <strong>encender</strong> la aireación de <strong>{{valor}}</strong>? Al confirmar, el sistema ya no controlará automáticamente los aeradores.',
                botaoAcao: 'encender',
            },
        },
    },
    {
        codigo: '103',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Interruptor del panel en posición manual',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Interruptor del panel en posición manual',
                subtitulo: 'Aeradores a través del sistema estarían encendidos.',
                mensagem:
                    'El sistema está configurado para activación automática e intentó encender el aerador, pero los interruptores del panel están en la posición manual, lo que impide que el motor se encienda o apague a través del sistema.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '104',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Interruptor del panel en posición manual',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Interruptor del panel en posición manual',
                subtitulo: 'Los aeradores no serán controlados a través del sistema.',
                mensagem:
                    'El sistema está configurado para activación automática, el aerador está encendido, pero los interruptores del panel están en la posición manual, lo que impide que el aerador se apague a través del sistema.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '105',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Aerador encendido manualmente',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Aerador encendido manualmente',
                subtitulo: 'Los aeradores no serán controlados a través del sistema.',
                mensagem:
                    'El sistema está configurado para activación automática, pero los interruptores del panel están en la posición manual, lo que impide que el aerador se encienda o apague a través del sistema.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '106',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Panel eléctrico o equipo Procer',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Panel eléctrico o equipo Procer',
                subtitulo: 'El aerador no encendió. Verificar disyuntores del panel.',
                mensagem:
                    'El relé del equipo Procer está activado, el interruptor del panel está en la posición automática, pero el aerador no encendió. Verificar disyuntores del panel.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual de aireación',
                mensagem: '¿Realmente desea <strong>apagar</strong> la aireación de <strong>{{valor}}</strong>?',
                botaoAcao: 'apagar',
            },
        },
    },
    {
        codigo: '107',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Panel eléctrico o equipo Procer',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Panel eléctrico o equipo Procer',
                subtitulo: 'Aeradores a través del sistema estarían apagados.',
                mensagem:
                    'Los interruptores del panel están en la posición automática, el estado del aerador está activado y el relé está apagado. Es posible que el panel eléctrico o el equipo Procer estén defectuosos.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'preto-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual de aireación',
                mensagem: '¿Realmente desea <strong>encender</strong> la aireación de <strong>{{valor}}</strong>?',
                botaoAcao: 'encender',
            },
        },
    },
    {
        codigo: '201',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Interruptor del panel en posición manual',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Interruptor del panel en posición manual',
                subtitulo: 'Los aeradores no serán controlados a través del sistema.',
                mensagem:
                    'El sistema está configurado para activación automática, pero los interruptores del panel están en la posición manual, lo que impide que el aerador se encienda o apague a través del sistema.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '202',
        icone: {
            tipo: 'heroicons_solid:x-circle',
            texto: 'Interruptor del panel en posición manual',
            classIcone: 'text-[#D65757]',
            modal: {
                titulo: 'Interruptor del panel en posición manual',
                subtitulo: 'Aeradores a través del sistema estarían encendidos.',
                mensagem:
                    'El relé del equipo Procer está activado, pero el interruptor del panel está en la posición manual, lo que impide que el aerador se encienda.',
            },
        },
        alerta: {
            cor: '#D65757',
            background: '#D6575733',
        },
        aerador: {
            cor: 'vermelho-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '203',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Interruptor del panel en posición manual',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Interruptor del panel en posición manual',
                subtitulo: 'Los aeradores no serán controlados a través del sistema.',
                mensagem:
                    'El relé del equipo Procer está activado, pero el interruptor del panel está en la posición manual, lo que impide que el aerador se apague a través del sistema.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '204',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Aerador encendido manualmente',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Aerador encendido manualmente',
                subtitulo: 'Encendido a través del panel.',
                mensagem: 'Aerador encendido manualmente a través de los botones del panel eléctrico',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '205',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Interruptor del panel en posición manual',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Interruptor del panel en posición manual',
                subtitulo: 'No es posible controlar los aeradores.',
                mensagem: 'El interruptor del panel está en la posición manual, no será posible controlar el aerador',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'cinza-mao-vermelha',
        },
        acionamento_manual: {
            habilitado: false,
        },
    },
    {
        codigo: '206',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Sistema en modo manual',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Sistema en modo manual',
                subtitulo: 'Los aeradores no serán controlados a través del sistema.',
                mensagem: 'Aeradores encendidos manualmente a través del sistema.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'verde-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual de aireación',
                mensagem: '¿Realmente desea <strong>apagar</strong> la aireación de <strong>{{valor}}</strong>?',
                botaoAcao: 'apagar',
            },
        },
    },
    {
        codigo: '207',
        icone: {
            tipo: 'mat_solid:warning',
            texto: 'Sistema en modo manual',
            classIcone: 'fill-[#FFBA09]',
            modal: {
                titulo: 'Sistema en modo manual',
                subtitulo: 'Los aeradores no serán controlados a través del sistema.',
                mensagem: 'Sistema en control manual. Los aeradores no serán controlados automáticamente.',
            },
        },
        alerta: {
            cor: '#FFBA09',
            background: '#FFBA0933',
        },
        aerador: {
            cor: 'cinza-mao-amarela',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual de aireación',
                mensagem: '¿Realmente desea <strong>encender</strong> la aireación de <strong>{{valor}}</strong>?',
                botaoAcao: 'encender',
            },
        },
    },
    {
        codigo: '401',
        icone: {
            tipo: 'mat_solid:check_circle',
            texto: 'Sistema en modo automático',
            classIcone: 'fill-[#34901C]',
            modal: {
                titulo: 'Sistema en modo automático',
                subtitulo: 'Aeradores configurados para activación automática.',
                mensagem: 'Activación automática de los aeradores de acuerdo con el programa establecido.',
            },
        },
        alerta: {
            cor: '#34901C',
            background: '#34901C33',
        },
        aerador: {
            cor: 'cinza',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual de aireación',
                mensagem:
                    '¿Realmente desea <strong>encender</strong> la aireación de <strong>{{valor}}</strong>? Al confirmar, el sistema ya no controlará automáticamente los aeradores.',
                botaoAcao: 'encender',
            },
        },
    },
    {
        codigo: '402',
        icone: {
            tipo: 'mat_solid:check_circle',
            texto: 'Sistema en modo automático',
            classIcone: 'fill-[#34901C]',
            modal: {
                titulo: 'Sistema en modo automático',
                subtitulo: 'Aeradores encendidos automáticamente.',
                mensagem: 'Activación automática de los aeradores de acuerdo con el programa configurado.',
            },
        },
        alerta: {
            cor: '#34901C',
            background: '#34901C33',
        },
        aerador: {
            cor: 'verde',
        },
        acionamento_manual: {
            habilitado: true,
            modal: {
                titulo: 'Comando manual de aireación',
                mensagem:
                    '¿Realmente desea <strong>apagar</strong> la aireación de <strong>{{valor}}</strong>? Al confirmar, el sistema ya no controlará automáticamente los aeradores.',
                botaoAcao: 'apagar',
            },
        },
    },
];
